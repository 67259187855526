//@ts-ignore
import { ScatterplotLayer } from "@deck.gl/layers";
import {
  scaleQuantile,
  scaleQuantize,
  scaleLinear,
  scaleOrdinal,
  //@ts-ignore
} from "d3-scale";
//@ts-ignore
import { DataFilterExtension } from "@deck.gl/extensions";
import { COLOR_RANGE } from "../constants/colorRange";
import {
  getMinMax,
  findColorRange,
  getFilterValueFromDataset,
  getFilterRangeFromDataset,
} from "../functions/layerProperties";
//@ts-ignore
import { isEmpty, isString } from "lodash";

export const PointLayer = (
  datasetId: string,
  dataset: Record<string, any>,
  config: Record<string, any>
) => {
  if (isEmpty(config)) return;

  const {
    filterConfig,
    radiusBasedOn,
    colorBasedOn,
    colorScale,
    colorRange,
    fillColor,
    radiusUnits,
  } = config;

  const getRadius = (d: any) => {
    if (radiusBasedOn) {
      const radiusValue = isString(d[radiusBasedOn])
        ? d[radiusBasedOn].length
        : d[radiusBasedOn];
      return valueScale(radiusValue);
    } else return 10;
  };

  const getFillColor = (d: any) => {
    if (colorBasedOn) {
      const colorValue = d[colorBasedOn];
      return colorScaleFunction(colorValue);
    } else return fillColor;
  };

  const valueScale = scaleLinear()
    .domain([...getMinMax(dataset, radiusBasedOn)])
    .range([0, 100]);

  const colorScaleFunction =
    colorScale === "quantize"
      ? scaleQuantize()
          .domain([
            ...findColorRange(
              dataset,
              colorScale,
              colorBasedOn,
              //@ts-ignore
              COLOR_RANGE[colorRange]
            ),
          ])
          .range(
            colorRange
              ? // @ts-ignore
                COLOR_RANGE[colorRange]
              : COLOR_RANGE["default"]
          )
      : colorScale === "quantile"
      ? scaleQuantile()
          .domain([
            ...findColorRange(
              dataset,
              colorScale,
              colorBasedOn,
              //@ts-ignore
              COLOR_RANGE[colorRange]
            ),
          ])
          .range(
            colorRange
              ? // @ts-ignore
                COLOR_RANGE[colorRange]
              : COLOR_RANGE["default"]
          )
      : scaleOrdinal()
          .domain([
            ...findColorRange(
              dataset,
              colorScale,
              colorBasedOn,
              //@ts-ignore
              COLOR_RANGE[colorRange]
            ),
          ])
          .range(
            colorRange
              ? // @ts-ignore
                COLOR_RANGE[colorRange]
              : COLOR_RANGE["default"]
          );
  const getFilterValue = (d: any) => {
    const result = getFilterValueFromDataset(d, filterConfig);
    return result;
  };

  const getFilterRange = () => {
    const result = getFilterRangeFromDataset(filterConfig);
    return result;
  };

  const getFilterSoftRange = () => {
    if (!isEmpty(filterConfig)) {
      let len = filterConfig.length;
      switch (len) {
        case 1:
          const range1 = filterConfig[0].data.range;
          return [
            [
              range1[0] * 0.9 + range1[1] * 0.1,
              range1[0] * 0.1 + range1[1] * 0.9,
            ],
            [-1, 1],
            [-1, 1],
          ];
        case 2:
          const range2 = filterConfig[0].data.range;
          const range3 = filterConfig[1].data.range;
          return [
            [
              range2[0] * 0.9 + range2[1] * 0.1,
              range2[0] * 0.1 + range2[1] * 0.9,
            ],
            [
              range3[0] * 0.9 + range3[1] * 0.1,
              range3[0] * 0.1 + range3[1] * 0.9,
            ],
            [-1, 1],
          ];
        case 3:
          const range4 = filterConfig[0].data.range;
          const range5 = filterConfig[1].data.range;
          const range6 = filterConfig[2].data.range;
          return [
            [
              range4[0] * 0.9 + range4[1] * 0.1,
              range4[0] * 0.1 + range4[1] * 0.9,
            ],
            [
              range5[0] * 0.9 + range5[1] * 0.1,
              range5[0] * 0.1 + range5[1] * 0.9,
            ],
            [
              range6[0] * 0.9 + range6[1] * 0.1,
              range6[0] * 0.1 + range6[1] * 0.9,
            ],
          ];
      }
    } else return null;
  };

  const test = new ScatterplotLayer({
    id: datasetId,
    data: dataset.rows,
    pickable: true,
    filled: true,
    autoHighligh: true,
    highlightColor: [250, 253, 15],
    getPosition: (d: any) => [d.lon, d.lat],
    getRadius,
    getFillColor,
    getFilterValue: (d: any) => getFilterValue(d),
    filterRange: getFilterRange(),
    filterSoftRange: getFilterSoftRange(),
    extensions: [
      new DataFilterExtension({
        filterSize: 3,
      }),
    ],
    updateTriggers: {
      getRadius: [radiusBasedOn, radiusUnits],
      getFillColor: [
        colorBasedOn,
        colorRange,
        getFillColor,
        fillColor,
        colorScale,
      ],
      getFilterValue: [filterConfig],
    },
    transitions: {
      getRadius: 100,
      radiusScale: 100,
      opacity: 100,
      getFillColor: 100,
    },
    ...config,
  });
  return test;
};
