import { InboxOutlined } from "@ant-design/icons";
import { message, Upload } from "antd";
import { parseFile } from "../functions/dataProcessor";

const { Dragger } = Upload;

export default function UploadFile({ processFile, setShowUploadModal }: any) {
  const props = {
    name: "file",
    multiple: true,
    accept: ".json, .csv, .geojson",
    customRequest: async ({ file, onSuccess, onError }: any) => {
      const reader = new FileReader();
      reader.onloadend = async (e) => {
        const data: any = e.target?.result;
        if (data) {
          onSuccess(file.name);
          processFile(parseFile(data), file.name);
          setShowUploadModal(false);
        } else {
          onError(file.name);
        }
      };
      reader.readAsText(file);
    },
    onSuccess(name: string) {
      message.success(`${name} file uploaded successfully.`);
    },
    onError(name: string) {
      message.error(`${name} file upload failed.`);
    },
  };

  return (
    <>
      <Dragger {...props} showUploadList={false}>
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">
          Click or drag file to this area to upload
        </p>
        <p className="ant-upload-hint">
          Support for a single or bulk upload. Strictly prohibit from uploading
          company data or other band files
        </p>
      </Dragger>
    </>
  );
}
