import {
  Typography,
  Input,
  Card,
  Divider,
  Descriptions,
  Space,
  Button,
} from "antd";
import { CloseOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
// import { StateTypes } from "../../model/data";
import LegendColor from "./LegendColor";
import { isEmpty } from "lodash";
import { HextoRGB } from "../functions/colorConversion";

const Legend = ({ mapDataset, setIsLegendVisible }: any) => {
  const {
    // user: { data },
    deckgl: { configlayers },
  } = useSelector((state: any) => state);

  const [legendData, setLegendData] = useState<[any]>();
  const [filteredLegendData, setFilteredLegendData] = useState<[any]>();

  useEffect(() => {
    const newData = Object.keys(configlayers)
      .filter((layerId: string) => configlayers[layerId].visible)
      .map((layerId) => ({
        title: mapDataset[layerId]?.name.split(".")[0],
        colorBasedOn: configlayers[layerId].colorBasedOn,
        color: configlayers[layerId].colorRange,
        customColor: configlayers[layerId]?.customColor?.map((item: any) =>
          HextoRGB(item)
        ),
        radiusBasedOn: configlayers[layerId].radiusBasedOn,
        heightBasedOn: configlayers[layerId].heightBasedOn,
        strokeWidthBasedOn: configlayers[layerId].strokeWidthBasedOn,
        strokeColorBasedOn: configlayers[layerId].strokeColorBasedOn,
        strokeColor: configlayers[layerId].strokeColorRange,
        customStrokeColor: configlayers[layerId]?.customStrokeColor?.map(
          (item: any) => HextoRGB(item)
        ),
        lineColor: configlayers[layerId].lineColor,
        fillColor: configlayers[layerId].fillColor,
        wireframe: configlayers[layerId].wireframe,
        stroked: configlayers[layerId].stroked,
        fillColorScaleType: configlayers[layerId].colorScale,
        strokeColorScaleType: configlayers[layerId].strokeColorScale,
        conditionEnabled:
          configlayers[layerId]?.fillConditionEnabled ??
          configlayers[layerId].conditionEnabled,
        condition:
          configlayers[layerId]?.fillCondition ??
          configlayers[layerId].condition,
        strokeConditionEnabled: configlayers[layerId]?.strokeConditionEnabled,
        strokeCondition: configlayers[layerId]?.strokeCondition,
        layerId: layerId,
        nullConfig: configlayers[layerId]?.nullConfig,
      }));
    // @ts-ignore
    setLegendData(newData);
    // @ts-ignore
    setFilteredLegendData(newData);
    // eslint-disable-next-line
  }, [configlayers, mapDataset]);

  const onSearch = (value: any) => {
    const search = value.nativeEvent.target.value;
    const newLegendData = legendData?.filter((legend: any) =>
      legend.title.toLowerCase().includes(search.toLowerCase())
    );
    // @ts-ignore
    setFilteredLegendData(newLegendData);
  };

  const handleClose = () => {
    setIsLegendVisible(false);
  };

  if (isEmpty(configlayers)) {
    return (
      <div
        style={{
          whiteSpace: "nowrap",
        }}
      >
        <Typography.Paragraph style={{ marginBottom: "0" }}>
          Please download or enable datasets to see legends.
        </Typography.Paragraph>
      </div>
    );
  }

  return (
    <Card
      className="legend"
      title={
        <Space className="legend-card-header" style={{ width: "100%" }}>
          <Input.Search placeholder="Search" onChange={onSearch} />
          <Button
            type="default"
            icon={<CloseOutlined />}
            onClick={handleClose}
          />
        </Space>
      }
      headStyle={{ padding: "0px 12px" }}
      bodyStyle={{
        padding: 12,
        overflowY: "scroll",
        overflowX: "hidden",
        maxHeight: "275px",
        minWidth: "250px",
      }}
    >
      {filteredLegendData &&
        filteredLegendData.map((dataset: any, index: any) => (
          <React.Fragment key={index}>
            <Typography.Title level={5} style={{ margin: 0 }}>
              {filteredLegendData[index].title}
            </Typography.Title>

            {filteredLegendData[index].colorBasedOn ? (
              <LegendColor
                mapDataset={mapDataset}
                colorBasedOn={dataset.colorBasedOn}
                color={dataset.color ? dataset.color : dataset?.customColor}
                isFillColor={true}
                layerId={dataset.layerId}
                colorScale={dataset.fillColorScaleType}
                conditionEnabled={dataset.conditionEnabled}
                condition={dataset.condition}
                nullConfig={dataset.nullConfig}
              />
            ) : (
              <Descriptions layout="horizontal" column={1} colon={false}>
                <Descriptions.Item
                  label={
                    <Typography.Text>Fill colour determined by</Typography.Text>
                  }
                  className="legend-items"
                >
                  <Typography.Text>None</Typography.Text>
                </Descriptions.Item>
                <Descriptions.Item
                  className="legend-items"
                  label={
                    <div
                      style={{
                        backgroundColor: `rgb(${dataset.fillColor[0]},${dataset.fillColor[1]},${dataset.fillColor[2]})`,
                        display: "flex",
                        width: "30px",
                        height: "20px",
                      }}
                    />
                  }
                >
                  <Typography.Text>Default colour</Typography.Text>
                </Descriptions.Item>
              </Descriptions>
            )}
            <Divider style={{ margin: 8 }} />
            {filteredLegendData[index].stroked &&
              (filteredLegendData[index].strokeColorBasedOn ? (
                <LegendColor
                  mapDataset={mapDataset}
                  colorBasedOn={dataset.strokeColorBasedOn}
                  color={
                    dataset.strokeColor
                      ? dataset.strokeColor
                      : dataset.customStrokeColor
                  }
                  isFillColor={false}
                  layerId={dataset.layerId}
                  colorScale={dataset.strokeColorScaleType}
                  conditionEnabled={dataset.strokeConditionEnabled}
                  condition={dataset.strokeCondition}
                  nullConfig={undefined}
                />
              ) : (
                <Descriptions layout="horizontal" column={1} colon={false}>
                  <Descriptions.Item
                    label={
                      <Typography.Text>
                        Stroke colour determined by
                      </Typography.Text>
                    }
                    className="legend-items"
                  >
                    <Typography.Text>None</Typography.Text>
                  </Descriptions.Item>
                  <Descriptions.Item
                    className="legend-items"
                    label={
                      <div
                        style={{
                          backgroundColor: `rgb(${dataset.lineColor[0]},${dataset.lineColor[1]},${dataset.lineColor[2]})`,
                          display: "flex",
                          width: "30px",
                          height: "20px",
                        }}
                      />
                    }
                  >
                    <Typography.Text>Default colour</Typography.Text>
                  </Descriptions.Item>
                </Descriptions>
              ))}
            {filteredLegendData[index].stroked &&
              filteredLegendData[index].strokeWidthBasedOn && (
                <Descriptions layout="vertical">
                  <Descriptions.Item
                    label={
                      <Typography.Text>Stroke determined by</Typography.Text>
                    }
                    className="legend-items"
                  >
                    <Typography.Text>
                      {filteredLegendData[index].strokeWidthBasedOn}
                    </Typography.Text>
                  </Descriptions.Item>
                </Descriptions>
              )}
            {filteredLegendData[index].radiusBasedOn && (
              <Descriptions layout="vertical">
                <Descriptions.Item
                  label={
                    <Typography.Text>Radius determined by</Typography.Text>
                  }
                  className="legend-items"
                >
                  <Typography.Text>
                    {filteredLegendData[index].radiusBasedOn}
                  </Typography.Text>
                </Descriptions.Item>
              </Descriptions>
            )}
            {filteredLegendData[index].wireframe &&
              filteredLegendData[index].heightBasedOn && (
                <Descriptions layout="vertical">
                  <Descriptions.Item
                    label={
                      <Typography.Text>Height determined by</Typography.Text>
                    }
                    className="legend-items"
                  >
                    <Typography.Text>
                      {filteredLegendData[index].heightBasedOn}
                    </Typography.Text>
                  </Descriptions.Item>
                </Descriptions>
              )}
            {index !== filteredLegendData.length - 1 && (
              <hr style={{ opacity: 0.3 }} />
            )}
          </React.Fragment>
        ))}
    </Card>
  );
};

export default Legend;
