import "./components.css";
import {
  CaretDownFilled,
  CaretRightFilled,
  DeleteFilled,
  PlusOutlined,
} from "@ant-design/icons";
import { Button, Collapse, Space, Typography } from "antd";
import { isEmpty } from "lodash";
import { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addFilterField, deleteFilter } from "../models/deckgl";
import SelectFilterField from "./SelectFilterField";

const Filter = ({ mapDataset, datasetId }: any) => {
  const {
    deckgl: { dataInfo, filters },
  } = useSelector((state: any) => state);
  const [activeKey, setActiveKey] = useState(true);
  const dispatch = useDispatch();

  const dataset = useMemo(() => {
    if (!isEmpty(datasetId)) return mapDataset[datasetId].value;
  }, [datasetId, mapDataset]);

  const handleDeleteFilter = () => {
    dispatch(deleteFilter(datasetId));
  };

  return (
    <div style={{ position: "relative" }}>
      <div
        style={{
          position: "absolute",
          height: 62,
          width: 3,
          zIndex: 2,
          borderTopLeftRadius: 5,
          borderBottomLeftRadius: 5,
          top: 1,
          left: 0,
          backgroundColor: !isEmpty(datasetId)
            ? `${dataInfo[datasetId]?.color}` || "white"
            : "white",
        }}
      />
      <Collapse
        className="filters"
        expandIconPosition="right"
        ghost
        defaultActiveKey="filter"
        expandIcon={() =>
          !activeKey ? (
            <Button
              disabled={!isEmpty(datasetId) ? false : true}
              type="text"
              icon={<CaretRightFilled />}
              onClick={() => setActiveKey(!activeKey)}
              style={{ marginRight: -8, color: "rgb(106,116,133)" }}
            />
          ) : (
            <Button
              disabled={!isEmpty(datasetId) ? false : true}
              type="text"
              icon={<CaretDownFilled />}
              onClick={() => setActiveKey(!activeKey)}
              style={{ marginRight: -8, color: "rgb(106,116,133)" }}
            />
          )
        }
      >
        <Collapse.Panel
          header={
            <div
              style={{
                width: "85%",
                padding: 8,
                backgroundColor: "#f0f8ff",
              }}
              onClick={(e) => e.stopPropagation()}
            >
              <Typography.Title
                ellipsis
                level={5}
                style={{
                  color: "var(--default-grey)",
                  margin: 0,
                  maxWidth: 175,
                }}
              >
                {mapDataset[datasetId].name}
              </Typography.Title>
            </div>
          }
          extra={
            <Button
              onClick={handleDeleteFilter}
              style={{ marginTop: 3 }}
              type="text"
              icon={
                <DeleteFilled
                  style={{ fontSize: 16, color: "rgb(106, 116, 133)" }}
                />
              }
            />
          }
          key="filter"
        >
          <Space direction="vertical" size="large" style={{ width: "100%" }}>
            {!isEmpty(datasetId) && (
              <Space direction="vertical" size="large">
                {filters[datasetId].map((item: any) => (
                  <div key={item.fieldId}>
                    <SelectFilterField
                      dataset={dataset}
                      datasetId={datasetId}
                      fieldId={item.fieldId}
                    />
                  </div>
                ))}
              </Space>
            )}
            {!isEmpty(datasetId) && filters[datasetId].length < 3 ? (
              <Button
                type="primary"
                block
                style={{ backgroundColor: "rgb(41, 50, 60)" }}
                onClick={() =>
                  dispatch(addFilterField({ datasetId: datasetId }))
                }
                icon={<PlusOutlined style={{ fontSize: 14, color: "white" }} />}
              >
                Add Field
              </Button>
            ) : null}
          </Space>
        </Collapse.Panel>
      </Collapse>
    </div>
  );
};

export default Filter;
