//@ts-ignore
import { GeoJsonLayer as Geo } from "@deck.gl/layers";
import {
  scaleQuantize,
  scaleQuantile,
  scaleLinear,
  scaleSqrt,
  scaleLog,
  //@ts-ignore
} from "d3-scale";
//@ts-ignore
import { DataFilterExtension } from "@deck.gl/extensions";
import { COLOR_RANGE } from "../constants/colorRange";
import {
  findColorRange,
  getFilterRangeFromDataset,
  getFilterValueFromDataset,
  getMinMax,
} from "../functions/layerProperties";
//@ts-ignore
import { isEmpty, isString } from "lodash";

export const GeoJsonLayer = (
  datasetId: string,
  dataset: Record<string, any>,
  config: any
) => {
  if (isEmpty(config)) return;

  const {
    colorRange,
    colorScale,
    colorBasedOn,
    strokeColorBasedOn,
    strokeWidthBasedOn,
    strokeColorRange,
    strokeWidthScale,
    strokeColorScale,
    fillColor,
    lineColor,
    filterConfig,
    extruded,
    heightBasedOn,
    heightScale,
    visible,
    radiusBasedOn,
  } = config;

  const getPointRadius = (d: any) => {
    if (radiusBasedOn) {
      const radiusValue =
        typeof d.properties[radiusBasedOn] === "string"
          ? d.properties[radiusBasedOn].length
          : d.properties[radiusBasedOn];
      return valueScale(radiusValue);
    } else return 100;
  };

  const valueScale = scaleLinear()
    .domain([...getMinMax(dataset, radiusBasedOn)])
    .range([0, 500]);

  const getFillColor = (d: any) => {
    if (colorBasedOn) {
      const colorValue = d.properties[colorBasedOn];
      return colorScaleFunction(colorValue);
    } else return fillColor;
  };

  const getLineColor = (d: any) => {
    if (strokeColorBasedOn) {
      const strokeColor = d.properties[strokeColorBasedOn];
      return strokeColorScaleFunction(strokeColor);
    } else return lineColor;
  };

  const getLineWidth = (d: any) => {
    if (strokeWidthBasedOn) {
      const lineWidth = isString(d.properties[strokeWidthBasedOn])
        ? d.properties[strokeWidthBasedOn].length
        : d.properties[strokeWidthBasedOn];
      return strokeValueScale(lineWidth) / 10;
    } else return 1;
  };

  const getElevation = (d: any) => {
    if (!extruded) return 0;
    if (heightBasedOn) {
      const heightValue = isString(d.properties[heightBasedOn])
        ? d.properties[heightBasedOn].length
        : d.properties[heightBasedOn];
      return heightValueScale(heightValue) * 100;
    } else return 100;
  };

  const strokeValueScale =
    strokeWidthScale === "linear"
      ? scaleLinear()
          .domain([...getMinMax(dataset, strokeWidthBasedOn)])
          .range([0, 100])
      : strokeWidthScale === "log"
      ? scaleLog()
          .domain([...getMinMax(dataset, strokeWidthBasedOn)])
          .range([0, 100])
      : scaleSqrt()
          .domain([...getMinMax(dataset, strokeWidthBasedOn)])
          .range([0, 100]);

  const heightValueScale =
    heightScale === "linear"
      ? scaleLinear()
          .domain([...getMinMax(dataset, heightBasedOn)])
          .range([0, 100])
      : heightScale === "log"
      ? scaleLog()
          .domain([...getMinMax(dataset, heightBasedOn)])
          .range([0, 100])
      : scaleSqrt()
          .domain([...getMinMax(dataset, heightBasedOn)])
          .range([0, 100]);

  const colorScaleFunction =
    colorScale === "quantize"
      ? scaleQuantize()
          .domain([
            ...findColorRange(
              dataset,
              colorScale,
              colorBasedOn,
              //@ts-ignore
              COLOR_RANGE[colorRange]
            ),
          ])
          .range(
            colorRange
              ? // @ts-ignore
                COLOR_RANGE[colorRange]
              : COLOR_RANGE["default"]
          )
      : scaleQuantile()
          .domain([
            ...findColorRange(
              dataset,
              colorScale,
              colorBasedOn,
              //@ts-ignore
              COLOR_RANGE[colorRange]
            ),
          ])
          .range(
            colorRange
              ? // @ts-ignore
                COLOR_RANGE[colorRange]
              : COLOR_RANGE["default"]
          );

  const strokeColorScaleFunction =
    config.strokeColorScale === "quantize"
      ? scaleQuantize()
          .domain([
            ...findColorRange(
              dataset,
              strokeColorScale,
              strokeColorBasedOn,
              //@ts-ignore
              COLOR_RANGE[strokeColorRange]
            ),
          ])
          .range(
            strokeColorRange
              ? // @ts-ignore
                COLOR_RANGE[strokeColorRange]
              : COLOR_RANGE["default"]
          )
      : scaleQuantile()
          .domain([
            ...findColorRange(
              dataset,
              strokeColorScale,
              strokeColorBasedOn,
              //@ts-ignore
              COLOR_RANGE[strokeColorRange]
            ),
          ])
          .range(
            strokeColorRange
              ? // @ts-ignore
                COLOR_RANGE[strokeColorRange]
              : COLOR_RANGE["default"]
          );

  const getFilterValue = (d: any) => {
    const result = getFilterValueFromDataset(d, filterConfig);
    return result;
  };

  const getFilterRange = () => {
    const result = getFilterRangeFromDataset(filterConfig);
    return result;
  };

  return new Geo({
    id: datasetId,
    data: dataset.rows,
    pickable: true,
    filled: true,
    autoHighlight: true,
    highlightColor: [250, 253, 15],
    visible: visible,
    pointType: "circle",
    pointRadiusUnits: "meters",
    pointRadiusMinPixels: 1,
    pointRadiusMaxPixels: 100,
    lineWidthMaxPixels: 100,
    lineWidthMinPixels: 1,
    lineWidthUnits: "meters",
    getPointRadius,
    getFillColor,
    getLineColor,
    getLineWidth,
    getElevation,
    getFilterValue: (d: any) => getFilterValue(d),
    filterRange: getFilterRange(),
    extensions: [
      new DataFilterExtension({
        filterSize: 3,
      }),
    ],
    updateTriggers: {
      getFillColor: [
        colorBasedOn,
        colorRange,
        getFillColor,
        fillColor,
        colorScale,
      ],
      getPointRadius: [radiusBasedOn],
      getLineWidth: [strokeWidthBasedOn, strokeWidthScale],
      getLineColor: [
        strokeColorBasedOn,
        strokeColorScale,
        strokeColorRange,
        lineColor,
        getLineColor,
      ],
      getElevation: [heightBasedOn, heightScale],
      getFilterValue: [filterConfig],
    },
    ...config,
    _subLayerProps: {
      "polygons-fill": {
        autoHighlight: false,
      },
      "points-circle": {
        autoHighlight: true,
      },
    },
  });
};
