export const geoConfig = {
  filled: true,
  visible: true,
  extruded: false,
  opacity: 0.8,
  stroked: true,
  wireframe: false,
  pointRadiusScale: 10,
  lineWidthScale: 1,
  fillConditionEnabled: false,
  fillCondition: null,
  strokeConditionEnabled: false,
  strokeCondition: null,
  colorScale: "quantile",
  colorRange: "default",
  strokeColorScale: "quantile",
  strokeColorRange: "default",
  heightScale: "linear",
  strokeWidthScale: "linear",
  elevationScale: 1,
  fillColor: [237, 146, 42],
  lineColor: [0, 0, 0],
  datatype: "CsvCollections",
};

export const pointConfig = {
  pickable: true,
  visible: true,
  radiusScale: 10,
  radiusUnits: "meters",
  conditionEnabled: false,
  condition: null,
  radiusMinPixels: 2,
  radiusMaxPixels: 200,
  opacity: 0.8,
  colorScale: "quantile",
  colorRange: "default",
  fillColor: [237, 42, 66],
  autoHighlight: true,
  datatype: "Point",
};
