import {
  CloseOutlined,
  ExclamationCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import {
  Alert,
  Button,
  Checkbox,
  Drawer,
  Empty,
  Form,
  Input,
  message,
  Modal,
  Popconfirm,
  Space,
  Typography,
} from "antd";
import { cloneDeep, isEmpty } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { updateCategoriesState } from "../models/deckgl";
import { chooseDb } from "../services/firebase";
import DirectoryTree from "./DirectoryTree";
import DraggableTree from "./DraggableTree";

const CategoryModal = ({
  visible,
  onCancel,
  selectedProject,
  datasetKey,
}: any) => {
  const [categories, setCategories] = useState<undefined | any[]>([]);
  const [drawerVisible, setDrawerVisible] = useState<boolean>(false);
  const [selectedCategory, setSelectedCategory] = useState<string>("");
  const [isLeaf, setIsLeaf] = useState<boolean>(true);
  const [categoryTitle, setCategoryTitle] = useState<string>("");
  // const [datasetKey, setDatasetKey] = useState<string>("");
  // const [tileUrl, setTileUrl] = useState<string>("");
  const [fsDb, setFsDb] = useState<string>("");
  const dispatch = useDispatch();

  const db = useMemo(() => {
    return chooseDb(fsDb);
  }, [fsDb]);

  useEffect(() => {
    if (selectedProject) {
      if (selectedProject === "pkc") {
        setFsDb("pkc");
      } else if (selectedProject === "dcc") {
        setFsDb("resop");
      } else if (selectedProject === "occ") {
        setFsDb("aitl-lear");
      } else if (selectedProject === "wmca") {
        setFsDb("wmca");
      } else if (["ukpn", "ukpn_pbh"].includes(selectedProject)) {
        setFsDb("ukpn");
      }
    }
  }, [selectedProject]);

  useEffect(() => {
    const unsubscribe = db.dev
      .collection("constants")
      .doc("categories")
      .onSnapshot((snapshot) => {
        setCategories(snapshot.data()?.data);
        dispatch(updateCategoriesState(snapshot.data()?.data));
      });

    return () => unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [db]);

  const updateCategories = (newCategories: any) => {
    if (fsDb === "aitl-lear") {
      db.dev
        .collection("constants")
        .doc("categories")
        .set({ data: newCategories });
      // db.prod
      //   .collection("constants")
      //   .doc("categories")
      //   .set({ data: newCategories });
    } else {
      db.dev
        .collection("constants")
        .doc("categories")
        .set({ data: newCategories });
    }
  };

  const drawerOpenHandler = (key: string) => {
    setDrawerVisible(true);
    setSelectedCategory(key);
  };

  const resetState = () => {
    setSelectedCategory("");
    setIsLeaf(true);
    setCategoryTitle("");
    // setDatasetKey("");
    setDrawerVisible(false);
  };

  const getNewCategory = (
    isLeaf: boolean,
    categoryTitle: string,
    datasetKey: string
  ) => {
    const uniqueId = Math.floor((1 + Math.random()) * 0x1000000).toString(16);
    if (isLeaf) {
      return {
        id: uniqueId,
        key: datasetKey,
        title: categoryTitle,
        checkable: true,
        isLeaf: true,
        children: [],
      };
    } else {
      return {
        id: uniqueId,
        key: uniqueId,
        title: categoryTitle,
        checkable: true,
        isLeaf: false,
        children: [],
      };
    }
  };

  const getParentFolder = (parentKey: string, tree: any) => {
    if (tree.key === parentKey) {
      return tree;
    }

    for (const item of tree.children) {
      if (!item.isLeaf) {
        const parentFolder: any = getParentFolder(parentKey, item);
        if (parentFolder) return parentFolder;
      }
    }
  };

  const addCategoryHandler = () => {
    const category = getNewCategory(isLeaf, categoryTitle, datasetKey);
    const tree = cloneDeep(categories);
    if (isEmpty(selectedCategory)) {
      tree?.push(category);
    } else {
      tree?.forEach((item) => {
        const parentFolder = getParentFolder(selectedCategory, item);
        if (parentFolder) parentFolder.children.push(category);
      });
    }
    // updateCategories(tree);
    setCategories(tree);
    message.success("New category added successfully!");
    resetState();
  };

  const removeCategoryHandler = (parentKey: string | null, itemKey: string) => {
    Modal.confirm({
      title: "Do you want to delete this category?",
      icon: <ExclamationCircleOutlined />,
      onOk() {
        let tree = cloneDeep(categories);
        if (!parentKey) {
          tree = tree?.filter((item) => item.key !== itemKey);
        } else {
          tree?.forEach((item) => {
            const parentFolder = getParentFolder(parentKey, item);
            if (parentFolder)
              parentFolder.children = parentFolder.children.filter(
                (item: any) => item.key !== itemKey
              );
          });
        }
        // updateCategories(tree);
        setCategories(tree);
        message.success("Category removed successfully!");
        resetState();
      },
      onCancel() {},
    });
  };

  // Drag Mode State
  const [dragMode, setDragMode] = useState<boolean>(false);

  const onChangeDragMode = (e: any) => {
    if (e.target.checked) {
      message.info({
        content: "Drag mode enabled!",
        config: {
          right: 0,
        },
      });
      setDragMode(true);
    } else {
      message.info("Drag mode disabled!");
      setDragMode(false);
    }
  };

  const [submitLoader, setSubmitLoader] = useState<boolean>(false);

  const submitCategories = () => {
    setSubmitLoader(true);
    updateCategories(categories);
    setTimeout(() => {
      setSubmitLoader(false);
      message.success("Categories updated successfully!");
    }, 1000);
  };

  return (
    <Modal
      // title="Add Category"
      title={
        <Space style={{ display: "flex", justifyContent: "space-between" }}>
          <Typography.Title level={5} style={{ margin: 0 }}>
            Add Category
          </Typography.Title>
          <Checkbox
            // style={{
            //   marginRight: "5em",
            // }}
            onChange={onChangeDragMode}
          >
            Enable Drag Mode
          </Checkbox>
          <Button
            style={{
              marginRight: "3em",
              backgroundColor: "rgb(15, 150, 104)",
              color: "white",
            }}
            onClick={submitCategories}
            loading={submitLoader}
          >
            Submit
          </Button>
        </Space>
      }
      visible={visible}
      closable
      onCancel={onCancel}
      width={525}
      footer={
        dragMode ? null : (
          <Popconfirm
            title={
              <Space direction="vertical" size="small">
                <Typography.Paragraph style={{ margin: 0 }}>
                  This will add a new category to the root category list.
                </Typography.Paragraph>
                <Typography.Paragraph style={{ margin: 0 }}>
                  Are you sure you want to add new category in the root.
                </Typography.Paragraph>
              </Space>
            }
            onConfirm={() => setDrawerVisible(true)}
            okText="Yes"
          >
            <Button block type="primary" icon={<PlusOutlined />}>
              Add new parent category
            </Button>
          </Popconfirm>
        )
      }
      bodyStyle={{
        height: "400px",
        overflow: "hidden",
        position: "relative",
        padding: "8px 24px 8px 24px",
      }}
    >
      <Space direction="vertical" style={{ width: "100%" }}>
        <Alert
          type="info"
          showIcon
          message={`Selected Project : ${
            selectedProject === "pkc"
              ? "Perth and Kinross Council"
              : selectedProject === "dcc"
              ? "Dundee City Council"
              : selectedProject === "occ"
              ? "Oxfordshire County Council"
                ? selectedProject === "wmca"
                : "Coventry City Council"
              : ""
          }`}
        />
        {/* <Select
          placeholder="Select project"
          style={{ width: "100%" }}
          onSelect={(val: string) => setFsDb(val)}
          value={fsDb}
        >
          {projects.map((item: string) => (
            <Select.Option key={item} value={item}>
              {item}
            </Select.Option>
          ))}
        </Select> */}
        <div
          style={{
            height: 295,
            padding: "8px 8px 8px 0px ",
            overflowY: "scroll",
          }}
        >
          {isEmpty(categories) ? (
            <Empty style={{ marginTop: 60 }} />
          ) : !dragMode ? (
            categories?.map((category) => (
              <DirectoryTree
                key={category.key}
                itemKey={category.key}
                item={category}
                onAddCategory={drawerOpenHandler}
                onRemoveCategory={removeCategoryHandler}
              />
            ))
          ) : (
            <DraggableTree
              categories={categories}
              setCategories={setCategories}
            />
          )}
        </div>
      </Space>
      <Drawer
        title="Enter Category Details"
        visible={drawerVisible}
        getContainer={false}
        placement="bottom"
        height={260}
        closable={false}
        extra={<CloseOutlined onClick={() => setDrawerVisible(false)} />}
        style={{ position: "absolute" }}
      >
        <Form onFinish={addCategoryHandler}>
          <Checkbox
            checked={!isLeaf}
            onChange={(e) => setIsLeaf(!e.target.checked)}
            style={{ marginBottom: 10 }}
          >
            Create category folder
          </Checkbox>
          <Input
            placeholder="Enter category title"
            value={categoryTitle}
            onChange={(e) => setCategoryTitle(e.target.value)}
            style={{ marginBottom: 10 }}
          />
          {/* <Input
            placeholder="Enter dataset key"
            disabled={!isLeaf}
            value={datasetKey}
            onChange={(e) => setDatasetKey(e.target.value)}
            style={{ marginBottom: 10 }}
          />
          {fsDb === "resop" && (
            <Input
              placeholder="Enter tile URL"
              disabled={!isLeaf}
              value={tileUrl}
              onChange={(e) => setTileUrl(e.target.value)}
              style={{ marginBottom: 10 }}
            />
          )} */}
          <Button type="primary" htmlType="submit">
            Add Category
          </Button>
        </Form>
      </Drawer>
    </Modal>
  );
};

export default CategoryModal;
