import { SearchOutlined } from "@ant-design/icons";
import {
  Dropdown,
  Input,
  Menu,
  Select,
  Space,
  Tag,
  Typography,
  Form,
  Button,
  message,
} from "antd";
import { isEmpty, lowerCase } from "lodash";
import { useMemo, useState } from "react";
import { COLOR_RANGE } from "../../../constants/colorRange";
import { HextoRGB, RGBToHex } from "../../../functions/colorConversion";
import useUpdateConfig from "../../../hooks/useUpdateConfig";

const ChooseConditionFields = ({ id, fields, rows, config, name }: any) => {
  const [search, setSearch] = useState("");
  const [selectedValues, setSelectedValues] = useState<string[]>([]);

  const { updateConfig } = useUpdateConfig();

  const [form] = Form.useForm();

  const handleSelect = (value: string) => {
    setSelectedValues([...selectedValues, value]);
  };

  const handleDelete = (val: string) => {
    setSelectedValues([...selectedValues.filter((item) => item !== val)]);
  };

  const handleSubmit = () => {
    form.validateFields().then((formData) => {
      const values = Object.values(formData);
      const cnd = name === "Fill" ? "fillCondition" : "strokeCondition";
      updateConfig(id, cnd, { fieldNames: selectedValues, values });
    });
    message.success({
      content: `${name} condition applied`,
    });
  };

  const getColorRange = () => {
    if (name === "Fill") {
      return (
        //@ts-ignore
        COLOR_RANGE[config?.colorRange] ??
        config?.customColor?.map((item: any) => HextoRGB(item))
      );
    } else {
      return (
        //@ts-ignore
        COLOR_RANGE[config?.strokeColorRange] ??
        config?.customStrokeColor?.map((item: any) => HextoRGB(item))
      );
    }
  };

  const rowValues = useMemo(() => {
    if (!isEmpty(selectedValues)) {
      return Array.from(
        new Set(rows.map((item: any) => item[selectedValues[0]]))
      );
    } else return null;
  }, [selectedValues, rows]);

  const overlay = (
    <Menu className="select-fields layer">
      <Menu.Item style={{ pointerEvents: "inherit" }} key="search" disabled>
        <Input
          placeholder="search fields..."
          suffix={<SearchOutlined />}
          style={{
            border: 0,
          }}
          value={search}
          onChange={(val) => setSearch(val.target.value)}
        />
      </Menu.Item>
      {fields &&
        fields
          .filter((item: any) =>
            lowerCase(item.name).includes(lowerCase(search))
          )
          .filter((item: any) => !selectedValues.includes(item))
          .map((data: any, j: number) => (
            <Menu.Item
              onClick={() => handleSelect(data.name)}
              key={`${data.name}_${j}`}
            >
              <Space
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                }}
              >
                <Tag
                  style={{
                    // backgroundColor: "transparent",
                    fontSize: 8,
                    width: 55,
                    textAlign: "center",
                  }}
                  color={
                    data.analyzerType === "INT"
                      ? "success"
                      : data.analyzerType === "FLOAT"
                      ? "yellow"
                      : data.analyzerType === "BOOLEAN"
                      ? "pink"
                      : data.analyzerType === "DATETIME"
                      ? "volcano"
                      : "cyan"
                  }
                >
                  {data.type}
                </Tag>
                {data.name}
              </Space>
            </Menu.Item>
          ))}
    </Menu>
  );
  return (
    <Space direction="vertical" style={{ width: "100%" }}>
      <div className="select-fields-input">
        <Dropdown
          overlay={overlay}
          trigger={["click"]}
          placement="bottom"
          getPopupContainer={(): any => document.querySelector(".layer")}
        >
          <div
            style={{
              width: 190,
              height: 40,
              display: "flex",
              alignItems: "center",
            }}
          >
            {isEmpty(selectedValues)
              ? "select column"
              : selectedValues.map((item, i) => (
                  <Tag
                    style={{ margin: 0 }}
                    closable
                    onClose={() => handleDelete(item)}
                    key={item}
                  >
                    {item}
                  </Tag>
                ))}
          </div>
        </Dropdown>
      </div>
      {isEmpty(selectedValues) ? (
        <Typography.Text>Please select a column name</Typography.Text>
      ) : isEmpty(config?.colorBasedOn || config?.strokeColorBasedOn) ? (
        <Typography.Text>Please select a color step first</Typography.Text>
      ) : (
        <Space direction="vertical">
          <Form form={form} size="small" onFinish={handleSubmit}>
            {getColorRange()?.map((color: any, i: number) => {
              return (
                <Space size="large">
                  <div
                    style={{
                      width: 28,
                      height: 20,
                      backgroundColor: RGBToHex(color),
                    }}
                  />
                  <Form.Item
                    name={i}
                    rules={[{ required: true }]}
                    style={{ marginBottom: 8 }}
                  >
                    <Select
                      style={{ width: 190 }}
                      size="small"
                      showArrow={false}
                      placeholder="select value"
                    >
                      {rowValues?.map((item: any, i) => (
                        <Select.Option value={item}>{item}</Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Space>
              );
            })}
            <Button htmlType="submit" type="primary" size="middle" block>
              Apply Condition
            </Button>
          </Form>
        </Space>
      )}
    </Space>
  );
};

export default ChooseConditionFields;
