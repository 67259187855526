export const COLOR_RANGE = {
  yellowBlue: [
    [255, 255, 153],
    [56, 108, 176],
  ],
  RedYellow: [
    [199, 0, 57],
    [255, 195, 0],
  ],
  grayYellow: [
    [148, 147, 152],
    [244, 223, 78],
  ],
  RedBlue: [
    [252, 118, 106],
    [91, 132, 176],
  ],
  blackWhite: [
    [73, 73, 73],
    [251, 251, 251],
  ],
  blueOrange: [
    [33, 156, 149],
    [231, 83, 0],
  ],
  yellowViolet: [
    [245, 255, 0],
    [116, 7, 136],
  ],
  blueCream: [
    [0, 65, 133],
    [254, 136, 47],
  ],
  greenRed: [
    [0, 133, 14],
    [213, 4, 0],
  ],
  orangeGray: [
    [231, 83, 0],
    [148, 147, 152],
  ],
  greenGray: [
    [0, 133, 14],
    [148, 147, 152],
  ],
  blackTransparent: [
    [0, 0, 0],
    [255, 255, 255, 0],
  ],
  rYellowBlue: [
    [255, 255, 153],
    [56, 108, 176],
  ].reverse(),
  rRedYellow: [
    [199, 0, 57],
    [255, 195, 0],
  ].reverse(),
  rGrayYellow: [
    [148, 147, 152],
    [244, 223, 78],
  ].reverse(),
  rRedBlue: [
    [252, 118, 106],
    [91, 132, 176],
  ].reverse(),
  rBlackWhite: [
    [73, 73, 73],
    [251, 251, 251],
  ].reverse(),
  rBlueOrange: [
    [33, 156, 149],
    [231, 83, 0],
  ].reverse(),
  rYellowViolet: [
    [245, 255, 0],
    [116, 7, 136],
  ].reverse(),
  rBlueCream: [
    [0, 65, 133],
    [254, 136, 47],
  ].reverse(),
  rGreenRed: [
    [0, 133, 14],
    [213, 4, 0],
  ].reverse(),
  rOrangeGray: [
    [231, 83, 0],
    [148, 147, 152],
  ].reverse(),
  rGreenGray: [
    [0, 133, 14],
    [148, 147, 152],
  ].reverse(),
  rblackTransparent: [
    [0, 0, 0],
    [255, 255, 255, 0],
  ].reverse(),
  divergingBlueRed_3: [
    [0, 147, 156],
    [254, 238, 232],
    [194, 46, 0],
  ],
  divergingBluePinkYellow_3: [
    [33, 62, 154],
    [202, 22, 142],
    [249, 226, 0],
  ],
  divergingBluePink_3: [
    [1, 152, 189],
    [250, 254, 179],
    [213, 2, 85],
  ],
  qualitativeBlueBrown_3: [
    [18, 147, 154],
    [221, 178, 124],
    [136, 87, 44],
  ],
  sequentialVioletYellow_3: [
    [90, 24, 70],
    [199, 0, 57],
    [255, 195, 0],
  ],
  sequentialBluePink_3: [
    [53, 92, 125],
    [192, 108, 132],
    [248, 177, 149],
  ],
  sequentialBlueCream_3: [
    [58, 116, 138],
    [62, 172, 168],
    [229, 238, 193],
  ],
  sequentialPurple2_3: [
    [80, 49, 94],
    [149, 100, 133],
    [237, 209, 202],
  ],
  sequentialPurpleGreen_3: [
    [43, 30, 62],
    [94, 162, 141],
    [214, 222, 191],
  ],
  sequentialGreen1_3: [
    [247, 252, 185],
    [173, 221, 142],
    [49, 163, 84],
  ],
  sequentialBlue1_3: [
    [237, 248, 177],
    [127, 205, 187],
    [44, 127, 184],
  ],
  sequentialBlue2_3: [
    [224, 243, 219],
    [168, 221, 181],
    [67, 162, 202],
  ],
  sequentialGreen2_3: [
    [229, 245, 249],
    [153, 216, 201],
    [44, 162, 95],
  ],
  sequentialBlue3_3: [
    [236, 226, 240],
    [166, 189, 219],
    [28, 144, 153],
  ],
  sequentialBlue4_3: [
    [236, 231, 242],
    [166, 189, 219],
    [43, 140, 190],
  ],
  sequentialBlue5_3: [
    [230, 250, 250],
    [137, 198, 202],
    [0, 147, 156],
  ],
  sequentialPurple1_3: [
    [224, 236, 244],
    [158, 188, 218],
    [136, 86, 167],
  ],
  sequentialPink1_3: [
    [253, 224, 221],
    [250, 159, 181],
    [197, 27, 138],
  ],
  sequentialPink2_3: [
    [231, 225, 239],
    [201, 148, 199],
    [221, 28, 119],
  ],
  sequentialOrange1_3: [
    [254, 232, 200],
    [253, 187, 132],
    [227, 74, 51],
  ],
  sequentialOrange2_3: [
    [255, 237, 160],
    [254, 178, 76],
    [240, 59, 32],
  ],
  sequentialOrange3_3: [
    [255, 247, 188],
    [254, 196, 79],
    [217, 95, 14],
  ],
  singlehueViolet_3: [
    [239, 237, 245],
    [188, 189, 220],
    [117, 107, 177],
  ],
  singlehueBlue_3: [
    [222, 235, 247],
    [158, 202, 225],
    [49, 130, 189],
  ],
  singlehueGreen_3: [
    [229, 245, 224],
    [161, 217, 155],
    [49, 163, 84],
  ],
  singlehueOrange_3: [
    [254, 230, 206],
    [253, 174, 107],
    [230, 85, 13],
  ],
  singlehueRed_3: [
    [254, 224, 210],
    [252, 146, 114],
    [222, 45, 38],
  ],
  singlehueGray_3: [
    [240, 240, 240],
    [189, 189, 189],
    [99, 99, 99],
  ],
  divergingOrangePurple_3: [
    [241, 163, 64],
    [247, 247, 247],
    [153, 142, 195],
  ],
  divergingOrangeGreen3_3: [
    [216, 179, 101],
    [245, 245, 245],
    [90, 180, 172],
  ],
  divergingPurpleGreen_3: [
    [175, 141, 195],
    [247, 247, 247],
    [127, 191, 123],
  ],
  divergingPinkGreen_3: [
    [233, 163, 201],
    [247, 247, 247],
    [161, 215, 106],
  ],
  divergingOrangeBlue1_3: [
    [239, 138, 98],
    [247, 247, 247],
    [103, 169, 207],
  ],
  divergingOrangeGray_3: [
    [239, 138, 98],
    [255, 255, 255],
    [153, 153, 153],
  ],
  divergingOrangeBlue2_3: [
    [252, 141, 89],
    [255, 255, 191],
    [145, 191, 219],
  ],
  divergingOrangeGreen1_3: [
    [252, 141, 89],
    [255, 255, 191],
    [153, 213, 148],
  ],
  divergingOrangeGreen2_3: [
    [252, 141, 89],
    [255, 255, 191],
    [145, 207, 96],
  ],
  qualitativeGreenOrange_3: [
    [127, 201, 127],
    [190, 174, 212],
    [253, 192, 134],
  ],
  qualitativeGreenPurple1_3: [
    [27, 158, 119],
    [217, 95, 2],
    [117, 112, 179],
  ],
  qualitativeBlueGreen_3: [
    [166, 206, 227],
    [31, 120, 180],
    [178, 223, 138],
  ],
  qualitativePinkGreen_3: [
    [251, 180, 174],
    [179, 205, 227],
    [204, 235, 197],
  ],
  qualitativeGreenPurple2_3: [
    [179, 226, 205],
    [253, 205, 172],
    [203, 213, 232],
  ],
  diverginRedGreen_3: [
    [255, 0, 0],
    [255, 191, 0],
    [0, 255, 0],
  ],
  qualitativeRedGreen_3: [
    [228, 26, 28],
    [55, 126, 184],
    [77, 175, 74],
  ],
  qualitativeGreenPurple3_3: [
    [102, 194, 165],
    [252, 141, 98],
    [141, 160, 203],
  ],
  qualitativeGreenPurple4_3: [
    [141, 211, 199],
    [255, 255, 179],
    [190, 186, 218],
  ],
  rDivergingBlueRed_3: [
    [0, 147, 156],
    [254, 238, 232],
    [194, 46, 0],
  ].reverse(),
  rDivergingBluePinkYellow_3: [
    [33, 62, 154],
    [202, 22, 142],
    [249, 226, 0],
  ].reverse(),
  rQualitativeBlueBrown_3: [
    [18, 147, 154],
    [221, 178, 124],
    [136, 87, 44],
  ].reverse(),
  rDivergingBluePink_3: [
    [1, 152, 189],
    [250, 254, 179],
    [213, 2, 85],
  ].reverse(),
  rSequentialVioletYellow_3: [
    [90, 24, 70],
    [199, 0, 57],
    [255, 195, 0],
  ].reverse(),
  rSequentialBluePink_3: [
    [53, 92, 125],
    [192, 108, 132],
    [248, 177, 149],
  ].reverse(),
  rSequentialBlueCream_3: [
    [58, 116, 138],
    [62, 172, 168],
    [229, 238, 193],
  ].reverse(),
  rSequentialPurple2_3: [
    [80, 49, 94],
    [149, 100, 133],
    [237, 209, 202],
  ].reverse(),
  rSequentialPurpleGreen_3: [
    [43, 30, 62],
    [94, 162, 141],
    [214, 222, 191],
  ].reverse(),
  rSequentialGreen1_3: [
    [247, 252, 185],
    [173, 221, 142],
    [49, 163, 84],
  ].reverse(),
  rSequentialBlue1_3: [
    [237, 248, 177],
    [127, 205, 187],
    [44, 127, 184],
  ].reverse(),
  rSequentialBlue2_3: [
    [224, 243, 219],
    [168, 221, 181],
    [67, 162, 202],
  ].reverse(),
  rSequentialGreen2_3: [
    [229, 245, 249],
    [153, 216, 201],
    [44, 162, 95],
  ].reverse(),
  rSequentialBlue3_3: [
    [236, 226, 240],
    [166, 189, 219],
    [28, 144, 153],
  ].reverse(),
  rSequentialBlue4_3: [
    [236, 231, 242],
    [166, 189, 219],
    [43, 140, 190],
  ].reverse(),
  rSequentialBlue5_3: [
    [230, 250, 250],
    [137, 198, 202],
    [0, 147, 156],
  ].reverse(),
  rSequentialPurple1_3: [
    [224, 236, 244],
    [158, 188, 218],
    [136, 86, 167],
  ].reverse(),
  rSequentialPink1_3: [
    [253, 224, 221],
    [250, 159, 181],
    [197, 27, 138],
  ].reverse(),
  rSequentialPink2_3: [
    [231, 225, 239],
    [201, 148, 199],
    [221, 28, 119],
  ].reverse(),
  rSequentialOrange1_3: [
    [254, 232, 200],
    [253, 187, 132],
    [227, 74, 51],
  ].reverse(),
  rSequentialOrange2_3: [
    [255, 237, 160],
    [254, 178, 76],
    [240, 59, 32],
  ].reverse(),
  rSequentialOrange3_3: [
    [255, 247, 188],
    [254, 196, 79],
    [217, 95, 14],
  ].reverse(),
  rSinglehueViolet_3: [
    [239, 237, 245],
    [188, 189, 220],
    [117, 107, 177],
  ].reverse(),
  rSinglehueBlue_3: [
    [222, 235, 247],
    [158, 202, 225],
    [49, 130, 189],
  ].reverse(),
  rSinglehueGreen_3: [
    [229, 245, 224],
    [161, 217, 155],
    [49, 163, 84],
  ].reverse(),
  rSinglehueOrange_3: [
    [254, 230, 206],
    [253, 174, 107],
    [230, 85, 13],
  ].reverse(),
  rSinglehueRed_3: [
    [254, 224, 210],
    [252, 146, 114],
    [222, 45, 38],
  ].reverse(),
  rSinglehueGray_3: [
    [240, 240, 240],
    [189, 189, 189],
    [99, 99, 99],
  ].reverse(),
  rDivergingOrangePurple_3: [
    [241, 163, 64],
    [247, 247, 247],
    [153, 142, 195],
  ].reverse(),
  rDivergingOrangeGreen3_3: [
    [216, 179, 101],
    [245, 245, 245],
    [90, 180, 172],
  ].reverse(),
  rDivergingPurpleGreen_3: [
    [175, 141, 195],
    [247, 247, 247],
    [127, 191, 123],
  ].reverse(),
  rDivergingPinkGreen_3: [
    [233, 163, 201],
    [247, 247, 247],
    [161, 215, 106],
  ].reverse(),
  rDivergingOrangeBlue1_3: [
    [239, 138, 98],
    [247, 247, 247],
    [103, 169, 207],
  ].reverse(),
  rDivergingOrangeGray_3: [
    [239, 138, 98],
    [255, 255, 255],
    [153, 153, 153],
  ].reverse(),
  rDivergingOrangeBlue2_3: [
    [252, 141, 89],
    [255, 255, 191],
    [145, 191, 219],
  ].reverse(),
  rDivergingOrangeGreen1_3: [
    [252, 141, 89],
    [255, 255, 191],
    [153, 213, 148],
  ].reverse(),
  rDivergingOrangeGreen2_3: [
    [252, 141, 89],
    [255, 255, 191],
    [145, 207, 96],
  ].reverse(),
  rQualitativeGreenOrange_3: [
    [127, 201, 127],
    [190, 174, 212],
    [253, 192, 134],
  ].reverse(),
  rQualitativeGreenPurple1_3: [
    [27, 158, 119],
    [217, 95, 2],
    [117, 112, 179],
  ].reverse(),
  rQualitativeBlueGreen_3: [
    [166, 206, 227],
    [31, 120, 180],
    [178, 223, 138],
  ].reverse(),
  rQualitativePinkGreen_3: [
    [251, 180, 174],
    [179, 205, 227],
    [204, 235, 197],
  ].reverse(),
  rQualitativeGreenPurple2_3: [
    [179, 226, 205],
    [253, 205, 172],
    [203, 213, 232],
  ].reverse(),
  rDiverginRedGreen_3: [
    [255, 0, 0],
    [255, 191, 0],
    [0, 255, 0],
  ].reverse(),
  rQualitativeRedGreen_3: [
    [228, 26, 28],
    [55, 126, 184],
    [77, 175, 74],
  ].reverse(),
  rQualitativeGreenPurple3_3: [
    [102, 194, 165],
    [252, 141, 98],
    [141, 160, 203],
  ].reverse(),
  rQualitativeGreenPurple4_3: [
    [141, 211, 199],
    [255, 255, 179],
    [190, 186, 218],
  ].reverse(),
  divergingBlueRed1_4: [
    [0, 147, 156],
    [162, 212, 215],
    [239, 190, 174],
    [194, 46, 0],
  ],
  sequentialBlue1_4: [
    [230, 250, 250],
    [170, 215, 218],
    [104, 180, 187],
    [0, 147, 156],
  ],
  qualitativeBlueOrange_4: [
    [18, 147, 154],
    [221, 178, 124],
    [136, 87, 44],
    [255, 153, 31],
  ],
  divergingBlueYellow_4: [
    [33, 62, 154],
    [155, 27, 186],
    [218, 16, 44],
    [249, 226, 0],
  ],
  divergingBlueRed2_4: [
    [1, 152, 189],
    [232, 254, 181],
    [254, 173, 84],
    [213, 2, 85],
  ],
  sequentialVioletYellow_4: [
    [90, 24, 70],
    [131, 26, 61],
    [227, 97, 28],
    [255, 195, 0],
  ],
  sequentialBluePink_4: [
    [53, 92, 125],
    [154, 98, 124],
    [200, 106, 126],
    [248, 177, 149],
  ],
  sequentialGreen_4: [
    [84, 122, 130],
    [62, 172, 168],
    [162, 212, 171],
    [229, 238, 193],
  ],
  sequentialPurple_4: [
    [80, 49, 94],
    [119, 73, 118],
    [178, 130, 148],
    [237, 209, 202],
  ],
  sequentialViolet_4: [
    [43, 30, 62],
    [70, 99, 115],
    [123, 168, 137],
    [214, 222, 191],
  ],
  sequentialGreen1_4: [
    [255, 255, 204],
    [194, 230, 153],
    [120, 198, 121],
    [35, 132, 67],
  ],
  sequentialBlue2_4: [
    [255, 255, 204],
    [161, 218, 180],
    [65, 182, 196],
    [34, 94, 168],
  ],
  sequentialBlue3_4: [
    [240, 249, 232],
    [186, 228, 188],
    [123, 204, 196],
    [43, 140, 190],
  ],
  singlehueGreen_4: [
    [237, 248, 233],
    [186, 228, 179],
    [116, 196, 118],
    [35, 139, 69],
  ],
  sequentialBlue4_4: [
    [246, 239, 247],
    [189, 201, 225],
    [103, 169, 207],
    [2, 129, 138],
  ],
  sequentialBlue5_4: [
    [241, 238, 246],
    [189, 201, 225],
    [116, 169, 207],
    [5, 112, 176],
  ],
  sequentialPurple1_4: [
    [237, 248, 251],
    [179, 205, 227],
    [140, 150, 198],
    [136, 65, 157],
  ],
  sequentialPink1_4: [
    [254, 235, 226],
    [251, 180, 185],
    [247, 104, 161],
    [174, 1, 126],
  ],
  sequentialPink2_4: [
    [241, 238, 246],
    [215, 181, 216],
    [223, 101, 176],
    [206, 18, 86],
  ],
  sequentialRed1_4: [
    [254, 240, 217],
    [253, 204, 138],
    [252, 141, 89],
    [215, 48, 31],
  ],
  sequentialRed2_4: [
    [255, 255, 178],
    [254, 204, 92],
    [253, 141, 60],
    [227, 26, 28],
  ],
  sequentialRed3_4: [
    [255, 255, 212],
    [254, 217, 142],
    [254, 153, 41],
    [204, 76, 2],
  ],
  singlehuePurple_4: [
    [242, 240, 247],
    [203, 201, 226],
    [158, 154, 200],
    [106, 81, 163],
  ],
  singlehueBlue_4: [
    [239, 243, 255],
    [189, 215, 231],
    [107, 174, 214],
    [33, 113, 181],
  ],
  sequentialGreen3_4: [
    [237, 248, 251],
    [178, 226, 226],
    [102, 194, 164],
    [35, 139, 69],
  ],
  singlehueOrange_4: [
    [254, 237, 222],
    [253, 190, 133],
    [253, 141, 60],
    [217, 71, 1],
  ],
  singlehueRed_4: [
    [254, 229, 217],
    [252, 174, 145],
    [251, 106, 74],
    [203, 24, 29],
  ],
  singlehueGray_4: [
    [247, 247, 247],
    [204, 204, 204],
    [150, 150, 150],
    [82, 82, 82],
  ],
  divergingOrangePurple_4: [
    [230, 97, 1],
    [253, 184, 99],
    [178, 171, 210],
    [94, 60, 153],
  ],
  divergingBrownGreen_4: [
    [166, 97, 26],
    [223, 194, 125],
    [128, 205, 193],
    [1, 133, 113],
  ],
  divergingPurpleGreen_4: [
    [123, 50, 148],
    [194, 165, 207],
    [166, 219, 160],
    [0, 136, 55],
  ],
  divergingPinkGreen_4: [
    [208, 28, 139],
    [241, 182, 218],
    [184, 225, 134],
    [77, 172, 38],
  ],
  divergingRedBlue1_4: [
    [202, 0, 32],
    [244, 165, 130],
    [146, 197, 222],
    [5, 113, 176],
  ],
  divergingRedGray_4: [
    [202, 0, 32],
    [244, 165, 130],
    [186, 186, 186],
    [64, 64, 64],
  ],
  divergingRedBlue2_4: [
    [215, 25, 28],
    [253, 174, 97],
    [171, 217, 233],
    [44, 123, 182],
  ],
  divergingRedBlue3_4: [
    [215, 25, 28],
    [253, 174, 97],
    [171, 221, 164],
    [43, 131, 186],
  ],
  divergingRedGreen_4: [
    [215, 25, 28],
    [253, 174, 97],
    [166, 217, 106],
    [26, 150, 65],
  ],
  qualitativeGreenYellow_4: [
    [127, 201, 127],
    [190, 174, 212],
    [253, 192, 134],
    [255, 255, 153],
  ],
  qualitativeGreenPink1_4: [
    [27, 158, 119],
    [217, 95, 2],
    [117, 112, 179],
    [231, 41, 138],
  ],
  qualitativeBlueGreen_4: [
    [166, 206, 227],
    [31, 120, 180],
    [178, 223, 138],
    [51, 160, 44],
  ],
  qualitativePinkBlueGreen1_4: [
    [251, 180, 174],
    [179, 205, 227],
    [204, 235, 197],
    [222, 203, 228],
  ],
  qualitativePinkBlueGreen2_4: [
    [179, 226, 205],
    [253, 205, 172],
    [203, 213, 232],
    [244, 202, 228],
  ],
  qualitativeRedPurple_4: [
    [228, 26, 28],
    [55, 126, 184],
    [77, 175, 74],
    [152, 78, 163],
  ],
  qualitativeGreenPink2_4: [
    [102, 194, 165],
    [252, 141, 98],
    [141, 160, 203],
    [231, 138, 195],
  ],
  qualitativeGreenOrange_4: [
    [141, 211, 199],
    [255, 255, 179],
    [190, 186, 218],
    [251, 128, 114],
  ],
  rDivergingBlueRed1_4: [
    [0, 147, 156],
    [162, 212, 215],
    [239, 190, 174],
    [194, 46, 0],
  ].reverse(),
  rGrowBlue1_4: [
    [230, 250, 250],
    [170, 215, 218],
    [104, 180, 187],
    [0, 147, 156],
  ].reverse(),
  rQualitativeBlueOrange_4: [
    [18, 147, 154],
    [221, 178, 124],
    [136, 87, 44],
    [255, 153, 31],
  ].reverse(),
  rDivergingBlueYellow_4: [
    [33, 62, 154],
    [155, 27, 186],
    [218, 16, 44],
    [249, 226, 0],
  ].reverse(),
  rDivergingBlueRed2_4: [
    [1, 152, 189],
    [232, 254, 181],
    [254, 173, 84],
    [213, 2, 85],
  ].reverse(),
  rSequentialVioletYellow_4: [
    [90, 24, 70],
    [131, 26, 61],
    [227, 97, 28],
    [255, 195, 0],
  ].reverse(),
  rSequentialBluePink_4: [
    [53, 92, 125],
    [154, 98, 124],
    [200, 106, 126],
    [248, 177, 149],
  ].reverse(),
  rSequentialGreen_4: [
    [84, 122, 130],
    [62, 172, 168],
    [162, 212, 171],
    [229, 238, 193],
  ].reverse(),
  rSequentialPurple_4: [
    [80, 49, 94],
    [119, 73, 118],
    [178, 130, 148],
    [237, 209, 202],
  ].reverse(),
  rSequentialViolet_4: [
    [43, 30, 62],
    [70, 99, 115],
    [123, 168, 137],
    [214, 222, 191],
  ].reverse(),
  rSequentialGreen1_4: [
    [255, 255, 204],
    [194, 230, 153],
    [120, 198, 121],
    [35, 132, 67],
  ].reverse(),
  rSequentialBlue2_4: [
    [255, 255, 204],
    [161, 218, 180],
    [65, 182, 196],
    [34, 94, 168],
  ].reverse(),
  rSequentialBlue3_4: [
    [240, 249, 232],
    [186, 228, 188],
    [123, 204, 196],
    [43, 140, 190],
  ].reverse(),
  rSinglehueGreen_4: [
    [237, 248, 233],
    [186, 228, 179],
    [116, 196, 118],
    [35, 139, 69],
  ].reverse(),
  rSequentialBlue4_4: [
    [246, 239, 247],
    [189, 201, 225],
    [103, 169, 207],
    [2, 129, 138],
  ].reverse(),
  rSequentialBlue5_4: [
    [241, 238, 246],
    [189, 201, 225],
    [116, 169, 207],
    [5, 112, 176],
  ].reverse(),
  rSequentialPurple1_4: [
    [237, 248, 251],
    [179, 205, 227],
    [140, 150, 198],
    [136, 65, 157],
  ].reverse(),
  rSequentialPink1_4: [
    [254, 235, 226],
    [251, 180, 185],
    [247, 104, 161],
    [174, 1, 126],
  ].reverse(),
  rSequentialPink2_4: [
    [241, 238, 246],
    [215, 181, 216],
    [223, 101, 176],
    [206, 18, 86],
  ].reverse(),
  rSequentialRed1_4: [
    [254, 240, 217],
    [253, 204, 138],
    [252, 141, 89],
    [215, 48, 31],
  ].reverse(),
  rSequentialRed2_4: [
    [255, 255, 178],
    [254, 204, 92],
    [253, 141, 60],
    [227, 26, 28],
  ].reverse(),
  rSequentialRed3_4: [
    [255, 255, 212],
    [254, 217, 142],
    [254, 153, 41],
    [204, 76, 2],
  ].reverse(),
  rSinglehuePurple_4: [
    [242, 240, 247],
    [203, 201, 226],
    [158, 154, 200],
    [106, 81, 163],
  ].reverse(),
  rSinglehueBlue_4: [
    [239, 243, 255],
    [189, 215, 231],
    [107, 174, 214],
    [33, 113, 181],
  ].reverse(),
  rSequentialGreen3_4: [
    [237, 248, 251],
    [178, 226, 226],
    [102, 194, 164],
    [35, 139, 69],
  ].reverse(),
  rSinglehueOrange_4: [
    [254, 237, 222],
    [253, 190, 133],
    [253, 141, 60],
    [217, 71, 1],
  ].reverse(),
  rSinglehueRed_4: [
    [254, 229, 217],
    [252, 174, 145],
    [251, 106, 74],
    [203, 24, 29],
  ].reverse(),
  rSinglehueGray_4: [
    [247, 247, 247],
    [204, 204, 204],
    [150, 150, 150],
    [82, 82, 82],
  ].reverse(),
  rDivergingOrangePurple_4: [
    [230, 97, 1],
    [253, 184, 99],
    [178, 171, 210],
    [94, 60, 153],
  ].reverse(),
  rDivergingBrownGreen_4: [
    [166, 97, 26],
    [223, 194, 125],
    [128, 205, 193],
    [1, 133, 113],
  ].reverse(),
  rDivergingPurpleGreen_4: [
    [123, 50, 148],
    [194, 165, 207],
    [166, 219, 160],
    [0, 136, 55],
  ].reverse(),
  rDivergingPinkGreen_4: [
    [208, 28, 139],
    [241, 182, 218],
    [184, 225, 134],
    [77, 172, 38],
  ].reverse(),
  rDivergingRedBlue1_4: [
    [202, 0, 32],
    [244, 165, 130],
    [146, 197, 222],
    [5, 113, 176],
  ].reverse(),
  rDivergingRedGray_4: [
    [202, 0, 32],
    [244, 165, 130],
    [186, 186, 186],
    [64, 64, 64],
  ].reverse(),
  rDivergingRedBlue2_4: [
    [215, 25, 28],
    [253, 174, 97],
    [171, 217, 233],
    [44, 123, 182],
  ].reverse(),
  rDivergingRedBlue3_4: [
    [215, 25, 28],
    [253, 174, 97],
    [171, 221, 164],
    [43, 131, 186],
  ].reverse(),
  rDivergingRedGreen_4: [
    [215, 25, 28],
    [253, 174, 97],
    [166, 217, 106],
    [26, 150, 65],
  ].reverse(),
  rQualitativeGreenYellow_4: [
    [127, 201, 127],
    [190, 174, 212],
    [253, 192, 134],
    [255, 255, 153],
  ].reverse(),
  rQualitativeGreenPink1_4: [
    [27, 158, 119],
    [217, 95, 2],
    [117, 112, 179],
    [231, 41, 138],
  ].reverse(),
  rQualitativeBlueGreen_4: [
    [166, 206, 227],
    [31, 120, 180],
    [178, 223, 138],
    [51, 160, 44],
  ].reverse(),
  rQualitativePinkBlueGreen1_4: [
    [251, 180, 174],
    [179, 205, 227],
    [204, 235, 197],
    [222, 203, 228],
  ].reverse(),
  rQualitativePinkBlueGreen2_4: [
    [179, 226, 205],
    [253, 205, 172],
    [203, 213, 232],
    [244, 202, 228],
  ].reverse(),
  rQualitativeRedPurple_4: [
    [228, 26, 28],
    [55, 126, 184],
    [77, 175, 74],
    [152, 78, 163],
  ].reverse(),
  rQualitativeGreenPink2_4: [
    [102, 194, 165],
    [252, 141, 98],
    [141, 160, 203],
    [231, 138, 195],
  ].reverse(),
  rQualitativeGreenOrange_4: [
    [141, 211, 199],
    [255, 255, 179],
    [190, 186, 218],
    [251, 128, 114],
  ].reverse(),
  divergingBlueRed_5: [
    [0, 147, 156],
    [133, 196, 200],
    [254, 238, 232],
    [236, 147, 112],
    [194, 46, 0],
  ],
  sequentialBlue1_5: [
    [230, 250, 250],
    [184, 224, 225],
    [137, 198, 202],
    [86, 172, 179],
    [0, 147, 156],
  ],
  qualitativeBlueOrange_5: [
    [18, 147, 154],
    [41, 50, 60],
    [136, 87, 44],
    [255, 153, 31],
    [241, 92, 23],
  ],
  divergingBlueYellow_5: [
    [33, 62, 154],
    [110, 29, 178],
    [202, 22, 142],
    [226, 38, 12],
    [249, 226, 0],
  ],
  divergingBluePink_5: [
    [1, 152, 189],
    [73, 227, 206],
    [250, 254, 179],
    [254, 173, 84],
    [213, 2, 85],
  ],
  sequentialVioletYellow_5: [
    [90, 24, 70],
    [131, 26, 61],
    [172, 28, 23],
    [213, 93, 14],
    [255, 195, 0],
  ],
  sequentialBluePink_5: [
    [53, 92, 125],
    [108, 91, 123],
    [192, 108, 132],
    [246, 114, 128],
    [248, 177, 149],
  ],
  sequentialBlue2_5: [
    [58, 116, 138],
    [84, 163, 143],
    [115, 188, 132],
    [169, 213, 151],
    [229, 238, 193],
  ],
  sequentialPurple_5: [
    [80, 49, 94],
    [100, 61, 104],
    [149, 100, 133],
    [193, 147, 157],
    [237, 209, 202],
  ],
  sequentialViolet_5: [
    [43, 30, 62],
    [58, 75, 102],
    [95, 142, 134],
    [139, 182, 141],
    [214, 222, 191],
  ],
  sequentialGreen1_5: [
    [255, 255, 204],
    [194, 230, 153],
    [120, 198, 121],
    [49, 163, 84],
    [0, 104, 55],
  ],
  sequentialBlue4_5: [
    [255, 255, 204],
    [161, 218, 180],
    [65, 182, 196],
    [44, 127, 184],
    [37, 52, 148],
  ],
  sequentialBlue3_5: [
    [240, 249, 232],
    [186, 228, 188],
    [123, 204, 196],
    [67, 162, 202],
    [8, 104, 172],
  ],
  singlehueGreen_5: [
    [237, 248, 233],
    [186, 228, 179],
    [116, 196, 118],
    [49, 163, 84],
    [0, 109, 44],
  ],
  sequentialGreen3_5: [
    [246, 239, 247],
    [189, 201, 225],
    [103, 169, 207],
    [28, 144, 153],
    [1, 108, 89],
  ],
  sequentialBlue5_5: [
    [241, 238, 246],
    [189, 201, 225],
    [116, 169, 207],
    [43, 140, 190],
    [4, 90, 141],
  ],
  sequentialPurple1_5: [
    [237, 248, 251],
    [179, 205, 227],
    [140, 150, 198],
    [136, 86, 167],
    [129, 15, 124],
  ],
  sequentialPink1_5: [
    [254, 235, 226],
    [251, 180, 185],
    [247, 104, 161],
    [197, 27, 138],
    [122, 1, 119],
  ],
  sequentialPink2_5: [
    [241, 238, 246],
    [215, 181, 216],
    [223, 101, 176],
    [221, 28, 119],
    [152, 0, 67],
  ],
  sequentialRed1_5: [
    [254, 240, 217],
    [253, 204, 138],
    [252, 141, 89],
    [227, 74, 51],
    [179, 0, 0],
  ],
  sequentialRed2_5: [
    [255, 255, 178],
    [254, 204, 92],
    [253, 141, 60],
    [240, 59, 32],
    [189, 0, 38],
  ],
  sequentialBrown_5: [
    [255, 255, 212],
    [254, 217, 142],
    [254, 153, 41],
    [217, 95, 14],
    [153, 52, 4],
  ],
  singlehuePurple_5: [
    [242, 240, 247],
    [203, 201, 226],
    [158, 154, 200],
    [117, 107, 177],
    [84, 39, 143],
  ],
  singlehueBlue_5: [
    [239, 243, 255],
    [189, 215, 231],
    [107, 174, 214],
    [49, 130, 189],
    [8, 81, 156],
  ],
  sequentialGreen4_5: [
    [237, 248, 251],
    [178, 226, 226],
    [102, 194, 164],
    [44, 162, 95],
    [0, 109, 44],
  ],
  singlehueBrown_5: [
    [254, 237, 222],
    [253, 190, 133],
    [253, 141, 60],
    [230, 85, 13],
    [166, 54, 3],
  ],
  singlehueRed_5: [
    [254, 229, 217],
    [252, 174, 145],
    [251, 106, 74],
    [222, 45, 38],
    [165, 15, 21],
  ],
  singlehueBlack_5: [
    [247, 247, 247],
    [204, 204, 204],
    [150, 150, 150],
    [99, 99, 99],
    [37, 37, 37],
  ],
  divergingOrangePurple_5: [
    [230, 97, 1],
    [253, 184, 99],
    [247, 247, 247],
    [178, 171, 210],
    [94, 60, 153],
  ],
  divergingBrownGreen_5: [
    [166, 97, 26],
    [223, 194, 125],
    [245, 245, 245],
    [128, 205, 193],
    [1, 133, 113],
  ],
  divergingPurpleGreen_5: [
    [123, 50, 148],
    [194, 165, 207],
    [247, 247, 247],
    [166, 219, 160],
    [0, 136, 55],
  ],
  divergingPinkGreen_5: [
    [208, 28, 139],
    [241, 182, 218],
    [247, 247, 247],
    [184, 225, 134],
    [77, 172, 38],
  ],
  divergingRedBlue1_5: [
    [202, 0, 32],
    [244, 165, 130],
    [247, 247, 247],
    [146, 197, 222],
    [5, 113, 176],
  ],
  divergingRedGray_5: [
    [202, 0, 32],
    [244, 165, 130],
    [255, 255, 255],
    [186, 186, 186],
    [64, 64, 64],
  ],
  divergingRedBlue2_5: [
    [215, 25, 28],
    [253, 174, 97],
    [255, 255, 191],
    [171, 217, 233],
    [44, 123, 182],
  ],
  divergingRedBlue3_5: [
    [215, 25, 28],
    [253, 174, 97],
    [255, 255, 191],
    [171, 221, 164],
    [43, 131, 186],
  ],
  divergingRedGreen_5: [
    [215, 25, 28],
    [253, 174, 97],
    [255, 255, 191],
    [166, 217, 106],
    [26, 150, 65],
  ],
  qualitativeGreenBlue_5: [
    [127, 201, 127],
    [190, 174, 212],
    [253, 192, 134],
    [255, 255, 153],
    [56, 108, 176],
  ],
  qualitativeBluishGreenGreen2_5: [
    [27, 158, 119],
    [217, 95, 2],
    [117, 112, 179],
    [231, 41, 138],
    [102, 166, 30],
  ],
  qualitativeBluePink_5: [
    [166, 206, 227],
    [31, 120, 180],
    [178, 223, 138],
    [51, 160, 44],
    [251, 154, 153],
  ],
  qualitativePinkCream_5: [
    [251, 180, 174],
    [179, 205, 227],
    [204, 235, 197],
    [222, 203, 228],
    [254, 217, 166],
  ],
  qualitativeGreenLightGreen_5: [
    [179, 226, 205],
    [253, 205, 172],
    [203, 201, 226],
    [244, 202, 228],
    [230, 245, 201],
  ],
  qualitativeRedOrange_5: [
    [228, 26, 28],
    [55, 126, 184],
    [77, 175, 74],
    [152, 78, 163],
    [255, 127, 0],
  ],
  qualitativeBluishGreenGreen1_5: [
    [102, 194, 165],
    [252, 141, 98],
    [141, 160, 203],
    [231, 138, 195],
    [166, 216, 84],
  ],
  qualitativeBluishGreenBlue_5: [
    [141, 211, 199],
    [255, 255, 179],
    [190, 186, 218],
    [251, 128, 114],
    [128, 177, 211],
  ],
  rDivergingBlueRed_5: [
    [0, 147, 156],
    [133, 196, 200],
    [254, 238, 232],
    [236, 147, 112],
    [194, 46, 0],
  ].reverse(),
  rSequentialBlue1_5: [
    [230, 250, 250],
    [184, 224, 225],
    [137, 198, 202],
    [86, 172, 179],
    [0, 147, 156],
  ].reverse(),
  rQualitativeBlueOrange_5: [
    [18, 147, 154],
    [41, 50, 60],
    [136, 87, 44],
    [255, 153, 31],
    [241, 92, 23],
  ].reverse(),
  rDivergingBlueYellow_5: [
    [33, 62, 154],
    [110, 29, 178],
    [202, 22, 142],
    [226, 38, 12],
    [249, 226, 0],
  ].reverse(),
  rDivergingBluePink_5: [
    [1, 152, 189],
    [73, 227, 206],
    [250, 254, 179],
    [254, 173, 84],
    [213, 2, 85],
  ].reverse(),
  rSequentialVioletYellow_5: [
    [90, 24, 70],
    [131, 26, 61],
    [172, 28, 23],
    [213, 93, 14],
    [255, 195, 0],
  ].reverse(),
  rSequentialBluePink_5: [
    [53, 92, 125],
    [108, 91, 123],
    [192, 108, 132],
    [246, 114, 128],
    [248, 177, 149],
  ].reverse(),
  rSequentialBlue2_5: [
    [58, 116, 138],
    [84, 163, 143],
    [115, 188, 132],
    [169, 213, 151],
    [229, 238, 193],
  ].reverse(),
  rSequentialPurple_5: [
    [80, 49, 94],
    [100, 61, 104],
    [149, 100, 133],
    [193, 147, 157],
    [237, 209, 202],
  ].reverse(),
  rSequentialViolet_5: [
    [43, 30, 62],
    [58, 75, 102],
    [95, 142, 134],
    [139, 182, 141],
    [214, 222, 191],
  ].reverse(),
  rSequentialGreen1_5: [
    [255, 255, 204],
    [194, 230, 153],
    [120, 198, 121],
    [49, 163, 84],
    [0, 104, 55],
  ].reverse(),
  rSequentialBlue4_5: [
    [255, 255, 204],
    [161, 218, 180],
    [65, 182, 196],
    [44, 127, 184],
    [37, 52, 148],
  ].reverse(),
  rSequentialBlue3_5: [
    [240, 249, 232],
    [186, 228, 188],
    [123, 204, 196],
    [67, 162, 202],
    [8, 104, 172],
  ].reverse(),
  rSinglehueGreen_5: [
    [237, 248, 233],
    [186, 228, 179],
    [116, 196, 118],
    [49, 163, 84],
    [0, 109, 44],
  ].reverse(),
  rSequentialGreen3_5: [
    [246, 239, 247],
    [189, 201, 225],
    [103, 169, 207],
    [28, 144, 153],
    [1, 108, 89],
  ].reverse(),
  rSequentialBlue5_5: [
    [241, 238, 246],
    [189, 201, 225],
    [116, 169, 207],
    [43, 140, 190],
    [4, 90, 141],
  ].reverse(),
  rSequentialPurple1_5: [
    [237, 248, 251],
    [179, 205, 227],
    [140, 150, 198],
    [136, 86, 167],
    [129, 15, 124],
  ].reverse(),
  rSequentialPink1_5: [
    [254, 235, 226],
    [251, 180, 185],
    [247, 104, 161],
    [197, 27, 138],
    [122, 1, 119],
  ].reverse(),
  rSequentialPink2_5: [
    [241, 238, 246],
    [215, 181, 216],
    [223, 101, 176],
    [221, 28, 119],
    [152, 0, 67],
  ].reverse(),
  rSequentialRed1_5: [
    [254, 240, 217],
    [253, 204, 138],
    [252, 141, 89],
    [227, 74, 51],
    [179, 0, 0],
  ].reverse(),
  rSequentialRed2_5: [
    [255, 255, 178],
    [254, 204, 92],
    [253, 141, 60],
    [240, 59, 32],
    [189, 0, 38],
  ].reverse(),
  rSequentialBrown_5: [
    [255, 255, 212],
    [254, 217, 142],
    [254, 153, 41],
    [217, 95, 14],
    [153, 52, 4],
  ].reverse(),
  rSinglehuePurple_5: [
    [242, 240, 247],
    [203, 201, 226],
    [158, 154, 200],
    [117, 107, 177],
    [84, 39, 143],
  ].reverse(),
  rSinglehueBlue_5: [
    [239, 243, 255],
    [189, 215, 231],
    [107, 174, 214],
    [49, 130, 189],
    [8, 81, 156],
  ].reverse(),
  rSequentialGreen4_5: [
    [237, 248, 251],
    [178, 226, 226],
    [102, 194, 164],
    [44, 162, 95],
    [0, 109, 44],
  ].reverse(),
  rSinglehueBrown_5: [
    [254, 237, 222],
    [253, 190, 133],
    [253, 141, 60],
    [230, 85, 13],
    [166, 54, 3],
  ].reverse(),
  rSinglehueRed_5: [
    [254, 229, 217],
    [252, 174, 145],
    [251, 106, 74],
    [222, 45, 38],
    [165, 15, 21],
  ].reverse(),
  rSinglehueBlack_5: [
    [247, 247, 247],
    [204, 204, 204],
    [150, 150, 150],
    [99, 99, 99],
    [37, 37, 37],
  ].reverse(),
  rDivergingOrangePurple_5: [
    [230, 97, 1],
    [253, 184, 99],
    [247, 247, 247],
    [178, 171, 210],
    [94, 60, 153],
  ].reverse(),
  rDivergingBrownGreen_5: [
    [166, 97, 26],
    [223, 194, 125],
    [245, 245, 245],
    [128, 205, 193],
    [1, 133, 113],
  ].reverse(),
  rDivergingPurpleGreen_5: [
    [123, 50, 148],
    [194, 165, 207],
    [247, 247, 247],
    [166, 219, 160],
    [0, 136, 55],
  ].reverse(),
  rDivergingPinkGreen_5: [
    [208, 28, 139],
    [241, 182, 218],
    [247, 247, 247],
    [184, 225, 134],
    [77, 172, 38],
  ].reverse(),
  rDivergingRedBlue1_5: [
    [202, 0, 32],
    [244, 165, 130],
    [247, 247, 247],
    [146, 197, 222],
    [5, 113, 176],
  ].reverse(),
  rDivergingRedGray_5: [
    [202, 0, 32],
    [244, 165, 130],
    [255, 255, 255],
    [186, 186, 186],
    [64, 64, 64],
  ].reverse(),
  rDivergingRedBlue2_5: [
    [215, 25, 28],
    [253, 174, 97],
    [255, 255, 191],
    [171, 217, 233],
    [44, 123, 182],
  ].reverse(),
  rDivergingRedBlue3_5: [
    [215, 25, 28],
    [253, 174, 97],
    [255, 255, 191],
    [171, 221, 164],
    [43, 131, 186],
  ].reverse(),
  rDivergingRedGreen_5: [
    [215, 25, 28],
    [253, 174, 97],
    [255, 255, 191],
    [166, 217, 106],
    [26, 150, 65],
  ].reverse(),
  rQualitativeGreenBlue_5: [
    [127, 201, 127],
    [190, 174, 212],
    [253, 192, 134],
    [255, 255, 153],
    [56, 108, 176],
  ].reverse(),
  rQualitativeBluishGreenGreen2_5: [
    [27, 158, 119],
    [217, 95, 2],
    [117, 112, 179],
    [231, 41, 138],
    [102, 166, 30],
  ].reverse(),
  rQualitativeBluePink_5: [
    [166, 206, 227],
    [31, 120, 180],
    [178, 223, 138],
    [51, 160, 44],
    [251, 154, 153],
  ].reverse(),
  rQualitativePinkCream_5: [
    [251, 180, 174],
    [179, 205, 227],
    [204, 235, 197],
    [222, 203, 228],
    [254, 217, 166],
  ].reverse(),
  rQualitativeGreenLightGreen_5: [
    [179, 226, 205],
    [253, 205, 172],
    [203, 201, 226],
    [244, 202, 228],
    [230, 245, 201],
  ].reverse(),
  rQualitativeRedOrange_5: [
    [228, 26, 28],
    [55, 126, 184],
    [77, 175, 74],
    [152, 78, 163],
    [255, 127, 0],
  ].reverse(),
  rQualitativeBluishGreenGreen1_5: [
    [102, 194, 165],
    [252, 141, 98],
    [141, 160, 203],
    [231, 138, 195],
    [166, 216, 84],
  ].reverse(),
  rQualitativeBluishGreenBlue_5: [
    [141, 211, 199],
    [255, 255, 179],
    [190, 186, 218],
    [251, 128, 114],
    [128, 177, 211],
  ].reverse(),
  divergingBlueRed1_6: [
    [0, 147, 156],
    [93, 186, 191],
    [186, 225, 226],
    [248, 192, 170],
    [221, 119, 85],
    [194, 46, 0],
  ],
  sequentialBlue1_6: [
    [230, 250, 250],
    [193, 229, 230],
    [157, 208, 212],
    [117, 187, 193],
    [75, 167, 175],
    [0, 147, 156],
  ],
  qualitativeBlueDarkBlue_6: [
    [18, 147, 154],
    [221, 178, 124],
    [136, 87, 44],
    [255, 153, 31],
    [241, 92, 23],
    [34, 63, 154],
  ],
  divergingBlueYellow_6: [
    [33, 62, 154],
    [85, 30, 173],
    [192, 25, 189],
    [211, 18, 86],
    [230, 71, 10],
    [249, 226, 0],
  ],
  divergingBlueRed2_6: [
    [1, 152, 189],
    [73, 227, 206],
    [232, 254, 181],
    [254, 237, 177],
    [254, 173, 84],
    [213, 2, 85],
  ],
  sequentialVioletYellow_6: [
    [90, 24, 70],
    [144, 12, 63],
    [199, 0, 57],
    [227, 97, 28],
    [241, 146, 14],
    [255, 195, 0],
  ],
  sequentialBluePink_6: [
    [53, 92, 125],
    [99, 97, 127],
    [145, 102, 129],
    [216, 129, 133],
    [232, 153, 141],
    [248, 177, 149],
  ],
  sequentialBlue2_6: [
    [55, 83, 94],
    [58, 116, 138],
    [64, 149, 181],
    [82, 174, 201],
    [114, 191, 196],
    [147, 207, 191],
  ],
  sequentialPurple1_6: [
    [44, 30, 61],
    [87, 54, 96],
    [131, 83, 124],
    [166, 117, 142],
    [201, 157, 164],
    [237, 209, 202],
  ],
  sequentialPurple2_6: [
    [43, 30, 62],
    [52, 61, 94],
    [79, 119, 126],
    [112, 158, 135],
    [153, 190, 149],
    [214, 222, 191],
  ],
  green: [
    [255, 255, 204],
    [217, 240, 163],
    [173, 221, 142],
    [120, 198, 121],
    [49, 163, 84],
    [0, 104, 55],
  ],
  blue: [
    [255, 255, 204],
    [199, 233, 180],
    [127, 205, 187],
    [65, 182, 196],
    [44, 127, 184],
    [37, 52, 148],
  ],
  sequentialBlue4_6: [
    [240, 249, 232],
    [204, 235, 197],
    [168, 221, 181],
    [123, 204, 196],
    [67, 162, 202],
    [8, 104, 172],
  ],
  singlehueGreen_6: [
    [237, 248, 233],
    [199, 233, 192],
    [161, 217, 155],
    [116, 196, 118],
    [49, 163, 84],
    [0, 109, 44],
  ],
  sequentialBluishGreen_6: [
    [246, 239, 247],
    [208, 209, 230],
    [166, 189, 219],
    [103, 169, 207],
    [28, 144, 153],
    [1, 108, 89],
  ],
  sequentialBlue5_6: [
    [255, 255, 255],
    [208, 209, 230],
    [166, 189, 219],
    [116, 169, 207],
    [43, 140, 190],
    [4, 90, 141],
  ],
  sequentialPurple3_6: [
    [237, 248, 251],
    [191, 211, 230],
    [158, 188, 218],
    [140, 150, 198],
    [136, 86, 167],
    [129, 15, 124],
  ],
  pink: [
    [254, 235, 226],
    [252, 197, 192],
    [250, 159, 181],
    [247, 104, 161],
    [197, 27, 138],
    [122, 1, 119],
  ],
  sequentialPink_6: [
    [241, 238, 246],
    [212, 185, 218],
    [201, 148, 199],
    [223, 101, 176],
    [221, 28, 119],
    [152, 0, 67],
  ],
  sequentialRed1_6: [
    [255, 255, 178],
    [253, 212, 158],
    [253, 187, 132],
    [252, 141, 89],
    [227, 74, 51],
    [179, 0, 0],
  ],
  default: [
    [255, 255, 178],
    [254, 217, 118],
    [254, 178, 76],
    [253, 141, 60],
    [240, 59, 32],
    [189, 0, 38],
  ],
  red: [
    [255, 255, 212],
    [254, 227, 145],
    [254, 196, 79],
    [254, 153, 41],
    [217, 95, 14],
    [153, 52, 4],
  ],
  singlehuePurple_6: [
    [242, 240, 247],
    [218, 218, 235],
    [188, 189, 220],
    [158, 154, 200],
    [117, 107, 177],
    [84, 39, 143],
  ],
  singlehueBlue_6: [
    [242, 240, 247],
    [198, 219, 239],
    [158, 202, 225],
    [107, 174, 214],
    [49, 130, 189],
    [8, 81, 156],
  ],
  sequentialGreen3_6: [
    [237, 248, 251],
    [204, 236, 230],
    [153, 216, 201],
    [102, 194, 164],
    [44, 162, 95],
    [0, 109, 44],
  ],
  singlehueBrown_6: [
    [242, 240, 247],
    [253, 208, 162],
    [253, 174, 107],
    [253, 141, 60],
    [230, 85, 13],
    [166, 54, 3],
  ],
  singlehueRed_6: [
    [254, 229, 217],
    [252, 187, 161],
    [252, 146, 114],
    [251, 106, 74],
    [222, 45, 38],
    [165, 15, 21],
  ],
  singlehueBlack_6: [
    [247, 247, 247],
    [217, 217, 217],
    [189, 189, 189],
    [150, 150, 150],
    [99, 99, 99],
    [37, 37, 37],
  ],
  divergingBrownPurple_6: [
    [179, 88, 6],
    [241, 163, 64],
    [254, 224, 182],
    [216, 218, 235],
    [153, 142, 195],
    [84, 39, 136],
  ],
  diverging_1: [
    [140, 81, 10],
    [216, 179, 101],
    [246, 232, 195],
    [199, 234, 229],
    [90, 180, 172],
    [1, 102, 94],
  ],
  divergingPurpleGreen_6: [
    [118, 42, 131],
    [175, 141, 195],
    [231, 212, 232],
    [217, 240, 211],
    [127, 191, 123],
    [27, 120, 55],
  ],
  divergingPinkGreen_6: [
    [197, 27, 125],
    [233, 163, 201],
    [253, 224, 239],
    [230, 245, 208],
    [161, 215, 106],
    [77, 146, 33],
  ],
  divergingRedBlue1_6: [
    [178, 24, 43],
    [239, 138, 98],
    [253, 219, 199],
    [209, 229, 240],
    [103, 169, 207],
    [33, 102, 172],
  ],
  divergingRedGray_6: [
    [178, 24, 43],
    [239, 138, 98],
    [253, 219, 199],
    [224, 224, 224],
    [153, 153, 153],
    [77, 77, 77],
  ],
  divergingRedBlue2_6: [
    [215, 48, 39],
    [252, 141, 89],
    [254, 224, 144],
    [224, 243, 248],
    [145, 191, 219],
    [69, 117, 180],
  ],
  diverging_2: [
    [213, 62, 79],
    [252, 141, 89],
    [254, 224, 139],
    [230, 245, 152],
    [153, 213, 148],
    [50, 136, 189],
  ],
  divergingRedGreen_6: [
    [215, 48, 39],
    [252, 141, 89],
    [254, 224, 139],
    [217, 239, 139],
    [145, 207, 96],
    [26, 152, 80],
  ],
  quantitative: [
    [127, 201, 127],
    [190, 174, 212],
    [253, 192, 134],
    [255, 255, 153],
    [56, 108, 176],
    [240, 2, 127],
  ],
  qualitativeGreenYellow_6: [
    [27, 158, 119],
    [217, 95, 2],
    [117, 112, 179],
    [231, 41, 138],
    [102, 166, 30],
    [230, 171, 2],
  ],
  qualitativeBlueRed_6: [
    [166, 206, 227],
    [31, 120, 180],
    [178, 223, 138],
    [51, 160, 44],
    [251, 154, 153],
    [227, 26, 28],
  ],
  qualitativePinkYellow_6: [
    [251, 180, 174],
    [179, 205, 227],
    [204, 235, 197],
    [222, 203, 228],
    [254, 217, 166],
    [255, 255, 204],
  ],
  qualitativeBluishGreenYellow1_6: [
    [179, 226, 205],
    [253, 205, 172],
    [203, 213, 232],
    [244, 202, 228],
    [230, 245, 201],
    [255, 242, 174],
  ],
  qualitativeRedYellow_6: [
    [228, 26, 28],
    [55, 126, 184],
    [77, 175, 74],
    [152, 78, 163],
    [255, 127, 0],
    [255, 255, 51],
  ],
  qualitativeBluishGreenYellow2_6: [
    [102, 194, 165],
    [252, 141, 98],
    [141, 160, 203],
    [231, 138, 195],
    [166, 216, 84],
    [255, 217, 47],
  ],
  qualitative_2: [
    [141, 211, 199],
    [255, 255, 179],
    [190, 186, 218],
    [251, 128, 114],
    [128, 177, 211],
    [253, 180, 98],
  ],
  whiteBlack: [
    [243, 243, 243],
    [215, 215, 215],
    [194, 194, 194],
    [162, 162, 162],
    [99, 99, 99],
    [37, 37, 37],
  ],
  rDivergingBlueRed1_6: [
    [0, 147, 156],
    [93, 186, 191],
    [186, 225, 226],
    [248, 192, 170],
    [221, 119, 85],
    [194, 46, 0],
  ].reverse(),
  rSequentialBlue1_6: [
    [230, 250, 250],
    [193, 229, 230],
    [157, 208, 212],
    [117, 187, 193],
    [75, 167, 175],
    [0, 147, 156],
  ].reverse(),
  rQualitativeBlueDarkBlue_6: [
    [18, 147, 154],
    [221, 178, 124],
    [136, 87, 44],
    [255, 153, 31],
    [241, 92, 23],
    [34, 63, 154],
  ].reverse(),
  rDivergingBlueYellow_6: [
    [33, 62, 154],
    [85, 30, 173],
    [192, 25, 189],
    [211, 18, 86],
    [230, 71, 10],
    [249, 226, 0],
  ].reverse(),
  rDivergingBlueRed2_6: [
    [1, 152, 189],
    [73, 227, 206],
    [232, 254, 181],
    [254, 237, 177],
    [254, 173, 84],
    [213, 2, 85],
  ].reverse(),
  rSequentialVioletYellow_6: [
    [90, 24, 70],
    [144, 12, 63],
    [199, 0, 57],
    [227, 97, 28],
    [241, 146, 14],
    [255, 195, 0],
  ].reverse(),
  rSequentialBluePink_6: [
    [53, 92, 125],
    [99, 97, 127],
    [145, 102, 129],
    [216, 129, 133],
    [232, 153, 141],
    [248, 177, 149],
  ].reverse(),
  rSequentialBlue2_6: [
    [55, 83, 94],
    [58, 116, 138],
    [64, 149, 181],
    [82, 174, 201],
    [114, 191, 196],
    [147, 207, 191],
  ].reverse(),
  rSequentialPurple1_6: [
    [44, 30, 61],
    [87, 54, 96],
    [131, 83, 124],
    [166, 117, 142],
    [201, 157, 164],
    [237, 209, 202],
  ].reverse(),
  rSequentialPurple2_6: [
    [43, 30, 62],
    [52, 61, 94],
    [79, 119, 126],
    [112, 158, 135],
    [153, 190, 149],
    [214, 222, 191],
  ].reverse(),
  rSequentialGreen1_6: [
    [255, 255, 204],
    [217, 240, 163],
    [173, 221, 142],
    [120, 198, 121],
    [49, 163, 84],
    [0, 104, 55],
  ].reverse(),
  rSequentialBlue3_6: [
    [255, 255, 204],
    [199, 233, 180],
    [127, 205, 187],
    [65, 182, 196],
    [44, 127, 184],
    [37, 52, 148],
  ].reverse(),
  rSequentialBlue4_6: [
    [240, 249, 232],
    [204, 235, 197],
    [168, 221, 181],
    [123, 204, 196],
    [67, 162, 202],
    [8, 104, 172],
  ].reverse(),
  rSinglehueGreen_6: [
    [237, 248, 233],
    [199, 233, 192],
    [161, 217, 155],
    [116, 196, 118],
    [49, 163, 84],
    [0, 109, 44],
  ].reverse(),
  rSequentialBluishGreen_6: [
    [246, 239, 247],
    [208, 209, 230],
    [166, 189, 219],
    [103, 169, 207],
    [28, 144, 153],
    [1, 108, 89],
  ].reverse(),
  rSequentialBlue5_6: [
    [255, 255, 255],
    [208, 209, 230],
    [166, 189, 219],
    [116, 169, 207],
    [43, 140, 190],
    [4, 90, 141],
  ].reverse(),
  rSequentialPurple3_6: [
    [237, 248, 251],
    [191, 211, 230],
    [158, 188, 218],
    [140, 150, 198],
    [136, 86, 167],
    [129, 15, 124],
  ].reverse(),
  rSequentialPurple4_6: [
    [254, 235, 226],
    [252, 197, 192],
    [250, 159, 181],
    [247, 104, 161],
    [197, 27, 138],
    [122, 1, 119],
  ].reverse(),
  rSequentialPink_6: [
    [241, 238, 246],
    [212, 185, 218],
    [201, 148, 199],
    [223, 101, 176],
    [221, 28, 119],
    [152, 0, 67],
  ].reverse(),
  rSequentialRed1_6: [
    [255, 255, 178],
    [253, 212, 158],
    [253, 187, 132],
    [252, 141, 89],
    [227, 74, 51],
    [179, 0, 0],
  ].reverse(),
  rSequentialRed2_6: [
    [255, 255, 178],
    [254, 217, 118],
    [254, 178, 76],
    [253, 141, 60],
    [240, 59, 32],
    [189, 0, 38],
  ].reverse(),
  rSequentialBrown_6: [
    [255, 255, 212],
    [254, 227, 145],
    [254, 196, 79],
    [254, 153, 41],
    [217, 95, 14],
    [153, 52, 4],
  ].reverse(),
  rSinglehuePurple_6: [
    [242, 240, 247],
    [218, 218, 235],
    [188, 189, 220],
    [158, 154, 200],
    [117, 107, 177],
    [84, 39, 143],
  ].reverse(),
  rSinglehueBlue_6: [
    [242, 240, 247],
    [198, 219, 239],
    [158, 202, 225],
    [107, 174, 214],
    [49, 130, 189],
    [8, 81, 156],
  ].reverse(),
  rSequentialGreen3_6: [
    [237, 248, 251],
    [204, 236, 230],
    [153, 216, 201],
    [102, 194, 164],
    [44, 162, 95],
    [0, 109, 44],
  ].reverse(),
  rSinglehueBrown_6: [
    [242, 240, 247],
    [253, 208, 162],
    [253, 174, 107],
    [253, 141, 60],
    [230, 85, 13],
    [166, 54, 3],
  ].reverse(),
  rSinglehueRed_6: [
    [254, 229, 217],
    [252, 187, 161],
    [252, 146, 114],
    [251, 106, 74],
    [222, 45, 38],
    [165, 15, 21],
  ].reverse(),
  rSinglehueBlack_6: [
    [247, 247, 247],
    [217, 217, 217],
    [189, 189, 189],
    [150, 150, 150],
    [99, 99, 99],
    [37, 37, 37],
  ].reverse(),
  rDivergingBrownPurple_6: [
    [179, 88, 6],
    [241, 163, 64],
    [254, 224, 182],
    [216, 218, 235],
    [153, 142, 195],
    [84, 39, 136],
  ].reverse(),
  rDivergingBrownGreen_6: [
    [140, 81, 10],
    [216, 179, 101],
    [246, 232, 195],
    [199, 234, 229],
    [90, 180, 172],
    [1, 102, 94],
  ].reverse(),
  rDivergingPurpleGreen_6: [
    [118, 42, 131],
    [175, 141, 195],
    [231, 212, 232],
    [217, 240, 211],
    [127, 191, 123],
    [27, 120, 55],
  ].reverse(),
  rDivergingPinkGreen_6: [
    [197, 27, 125],
    [233, 163, 201],
    [253, 224, 239],
    [230, 245, 208],
    [161, 215, 106],
    [77, 146, 33],
  ].reverse(),
  rDivergingRedBlue1_6: [
    [178, 24, 43],
    [239, 138, 98],
    [253, 219, 199],
    [209, 229, 240],
    [103, 169, 207],
    [33, 102, 172],
  ].reverse(),
  rDivergingRedGray_6: [
    [178, 24, 43],
    [239, 138, 98],
    [253, 219, 199],
    [224, 224, 224],
    [153, 153, 153],
    [77, 77, 77],
  ].reverse(),
  rDivergingRedBlue2_6: [
    [215, 48, 39],
    [252, 141, 89],
    [254, 224, 144],
    [224, 243, 248],
    [145, 191, 219],
    [69, 117, 180],
  ].reverse(),
  rDivergingPinkBlue_6: [
    [213, 62, 79],
    [252, 141, 89],
    [254, 224, 139],
    [230, 245, 152],
    [153, 213, 148],
    [50, 136, 189],
  ].reverse(),
  rDivergingRedGreen_6: [
    [215, 48, 39],
    [252, 141, 89],
    [254, 224, 139],
    [217, 239, 139],
    [145, 207, 96],
    [26, 152, 80],
  ].reverse(),
  rQualitativeGreenPink_6: [
    [127, 201, 127],
    [190, 174, 212],
    [253, 192, 134],
    [255, 255, 153],
    [56, 108, 176],
    [240, 2, 127],
  ].reverse(),
  rQualitativeGreenYellow_6: [
    [27, 158, 119],
    [217, 95, 2],
    [117, 112, 179],
    [231, 41, 138],
    [102, 166, 30],
    [230, 171, 2],
  ].reverse(),
  rQualitativeBlueRed_6: [
    [166, 206, 227],
    [31, 120, 180],
    [178, 223, 138],
    [51, 160, 44],
    [251, 154, 153],
    [227, 26, 28],
  ].reverse(),
  rQualitativePinkYellow_6: [
    [251, 180, 174],
    [179, 205, 227],
    [204, 235, 197],
    [222, 203, 228],
    [254, 217, 166],
    [255, 255, 204],
  ].reverse(),
  rQualitativeBluishGreenYellow1_6: [
    [179, 226, 205],
    [253, 205, 172],
    [203, 213, 232],
    [244, 202, 228],
    [230, 245, 201],
    [255, 242, 174],
  ].reverse(),
  rQualitativeRedYellow_6: [
    [228, 26, 28],
    [55, 126, 184],
    [77, 175, 74],
    [152, 78, 163],
    [255, 127, 0],
    [255, 255, 51],
  ].reverse(),
  rQualitativeBluishGreenYellow2_6: [
    [102, 194, 165],
    [252, 141, 98],
    [141, 160, 203],
    [231, 138, 195],
    [166, 216, 84],
    [255, 217, 47],
  ].reverse(),
  rQualitativeBluishGreenOrange_6: [
    [141, 211, 199],
    [255, 255, 179],
    [190, 186, 218],
    [251, 128, 114],
    [128, 177, 211],
    [253, 180, 98],
  ].reverse(),
  rSequentialBlack_6: [
    [243, 243, 243],
    [215, 215, 215],
    [194, 194, 194],
    [162, 162, 162],
    [99, 99, 99],
    [37, 37, 37],
  ].reverse(),
  divergingBlueRed_7: [
    [0, 147, 156],
    [101, 179, 186],
    [162, 212, 215],
    [254, 238, 232],
    [245, 176, 151],
    [225, 116, 73],
    [194, 46, 0],
  ],
  sequentialBlue1_7: [
    [230, 250, 250],
    [193, 229, 230],
    [157, 208, 212],
    [117, 187, 193],
    [75, 167, 175],
    [0, 147, 156],
    [16, 129, 136],
  ],
  qualitativeBluePink_7: [
    [18, 147, 154],
    [221, 178, 124],
    [136, 87, 44],
    [255, 153, 31],
    [241, 92, 23],
    [34, 63, 154],
    [218, 112, 191],
  ],
  divergingBlueYellow_7: [
    [33, 62, 154],
    [70, 31, 169],
    [155, 27, 186],
    [202, 22, 142],
    [218, 16, 44],
    [233, 94, 8],
    [249, 226, 0],
  ],
  divergingBluePink_7: [
    [1, 152, 189],
    [84, 186, 185],
    [167, 220, 182],
    [250, 254, 179],
    [252, 213, 131],
    [254, 173, 84],
    [213, 2, 85],
  ],
  sequentialVioletYellow_7: [
    [90, 24, 70],
    [117, 26, 67],
    [145, 25, 50],
    [172, 28, 23],
    [200, 68, 17],
    [227, 123, 10],
    [255, 195, 0],
  ],
  sequentialBluePink_7: [
    [53, 92, 125],
    [99, 97, 127],
    [145, 102, 129],
    [192, 108, 132],
    [210, 131, 137],
    [229, 154, 143],
    [248, 177, 149],
  ],
  blueLightGreen_7: [
    [58, 116, 138],
    [75, 154, 149],
    [94, 171, 139],
    [115, 188, 132],
    [146, 204, 139],
    [190, 221, 165],
    [229, 238, 193],
  ],
  sequentialViolet_7: [
    [44, 30, 61],
    [79, 49, 90],
    [119, 73, 118],
    [149, 100, 133],
    [178, 130, 148],
    [207, 164, 168],
    [237, 209, 202],
  ],
  violetLightGreen_7: [
    [43, 30, 62],
    [48, 53, 88],
    [70, 99, 115],
    [95, 142, 134],
    [123, 168, 137],
    [164, 195, 155],
    [214, 222, 191],
  ],
  singlehueGreen_7: [
    [255, 255, 204],
    [217, 240, 163],
    [173, 221, 142],
    [120, 198, 121],
    [65, 171, 93],
    [35, 132, 67],
    [0, 90, 50],
  ],
  sequentialBlue2_7: [
    [255, 255, 204],
    [199, 233, 180],
    [127, 205, 187],
    [65, 182, 196],
    [29, 145, 192],
    [34, 94, 168],
    [12, 44, 132],
  ],
  sequentialBlue3_7: [
    [240, 249, 232],
    [204, 235, 197],
    [168, 221, 181],
    [123, 204, 196],
    [78, 179, 211],
    [43, 140, 190],
    [8, 88, 158],
  ],
  sequentialGreen2_7: [
    [237, 248, 251],
    [204, 236, 230],
    [153, 216, 201],
    [102, 194, 164],
    [65, 174, 118],
    [35, 139, 69],
    [0, 88, 36],
  ],
  sequentialBlue4_7: [
    [246, 239, 247],
    [208, 209, 230],
    [166, 189, 219],
    [103, 169, 207],
    [54, 144, 192],
    [2, 129, 138],
    [1, 100, 80],
  ],
  sequentialBlue5_7: [
    [241, 238, 246],
    [208, 209, 230],
    [166, 189, 219],
    [116, 169, 207],
    [54, 144, 192],
    [5, 112, 176],
    [3, 78, 123],
  ],
  sequentialPurple_7: [
    [237, 248, 251],
    [191, 211, 230],
    [158, 188, 218],
    [140, 150, 198],
    [140, 107, 177],
    [136, 65, 157],
    [110, 1, 107],
  ],
  sequentialPink_7: [
    [254, 235, 226],
    [252, 197, 192],
    [250, 159, 181],
    [247, 104, 161],
    [221, 52, 151],
    [174, 1, 126],
    [122, 1, 119],
  ],
  sequentialDarkPink_7: [
    [241, 238, 246],
    [212, 185, 218],
    [201, 148, 199],
    [223, 101, 176],
    [231, 41, 138],
    [206, 18, 86],
    [145, 0, 63],
  ],
  sequentialRed1_7: [
    [254, 240, 217],
    [253, 212, 158],
    [253, 187, 132],
    [252, 141, 89],
    [239, 101, 72],
    [215, 48, 31],
    [153, 0, 0],
  ],
  sequentialRed2_7: [
    [255, 255, 178],
    [254, 217, 118],
    [254, 178, 76],
    [253, 141, 60],
    [252, 78, 42],
    [227, 26, 28],
    [177, 0, 38],
  ],
  singlehueBrown_7: [
    [255, 255, 212],
    [254, 227, 145],
    [254, 196, 79],
    [254, 153, 41],
    [236, 112, 20],
    [204, 76, 2],
    [140, 45, 4],
  ],
  singlehuePurple_7: [
    [242, 240, 247],
    [218, 218, 235],
    [188, 189, 220],
    [158, 154, 200],
    [128, 125, 186],
    [106, 81, 163],
    [74, 20, 134],
  ],
  singlehueBlue_7: [
    [239, 243, 255],
    [198, 219, 239],
    [158, 202, 225],
    [107, 174, 214],
    [66, 146, 198],
    [33, 113, 181],
    [8, 69, 148],
  ],
  sequentialGreen3_7: [
    [237, 248, 233],
    [199, 233, 192],
    [161, 217, 155],
    [116, 196, 118],
    [65, 171, 93],
    [35, 139, 69],
    [0, 90, 50],
  ],
  sequentialBrown_7: [
    [254, 237, 222],
    [253, 208, 162],
    [253, 174, 107],
    [253, 141, 60],
    [241, 105, 19],
    [217, 72, 1],
    [140, 45, 4],
  ],
  singlehueRed_7: [
    [254, 229, 217],
    [252, 187, 161],
    [252, 146, 114],
    [251, 106, 74],
    [239, 59, 44],
    [203, 24, 29],
    [153, 0, 13],
  ],
  singlehueBlack_7: [
    [247, 247, 247],
    [217, 217, 217],
    [189, 189, 189],
    [150, 150, 150],
    [115, 115, 115],
    [82, 82, 82],
    [37, 37, 37],
  ],
  divergingBrownPurple_7: [
    [179, 88, 6],
    [241, 163, 64],
    [254, 224, 182],
    [247, 247, 247],
    [216, 218, 235],
    [153, 142, 195],
    [84, 39, 136],
  ],
  divergingBrownGreen_7: [
    [140, 81, 10],
    [216, 179, 101],
    [246, 232, 195],
    [245, 245, 245],
    [199, 234, 229],
    [90, 180, 172],
    [1, 102, 94],
  ],
  divergingPurpleGreen_7: [
    [118, 42, 131],
    [175, 141, 195],
    [231, 212, 232],
    [247, 247, 247],
    [217, 240, 211],
    [127, 191, 123],
    [27, 120, 55],
  ],
  divergingPinkGreen_7: [
    [197, 27, 125],
    [233, 163, 201],
    [253, 224, 239],
    [247, 247, 247],
    [230, 245, 208],
    [161, 215, 106],
    [77, 146, 33],
  ],
  divergingRedBlue1_7: [
    [178, 24, 43],
    [239, 138, 98],
    [253, 219, 199],
    [247, 247, 247],
    [209, 229, 240],
    [103, 169, 207],
    [33, 102, 172],
  ],
  divergingRedGray_7: [
    [178, 24, 43],
    [239, 138, 98],
    [253, 219, 199],
    [255, 255, 255],
    [224, 224, 224],
    [153, 153, 153],
    [77, 77, 77],
  ],
  divergingRedBlue2_7: [
    [215, 48, 39],
    [252, 141, 89],
    [254, 224, 144],
    [255, 255, 191],
    [224, 243, 248],
    [145, 191, 219],
    [69, 117, 180],
  ],
  divergingOrangeBlue_7: [
    [213, 62, 79],
    [252, 141, 89],
    [254, 224, 139],
    [255, 255, 191],
    [230, 245, 152],
    [153, 213, 148],
    [50, 136, 189],
  ],
  divergingRedGreen_7: [
    [215, 48, 39],
    [252, 141, 89],
    [254, 224, 139],
    [255, 255, 191],
    [217, 239, 139],
    [145, 207, 96],
    [26, 152, 80],
  ],
  qualitativeLightGreenBrown_7: [
    [127, 201, 127],
    [190, 174, 212],
    [253, 192, 134],
    [255, 255, 153],
    [56, 108, 176],
    [240, 2, 127],
    [191, 91, 23],
  ],
  qualitativeGreenBrown_7: [
    [27, 158, 119],
    [217, 95, 2],
    [117, 112, 179],
    [231, 41, 138],
    [102, 166, 30],
    [230, 171, 2],
    [166, 118, 29],
  ],
  qualitativeBlueCream1_7: [
    [166, 206, 227],
    [31, 120, 180],
    [178, 223, 138],
    [51, 160, 44],
    [251, 154, 153],
    [227, 26, 28],
    [253, 191, 111],
  ],
  qualitativePinkCream_7: [
    [251, 180, 174],
    [179, 205, 227],
    [204, 235, 197],
    [222, 203, 228],
    [254, 217, 166],
    [255, 255, 204],
    [229, 216, 189],
  ],
  qualitativeBlueCream2_7: [
    [179, 226, 205],
    [253, 205, 172],
    [203, 213, 232],
    [244, 202, 228],
    [230, 245, 201],
    [255, 242, 174],
    [241, 226, 204],
  ],
  qualitativeRedBrown_7: [
    [228, 26, 28],
    [55, 126, 184],
    [77, 175, 74],
    [152, 78, 163],
    [255, 127, 0],
    [255, 255, 51],
    [166, 86, 40],
  ],
  qualitativeGreenCream_7: [
    [102, 194, 165],
    [252, 141, 98],
    [141, 160, 203],
    [231, 138, 195],
    [166, 216, 84],
    [255, 217, 47],
    [229, 196, 148],
  ],
  qualitativeBlueGreen_7: [
    [141, 211, 199],
    [255, 255, 179],
    [190, 186, 218],
    [251, 128, 114],
    [128, 177, 211],
    [253, 180, 98],
    [179, 222, 105],
  ],
  rDivergingBlueRed_7: [
    [0, 147, 156],
    [101, 179, 186],
    [162, 212, 215],
    [254, 238, 232],
    [245, 176, 151],
    [225, 116, 73],
    [194, 46, 0],
  ].reverse(),
  rSequentialBlue1_7: [
    [230, 250, 250],
    [193, 229, 230],
    [157, 208, 212],
    [117, 187, 193],
    [75, 167, 175],
    [0, 147, 156],
    [16, 129, 136],
  ].reverse(),
  rQualitativeBluePink_7: [
    [18, 147, 154],
    [221, 178, 124],
    [136, 87, 44],
    [255, 153, 31],
    [241, 92, 23],
    [34, 63, 154],
    [218, 112, 191],
  ].reverse(),
  rDivergingBlueYellow_7: [
    [33, 62, 154],
    [70, 31, 169],
    [155, 27, 186],
    [202, 22, 142],
    [218, 16, 44],
    [233, 94, 8],
    [249, 226, 0],
  ].reverse(),
  rDivergingBluePink_7: [
    [1, 152, 189],
    [84, 186, 185],
    [167, 220, 182],
    [250, 254, 179],
    [252, 213, 131],
    [254, 173, 84],
    [213, 2, 85],
  ].reverse(),
  rSequentialVioletYellow_7: [
    [90, 24, 70],
    [117, 26, 67],
    [145, 25, 50],
    [172, 28, 23],
    [200, 68, 17],
    [227, 123, 10],
    [255, 195, 0],
  ].reverse(),
  rSequentialBluePink_7: [
    [53, 92, 125],
    [99, 97, 127],
    [145, 102, 129],
    [192, 108, 132],
    [210, 131, 137],
    [229, 154, 143],
    [248, 177, 149],
  ].reverse(),
  rBlueLightGreen_7: [
    [58, 116, 138],
    [75, 154, 149],
    [94, 171, 139],
    [115, 188, 132],
    [146, 204, 139],
    [190, 221, 165],
    [229, 238, 193],
  ].reverse(),
  rSequentialViolet_7: [
    [44, 30, 61],
    [79, 49, 90],
    [119, 73, 118],
    [149, 100, 133],
    [178, 130, 148],
    [207, 164, 168],
    [237, 209, 202],
  ].reverse(),
  rVioletLightGreen_7: [
    [43, 30, 62],
    [48, 53, 88],
    [70, 99, 115],
    [95, 142, 134],
    [123, 168, 137],
    [164, 195, 155],
    [214, 222, 191],
  ].reverse(),
  rSinglehueGreen_7: [
    [237, 248, 233],
    [199, 233, 192],
    [161, 217, 155],
    [116, 196, 118],
    [65, 171, 93],
    [35, 139, 69],
    [0, 90, 50],
  ].reverse(),
  rSequentialBlue2_7: [
    [255, 255, 204],
    [199, 233, 180],
    [127, 205, 187],
    [65, 182, 196],
    [29, 145, 192],
    [34, 94, 168],
    [12, 44, 132],
  ].reverse(),
  rSequentialBlue3_7: [
    [240, 249, 232],
    [204, 235, 197],
    [168, 221, 181],
    [123, 204, 196],
    [78, 179, 211],
    [43, 140, 190],
    [8, 88, 158],
  ].reverse(),
  rSequentialGreen2_7: [
    [237, 248, 251],
    [204, 236, 230],
    [153, 216, 201],
    [102, 194, 164],
    [65, 174, 118],
    [35, 139, 69],
    [0, 88, 36],
  ].reverse(),
  rSequentialBlue4_7: [
    [246, 239, 247],
    [208, 209, 230],
    [166, 189, 219],
    [103, 169, 207],
    [54, 144, 192],
    [2, 129, 138],
    [1, 100, 80],
  ].reverse(),
  rSequentialBlue5_7: [
    [241, 238, 246],
    [208, 209, 230],
    [166, 189, 219],
    [116, 169, 207],
    [54, 144, 192],
    [5, 112, 176],
    [3, 78, 123],
  ].reverse(),
  rSequentialPurple_7: [
    [237, 248, 251],
    [191, 211, 230],
    [158, 188, 218],
    [140, 150, 198],
    [140, 107, 177],
    [136, 65, 157],
    [110, 1, 107],
  ].reverse(),
  rSequentialPink_7: [
    [254, 235, 226],
    [252, 197, 192],
    [250, 159, 181],
    [247, 104, 161],
    [221, 52, 151],
    [174, 1, 126],
    [122, 1, 119],
  ].reverse(),
  rSequentialDarkPink_7: [
    [241, 238, 246],
    [212, 185, 218],
    [201, 148, 199],
    [223, 101, 176],
    [231, 41, 138],
    [206, 18, 86],
    [145, 0, 63],
  ].reverse(),
  rSequentialRed1_7: [
    [254, 240, 217],
    [253, 212, 158],
    [253, 187, 132],
    [252, 141, 89],
    [239, 101, 72],
    [215, 48, 31],
    [153, 0, 0],
  ].reverse(),
  rSequentialRed2_7: [
    [255, 255, 178],
    [254, 217, 118],
    [254, 178, 76],
    [253, 141, 60],
    [252, 78, 42],
    [227, 26, 28],
    [177, 0, 38],
  ].reverse(),
  rSinglehueBrown_7: [
    [255, 255, 212],
    [254, 227, 145],
    [254, 196, 79],
    [254, 153, 41],
    [236, 112, 20],
    [204, 76, 2],
    [140, 45, 4],
  ].reverse(),
  rSinglehuePurple_7: [
    [242, 240, 247],
    [218, 218, 235],
    [188, 189, 220],
    [158, 154, 200],
    [128, 125, 186],
    [106, 81, 163],
    [74, 20, 134],
  ].reverse(),
  rSinglehueBlue_7: [
    [239, 243, 255],
    [198, 219, 239],
    [158, 202, 225],
    [107, 174, 214],
    [66, 146, 198],
    [33, 113, 181],
    [8, 69, 148],
  ].reverse(),
  rSequentialGreen3_7: [
    [255, 255, 204],
    [217, 240, 163],
    [173, 221, 142],
    [120, 198, 121],
    [65, 171, 93],
    [35, 132, 67],
    [0, 90, 50],
  ].reverse(),
  rSequentialBrown_7: [
    [254, 237, 222],
    [253, 208, 162],
    [253, 174, 107],
    [253, 141, 60],
    [241, 105, 19],
    [217, 72, 1],
    [140, 45, 4],
  ].reverse(),
  rSinglehueRed_7: [
    [254, 229, 217],
    [252, 187, 161],
    [252, 146, 114],
    [251, 106, 74],
    [239, 59, 44],
    [203, 24, 29],
    [153, 0, 13],
  ].reverse(),
  rSinglehueBlack_7: [
    [247, 247, 247],
    [217, 217, 217],
    [189, 189, 189],
    [150, 150, 150],
    [115, 115, 115],
    [82, 82, 82],
    [37, 37, 37],
  ].reverse(),
  rDivergingBrownPurple_7: [
    [179, 88, 6],
    [241, 163, 64],
    [254, 224, 182],
    [247, 247, 247],
    [216, 218, 235],
    [153, 142, 195],
    [84, 39, 136],
  ].reverse(),
  rDivergingBrownGreen_7: [
    [140, 81, 10],
    [216, 179, 101],
    [246, 232, 195],
    [245, 245, 245],
    [199, 234, 229],
    [90, 180, 172],
    [1, 102, 94],
  ].reverse(),
  rDivergingPurpleGreen_7: [
    [118, 42, 131],
    [175, 141, 195],
    [231, 212, 232],
    [247, 247, 247],
    [217, 240, 211],
    [127, 191, 123],
    [27, 120, 55],
  ].reverse(),
  rDivergingPinkGreen_7: [
    [197, 27, 125],
    [233, 163, 201],
    [253, 224, 239],
    [247, 247, 247],
    [230, 245, 208],
    [161, 215, 106],
    [77, 146, 33],
  ].reverse(),
  rDivergingRedBlue1_7: [
    [178, 24, 43],
    [239, 138, 98],
    [253, 219, 199],
    [247, 247, 247],
    [209, 229, 240],
    [103, 169, 207],
    [33, 102, 172],
  ].reverse(),
  rDivergingRedGray_7: [
    [178, 24, 43],
    [239, 138, 98],
    [253, 219, 199],
    [255, 255, 255],
    [224, 224, 224],
    [153, 153, 153],
    [77, 77, 77],
  ].reverse(),
  rDivergingRedBlue2_7: [
    [215, 48, 39],
    [252, 141, 89],
    [254, 224, 144],
    [255, 255, 191],
    [224, 243, 248],
    [145, 191, 219],
    [69, 117, 180],
  ].reverse(),
  rDivergingOrangeBlue_7: [
    [213, 62, 79],
    [252, 141, 89],
    [254, 224, 139],
    [255, 255, 191],
    [230, 245, 152],
    [153, 213, 148],
    [50, 136, 189],
  ].reverse(),
  rDivergingRedGreen_7: [
    [215, 48, 39],
    [252, 141, 89],
    [254, 224, 139],
    [255, 255, 191],
    [217, 239, 139],
    [145, 207, 96],
    [26, 152, 80],
  ].reverse(),
  rQualitativeLightGreenBrown_7: [
    [127, 201, 127],
    [190, 174, 212],
    [253, 192, 134],
    [255, 255, 153],
    [56, 108, 176],
    [240, 2, 127],
    [191, 91, 23],
  ].reverse(),
  rQualitativeGreenBrown_7: [
    [27, 158, 119],
    [217, 95, 2],
    [117, 112, 179],
    [231, 41, 138],
    [102, 166, 30],
    [230, 171, 2],
    [166, 118, 29],
  ].reverse(),
  rQualitativeBlueCream1_7: [
    [166, 206, 227],
    [31, 120, 180],
    [178, 223, 138],
    [51, 160, 44],
    [251, 154, 153],
    [227, 26, 28],
    [253, 191, 111],
  ].reverse(),
  rQualitativePinkCream_7: [
    [251, 180, 174],
    [179, 205, 227],
    [204, 235, 197],
    [222, 203, 228],
    [254, 217, 166],
    [255, 255, 204],
    [229, 216, 189],
  ].reverse(),
  rQualitativeBlueCream2_7: [
    [179, 226, 205],
    [253, 205, 172],
    [203, 213, 232],
    [244, 202, 228],
    [230, 245, 201],
    [255, 242, 174],
    [241, 226, 204],
  ].reverse(),
  rQualitativeRedBrown_7: [
    [228, 26, 28],
    [55, 126, 184],
    [77, 175, 74],
    [152, 78, 163],
    [255, 127, 0],
    [255, 255, 51],
    [166, 86, 40],
  ].reverse(),
  rQualitativeGreenCream_7: [
    [102, 194, 165],
    [252, 141, 98],
    [141, 160, 203],
    [231, 138, 195],
    [166, 216, 84],
    [255, 217, 47],
    [229, 196, 148],
  ].reverse(),
  rQualitativeBlueGreen_7: [
    [141, 211, 199],
    [255, 255, 179],
    [190, 186, 218],
    [251, 128, 114],
    [128, 177, 211],
    [253, 180, 98],
    [179, 222, 105],
  ].reverse(),
  aqua: [
    [230, 250, 250],
    [193, 229, 230],
    [157, 208, 212],
    [117, 187, 193],
    [75, 167, 175],
    [0, 147, 156],
    [16, 129, 136],
    [14, 112, 119],
  ],
  divergingBlueRed1_8: [
    [0, 147, 156],
    [62, 173, 179],
    [124, 199, 203],
    [186, 225, 226],
    [248, 192, 170],
    [230, 143, 113],
    [212, 95, 57],
    [194, 46, 0],
  ],
  qualitativeBlueDarkBlue_8: [
    [18, 147, 154],
    [221, 178, 124],
    [136, 87, 44],
    [255, 153, 31],
    [241, 92, 23],
    [34, 63, 154],
    [218, 112, 191],
    [18, 92, 119],
  ],
  divergingBlueYellow_8: [
    [33, 62, 154],
    [60, 31, 167],
    [129, 28, 181],
    [195, 24, 176],
    [208, 19, 103],
    [222, 15, 14],
    [236, 112, 7],
    [249, 226, 0],
  ],
  divergingBlueRed2_8: [
    [0, 122, 153],
    [1, 152, 189],
    [73, 227, 206],
    [232, 254, 181],
    [254, 237, 177],
    [254, 173, 84],
    [213, 2, 85],
    [127, 25, 65],
  ],
  sequentialVioletYellow_8: [
    [76, 0, 53],
    [101, 0, 49],
    [127, 0, 35],
    [152, 0, 10],
    [178, 24, 0],
    [203, 70, 0],
    [229, 127, 0],
    [255, 195, 0],
  ],
  sequentialBluePink_8: [
    [25, 66, 102],
    [53, 92, 125],
    [99, 97, 127],
    [145, 102, 129],
    [192, 108, 132],
    [210, 131, 137],
    [229, 154, 143],
    [248, 177, 149],
  ],
  sequentialGreen_8: [
    [0, 147, 156],
    [58, 116, 138],
    [75, 154, 149],
    [94, 171, 139],
    [115, 188, 132],
    [146, 204, 139],
    [190, 221, 165],
    [229, 238, 193],
  ],
  sequentialViolet1_8: [
    [44, 30, 61],
    [80, 49, 94],
    [118, 68, 118],
    [154, 91, 136],
    [183, 116, 149],
    [207, 145, 163],
    [224, 177, 179],
    [237, 209, 202],
  ],
  sequentialViolet2_8: [
    [43, 30, 62],
    [56, 60, 101],
    [62, 95, 126],
    [73, 131, 138],
    [94, 162, 141],
    [130, 187, 146],
    [174, 206, 161],
    [214, 222, 191],
  ],
  sequentialGreen1_8: [
    [247, 252, 245],
    [247, 252, 185],
    [217, 240, 163],
    [173, 221, 142],
    [120, 198, 121],
    [65, 171, 93],
    [35, 132, 67],
    [0, 90, 50],
  ],
  sequentialBlue2_8: [
    [255, 255, 255],
    [237, 248, 177],
    [199, 233, 180],
    [127, 205, 187],
    [65, 182, 196],
    [29, 145, 192],
    [34, 94, 168],
    [12, 44, 132],
  ],
  sequentialBlue3_8: [
    [247, 252, 240],
    [224, 243, 219],
    [204, 235, 197],
    [168, 221, 181],
    [123, 204, 196],
    [78, 179, 211],
    [43, 140, 190],
    [8, 88, 158],
  ],
  sequentialGreen2_8: [
    [247, 252, 253],
    [229, 245, 249],
    [204, 236, 230],
    [153, 216, 201],
    [102, 194, 164],
    [65, 174, 118],
    [35, 139, 69],
    [0, 88, 36],
  ],
  sequentialBluishGreen_8: [
    [255, 247, 251],
    [236, 226, 240],
    [208, 209, 230],
    [166, 189, 219],
    [103, 169, 207],
    [54, 144, 192],
    [2, 129, 138],
    [1, 100, 80],
  ],
  sequentialBlue4_8: [
    [255, 247, 251],
    [236, 231, 242],
    [208, 209, 230],
    [166, 189, 219],
    [116, 169, 207],
    [54, 144, 192],
    [5, 112, 176],
    [3, 78, 123],
  ],
  sequentialPurple_8: [
    [247, 252, 253],
    [224, 236, 244],
    [191, 211, 230],
    [158, 188, 218],
    [140, 150, 198],
    [140, 107, 177],
    [136, 65, 157],
    [110, 1, 107],
  ],
  sequentialPink_8: [
    [255, 247, 243],
    [253, 224, 221],
    [252, 197, 192],
    [250, 159, 181],
    [247, 104, 161],
    [221, 52, 151],
    [174, 1, 126],
    [122, 1, 119],
  ],
  sequentialPinkishPurple_8: [
    [247, 244, 249],
    [231, 225, 239],
    [212, 185, 218],
    [201, 148, 199],
    [223, 101, 176],
    [231, 41, 138],
    [206, 18, 86],
    [145, 0, 63],
  ],
  sequentialRed1_8: [
    [255, 247, 236],
    [254, 232, 200],
    [253, 212, 158],
    [253, 187, 132],
    [252, 141, 89],
    [239, 101, 72],
    [215, 48, 31],
    [153, 0, 0],
  ],
  sequentialRed2_8: [
    [255, 255, 204],
    [255, 237, 160],
    [254, 178, 76],
    [254, 178, 76],
    [253, 141, 60],
    [252, 78, 42],
    [227, 26, 28],
    [177, 0, 38],
  ],
  sequentialBrown_8: [
    [255, 255, 229],
    [255, 247, 188],
    [254, 227, 145],
    [254, 196, 79],
    [254, 153, 41],
    [236, 112, 20],
    [204, 76, 2],
    [140, 45, 4],
  ],
  singlehuePurple_8: [
    [252, 251, 253],
    [239, 237, 245],
    [218, 218, 235],
    [158, 154, 200],
    [128, 125, 186],
    [106, 81, 163],
    [74, 20, 134],
  ],
  singlehueBlue_8: [
    [247, 251, 255],
    [222, 235, 247],
    [198, 219, 239],
    [158, 202, 225],
    [107, 174, 214],
    [66, 146, 198],
    [33, 113, 181],
    [8, 69, 148],
  ],
  singlehueGreen_8: [
    [247, 252, 245],
    [229, 245, 224],
    [199, 233, 192],
    [161, 217, 155],
    [116, 196, 118],
    [65, 171, 93],
    [35, 139, 69],
    [0, 90, 50],
  ],
  singlehueBrown_8: [
    [247, 252, 253],
    [254, 230, 206],
    [253, 208, 162],
    [253, 174, 107],
    [253, 141, 60],
    [241, 105, 19],
    [217, 72, 1],
    [140, 45, 4],
  ],
  singlehueRed_8: [
    [255, 245, 240],
    [254, 224, 210],
    [252, 187, 161],
    [252, 146, 114],
    [251, 106, 74],
    [239, 59, 44],
    [203, 24, 29],
    [153, 0, 13],
  ],
  singlehueBlack_8: [
    [247, 252, 253],
    [240, 240, 240],
    [217, 217, 217],
    [189, 189, 189],
    [150, 150, 150],
    [115, 115, 115],
    [82, 82, 82],
    [37, 37, 37],
  ],
  divergingBrownPurple_8: [
    [179, 88, 6],
    [224, 130, 20],
    [253, 184, 99],
    [254, 224, 182],
    [216, 218, 235],
    [178, 171, 210],
    [128, 115, 172],
    [84, 39, 136],
  ],
  divergingBrownGreen_8: [
    [140, 81, 10],
    [191, 129, 45],
    [223, 194, 125],
    [246, 232, 195],
    [199, 234, 229],
    [128, 205, 193],
    [53, 151, 143],
    [1, 102, 94],
  ],
  divergingPurpleGreen_8: [
    [118, 42, 131],
    [153, 112, 171],
    [194, 165, 207],
    [231, 212, 232],
    [217, 240, 211],
    [166, 219, 160],
    [90, 174, 97],
    [27, 120, 55],
  ],
  divergingPinkGreen_8: [
    [197, 27, 125],
    [222, 119, 174],
    [241, 182, 218],
    [253, 224, 239],
    [230, 245, 208],
    [184, 225, 134],
    [127, 188, 65],
    [77, 146, 33],
  ],
  divergingRedBlue1_8: [
    [178, 24, 43],
    [214, 96, 77],
    [244, 165, 130],
    [253, 219, 199],
    [209, 229, 240],
    [146, 197, 222],
    [67, 147, 195],
    [33, 102, 172],
  ],
  divergingRedGray_8: [
    [178, 24, 43],
    [214, 96, 77],
    [244, 165, 130],
    [253, 219, 199],
    [224, 224, 224],
    [186, 186, 186],
    [135, 135, 135],
    [77, 77, 77],
  ],
  divergingRedBlue2_8: [
    [215, 48, 39],
    [244, 109, 67],
    [253, 174, 97],
    [254, 224, 144],
    [224, 243, 248],
    [171, 217, 233],
    [116, 173, 209],
    [69, 117, 180],
  ],
  divergingRedGreenishBlue_8: [
    [213, 62, 79],
    [244, 109, 67],
    [253, 174, 97],
    [254, 224, 139],
    [230, 245, 152],
    [171, 221, 164],
    [102, 194, 165],
    [50, 136, 189],
  ],
  divergingRedGreen_8: [
    [215, 48, 39],
    [244, 109, 67],
    [253, 174, 97],
    [254, 224, 139],
    [217, 239, 139],
    [166, 217, 106],
    [102, 189, 99],
    [26, 152, 80],
  ],
  qualitativeLightGreenGray_8: [
    [127, 201, 127],
    [190, 174, 212],
    [253, 192, 134],
    [255, 255, 153],
    [56, 108, 176],
    [240, 2, 127],
    [191, 91, 23],
    [102, 102, 102],
  ],
  qualitativeGreenGray1_8: [
    [27, 158, 119],
    [217, 95, 2],
    [117, 112, 179],
    [231, 41, 138],
    [102, 166, 30],
    [230, 171, 2],
    [166, 118, 29],
    [102, 102, 102],
  ],
  qualitativeBlueOrange_8: [
    [166, 206, 227],
    [31, 120, 180],
    [178, 223, 138],
    [51, 160, 44],
    [251, 154, 153],
    [227, 26, 28],
    [253, 191, 111],
    [255, 127, 0],
  ],
  qualitativePinkLightPurple_8: [
    [251, 180, 174],
    [179, 205, 227],
    [204, 235, 197],
    [222, 203, 228],
    [254, 217, 166],
    [255, 255, 204],
    [229, 216, 189],
    [253, 218, 236],
  ],
  qualitativeGreenGray2_8: [
    [179, 226, 205],
    [253, 205, 172],
    [203, 213, 232],
    [244, 202, 228],
    [230, 245, 201],
    [255, 242, 174],
    [241, 226, 204],
    [204, 204, 204],
  ],
  qualitativeRedGreen_8: [
    [228, 26, 28],
    [55, 126, 184],
    [77, 175, 74],
    [152, 78, 163],
    [255, 127, 0],
    [255, 255, 51],
    [166, 86, 40],
    [247, 129, 191],
  ],
  qualitativeBluishGreenGray_8: [
    [102, 194, 165],
    [252, 141, 98],
    [141, 160, 203],
    [231, 138, 195],
    [166, 216, 84],
    [255, 217, 47],
    [229, 196, 148],
    [179, 179, 179],
  ],
  qualitativeBluishGreenPink_8: [
    [141, 211, 199],
    [255, 255, 179],
    [190, 186, 218],
    [251, 128, 114],
    [128, 177, 211],
    [253, 180, 98],
    [179, 222, 105],
    [252, 205, 229],
  ],
  rSequentialBlue1_8: [
    [230, 250, 250],
    [193, 229, 230],
    [157, 208, 212],
    [117, 187, 193],
    [75, 167, 175],
    [0, 147, 156],
    [16, 129, 136],
    [14, 112, 119],
  ].reverse(),
  rDivergingBlueRed1_8: [
    [0, 147, 156],
    [62, 173, 179],
    [124, 199, 203],
    [186, 225, 226],
    [248, 192, 170],
    [230, 143, 113],
    [212, 95, 57],
    [194, 46, 0],
  ].reverse(),
  rQualitativeBlueDarkBlue_8: [
    [18, 147, 154],
    [221, 178, 124],
    [136, 87, 44],
    [255, 153, 31],
    [241, 92, 23],
    [34, 63, 154],
    [218, 112, 191],
    [18, 92, 119],
  ].reverse(),
  rDivergingBlueYellow_8: [
    [33, 62, 154],
    [60, 31, 167],
    [129, 28, 181],
    [195, 24, 176],
    [208, 19, 103],
    [222, 15, 14],
    [236, 112, 7],
    [249, 226, 0],
  ].reverse(),
  rDivergingBlueRed2_8: [
    [0, 122, 153],
    [1, 152, 189],
    [73, 227, 206],
    [232, 254, 181],
    [254, 237, 177],
    [254, 173, 84],
    [213, 2, 85],
    [127, 25, 65],
  ].reverse(),
  rSequentialVioletYellow_8: [
    [76, 0, 53],
    [101, 0, 49],
    [127, 0, 35],
    [152, 0, 10],
    [178, 24, 0],
    [203, 70, 0],
    [229, 127, 0],
    [255, 195, 0],
  ].reverse(),
  rSequentialBluePink_8: [
    [25, 66, 102],
    [53, 92, 125],
    [99, 97, 127],
    [145, 102, 129],
    [192, 108, 132],
    [210, 131, 137],
    [229, 154, 143],
    [248, 177, 149],
  ].reverse(),
  rSequentialGreen_8: [
    [0, 147, 156],
    [58, 116, 138],
    [75, 154, 149],
    [94, 171, 139],
    [115, 188, 132],
    [146, 204, 139],
    [190, 221, 165],
    [229, 238, 193],
  ].reverse(),
  rSequentialViolet1_8: [
    [44, 30, 61],
    [80, 49, 94],
    [118, 68, 118],
    [154, 91, 136],
    [183, 116, 149],
    [207, 145, 163],
    [224, 177, 179],
    [237, 209, 202],
  ].reverse(),
  rSequentialViolet2_8: [
    [43, 30, 62],
    [56, 60, 101],
    [62, 95, 126],
    [73, 131, 138],
    [94, 162, 141],
    [130, 187, 146],
    [174, 206, 161],
    [214, 222, 191],
  ].reverse(),
  rSequentialGreen1_8: [
    [247, 252, 245],
    [247, 252, 185],
    [217, 240, 163],
    [173, 221, 142],
    [120, 198, 121],
    [65, 171, 93],
    [35, 132, 67],
    [0, 90, 50],
  ].reverse(),
  rSequentialBlue2_8: [
    [255, 255, 255],
    [237, 248, 177],
    [199, 233, 180],
    [127, 205, 187],
    [65, 182, 196],
    [29, 145, 192],
    [34, 94, 168],
    [12, 44, 132],
  ].reverse(),
  rSequentialBlue3_8: [
    [247, 252, 240],
    [224, 243, 219],
    [204, 235, 197],
    [168, 221, 181],
    [123, 204, 196],
    [78, 179, 211],
    [43, 140, 190],
    [8, 88, 158],
  ].reverse(),
  rSequentialGreen2_8: [
    [247, 252, 253],
    [229, 245, 249],
    [204, 236, 230],
    [153, 216, 201],
    [102, 194, 164],
    [65, 174, 118],
    [35, 139, 69],
    [0, 88, 36],
  ].reverse(),
  rSequentialBluishGreen_8: [
    [255, 247, 251],
    [236, 226, 240],
    [208, 209, 230],
    [166, 189, 219],
    [103, 169, 207],
    [54, 144, 192],
    [2, 129, 138],
    [1, 100, 80],
  ].reverse(),
  rSequentialBlue4_8: [
    [255, 247, 251],
    [236, 231, 242],
    [208, 209, 230],
    [166, 189, 219],
    [116, 169, 207],
    [54, 144, 192],
    [5, 112, 176],
    [3, 78, 123],
  ].reverse(),
  rSequentialPurple_8: [
    [247, 252, 253],
    [224, 236, 244],
    [191, 211, 230],
    [158, 188, 218],
    [140, 150, 198],
    [140, 107, 177],
    [136, 65, 157],
    [110, 1, 107],
  ].reverse(),
  rSequentialPink_8: [
    [255, 247, 243],
    [253, 224, 221],
    [252, 197, 192],
    [250, 159, 181],
    [247, 104, 161],
    [221, 52, 151],
    [174, 1, 126],
    [122, 1, 119],
  ].reverse(),
  rSequentialPinkishPurple_8: [
    [247, 244, 249],
    [231, 225, 239],
    [212, 185, 218],
    [201, 148, 199],
    [223, 101, 176],
    [231, 41, 138],
    [206, 18, 86],
    [145, 0, 63],
  ].reverse(),
  rSequentialRed1_8: [
    [255, 247, 236],
    [254, 232, 200],
    [253, 212, 158],
    [253, 187, 132],
    [252, 141, 89],
    [239, 101, 72],
    [215, 48, 31],
    [153, 0, 0],
  ].reverse(),
  rSequentialRed2_8: [
    [255, 255, 204],
    [255, 237, 160],
    [254, 178, 76],
    [254, 178, 76],
    [253, 141, 60],
    [252, 78, 42],
    [227, 26, 28],
    [177, 0, 38],
  ].reverse(),
  rSequentialBrown_8: [
    [255, 255, 229],
    [255, 247, 188],
    [254, 227, 145],
    [254, 196, 79],
    [254, 153, 41],
    [236, 112, 20],
    [204, 76, 2],
    [140, 45, 4],
  ].reverse(),
  rSinglehuePurple_8: [
    [252, 251, 253],
    [239, 237, 245],
    [218, 218, 235],
    [158, 154, 200],
    [128, 125, 186],
    [106, 81, 163],
    [74, 20, 134],
  ].reverse(),
  rSinglehueBlue_8: [
    [247, 251, 255],
    [222, 235, 247],
    [198, 219, 239],
    [158, 202, 225],
    [107, 174, 214],
    [66, 146, 198],
    [33, 113, 181],
    [8, 69, 148],
  ].reverse(),
  rSinglehueGreen_8: [
    [247, 252, 245],
    [229, 245, 224],
    [199, 233, 192],
    [161, 217, 155],
    [116, 196, 118],
    [65, 171, 93],
    [35, 139, 69],
    [0, 90, 50],
  ].reverse(),
  rSinglehueBrown_8: [
    [247, 252, 253],
    [254, 230, 206],
    [253, 208, 162],
    [253, 174, 107],
    [253, 141, 60],
    [241, 105, 19],
    [217, 72, 1],
    [140, 45, 4],
  ].reverse(),
  rSinglehueRed_8: [
    [255, 245, 240],
    [254, 224, 210],
    [252, 187, 161],
    [252, 146, 114],
    [251, 106, 74],
    [239, 59, 44],
    [203, 24, 29],
    [153, 0, 13],
  ].reverse(),
  rSinglehueBlack_8: [
    [247, 252, 253],
    [240, 240, 240],
    [217, 217, 217],
    [189, 189, 189],
    [150, 150, 150],
    [115, 115, 115],
    [82, 82, 82],
    [37, 37, 37],
  ].reverse(),
  rDivergingBrownPurple_8: [
    [179, 88, 6],
    [224, 130, 20],
    [253, 184, 99],
    [254, 224, 182],
    [216, 218, 235],
    [178, 171, 210],
    [128, 115, 172],
    [84, 39, 136],
  ].reverse(),
  rDivergingBrownGreen_8: [
    [140, 81, 10],
    [191, 129, 45],
    [223, 194, 125],
    [246, 232, 195],
    [199, 234, 229],
    [128, 205, 193],
    [53, 151, 143],
    [1, 102, 94],
  ].reverse(),
  rDivergingPurpleGreen_8: [
    [118, 42, 131],
    [153, 112, 171],
    [194, 165, 207],
    [231, 212, 232],
    [217, 240, 211],
    [166, 219, 160],
    [90, 174, 97],
    [27, 120, 55],
  ].reverse(),
  rDivergingPinkGreen_8: [
    [197, 27, 125],
    [222, 119, 174],
    [241, 182, 218],
    [253, 224, 239],
    [230, 245, 208],
    [184, 225, 134],
    [127, 188, 65],
    [77, 146, 33],
  ].reverse(),
  rDivergingRedBlue1_8: [
    [178, 24, 43],
    [214, 96, 77],
    [244, 165, 130],
    [253, 219, 199],
    [209, 229, 240],
    [146, 197, 222],
    [67, 147, 195],
    [33, 102, 172],
  ].reverse(),
  rDivergingRedGray_8: [
    [178, 24, 43],
    [214, 96, 77],
    [244, 165, 130],
    [253, 219, 199],
    [224, 224, 224],
    [186, 186, 186],
    [135, 135, 135],
    [77, 77, 77],
  ].reverse(),
  rDivergingRedBlue2_8: [
    [215, 48, 39],
    [244, 109, 67],
    [253, 174, 97],
    [254, 224, 144],
    [224, 243, 248],
    [171, 217, 233],
    [116, 173, 209],
    [69, 117, 180],
  ].reverse(),
  rDivergingRedGreenishBlue_8: [
    [213, 62, 79],
    [244, 109, 67],
    [253, 174, 97],
    [254, 224, 139],
    [230, 245, 152],
    [171, 221, 164],
    [102, 194, 165],
    [50, 136, 189],
  ].reverse(),
  rDivergingRedGreen_8: [
    [215, 48, 39],
    [244, 109, 67],
    [253, 174, 97],
    [254, 224, 139],
    [217, 239, 139],
    [166, 217, 106],
    [102, 189, 99],
    [26, 152, 80],
  ].reverse(),
  rQualitativeLightGreenGray_8: [
    [127, 201, 127],
    [190, 174, 212],
    [253, 192, 134],
    [255, 255, 153],
    [56, 108, 176],
    [240, 2, 127],
    [191, 91, 23],
    [102, 102, 102],
  ].reverse(),
  rQualitativeGreenGray1_8: [
    [27, 158, 119],
    [217, 95, 2],
    [117, 112, 179],
    [231, 41, 138],
    [102, 166, 30],
    [230, 171, 2],
    [166, 118, 29],
    [102, 102, 102],
  ].reverse(),
  rQualitativeBlueOrange_8: [
    [166, 206, 227],
    [31, 120, 180],
    [178, 223, 138],
    [51, 160, 44],
    [251, 154, 153],
    [227, 26, 28],
    [253, 191, 111],
    [255, 127, 0],
  ].reverse(),
  rQualitativePinkLightPurple_8: [
    [251, 180, 174],
    [179, 205, 227],
    [204, 235, 197],
    [222, 203, 228],
    [254, 217, 166],
    [255, 255, 204],
    [229, 216, 189],
    [253, 218, 236],
  ].reverse(),
  rQualitativeGreenGray2_8: [
    [179, 226, 205],
    [253, 205, 172],
    [203, 213, 232],
    [244, 202, 228],
    [230, 245, 201],
    [255, 242, 174],
    [241, 226, 204],
    [204, 204, 204],
  ].reverse(),
  rQualitativeRedGreen_8: [
    [228, 26, 28],
    [55, 126, 184],
    [77, 175, 74],
    [152, 78, 163],
    [255, 127, 0],
    [255, 255, 51],
    [166, 86, 40],
    [247, 129, 191],
  ].reverse(),
  rQualitativeBluishGreenGray_8: [
    [102, 194, 165],
    [252, 141, 98],
    [141, 160, 203],
    [231, 138, 195],
    [166, 216, 84],
    [255, 217, 47],
    [229, 196, 148],
    [179, 179, 179],
  ].reverse(),
  rQualitativeBluishGreenPink_8: [
    [141, 211, 199],
    [255, 255, 179],
    [190, 186, 218],
    [251, 128, 114],
    [128, 177, 211],
    [253, 180, 98],
    [179, 222, 105],
    [252, 205, 229],
  ].reverse(),
  divergingBlueRed_9: [
    [0, 147, 156],
    [73, 166, 174],
    [101, 179, 186],
    [178, 220, 223],
    [254, 238, 232],
    [248, 192, 170],
    [236, 147, 112],
    [218, 100, 54],
    [194, 46, 0],
  ],
  qualitativeBlueLightBlue_9: [
    [18, 147, 154],
    [221, 178, 124],
    [136, 87, 44],
    [255, 153, 31],
    [241, 92, 23],
    [34, 63, 154],
    [218, 112, 191],
    [18, 92, 119],
    [77, 193, 156],
  ],
  divergingBlueYellow_9: [
    [44, 81, 190],
    [72, 43, 189],
    [122, 13, 166],
    [174, 14, 127],
    [207, 23, 80],
    [227, 26, 26],
    [253, 121, 0],
    [250, 194, 0],
    [250, 227, 0],
  ],
  sequentialGreen1_9: [
    [255, 255, 229],
    [247, 252, 185],
    [217, 240, 163],
    [173, 221, 142],
    [120, 198, 121],
    [65, 171, 93],
    [35, 132, 67],
    [0, 104, 55],
    [0, 69, 41],
  ],
  sequentialBlue1_9: [
    [255, 255, 217],
    [237, 248, 177],
    [199, 233, 180],
    [127, 205, 187],
    [65, 182, 196],
    [29, 145, 192],
    [34, 94, 168],
    [37, 52, 148],
    [8, 29, 88],
  ],
  sequentialLightBlue_9: [
    [247, 252, 240],
    [224, 243, 219],
    [204, 235, 197],
    [168, 221, 181],
    [123, 204, 196],
    [78, 179, 211],
    [43, 140, 190],
    [8, 104, 172],
    [8, 64, 129],
  ],
  sequentialGreen2_9: [
    [247, 252, 253],
    [229, 245, 249],
    [204, 236, 230],
    [153, 216, 201],
    [102, 194, 164],
    [65, 174, 118],
    [35, 139, 69],
    [0, 109, 44],
    [0, 68, 27],
  ],
  sequentialBluishGreen_9: [
    [255, 247, 251],
    [236, 226, 240],
    [208, 209, 230],
    [166, 189, 219],
    [103, 169, 207],
    [54, 144, 192],
    [2, 129, 138],
    [1, 108, 89],
    [1, 70, 54],
  ],
  sequentialBlue2_9: [
    [255, 247, 251],
    [236, 231, 242],
    [208, 209, 230],
    [166, 189, 219],
    [116, 169, 207],
    [54, 144, 192],
    [5, 112, 176],
    [4, 90, 141],
    [2, 56, 88],
  ],
  sequentialPurple_9: [
    [247, 252, 253],
    [224, 236, 244],
    [191, 211, 230],
    [158, 188, 218],
    [140, 150, 198],
    [140, 107, 177],
    [136, 65, 157],
    [129, 15, 124],
    [77, 0, 75],
  ],
  sequentialPinkishPurple_9: [
    [255, 247, 243],
    [253, 224, 221],
    [252, 197, 192],
    [250, 159, 181],
    [247, 104, 161],
    [221, 52, 151],
    [174, 1, 126],
    [122, 1, 119],
    [73, 0, 106],
  ],
  sequentialReddishPink_9: [
    [247, 244, 249],
    [231, 225, 239],
    [212, 185, 218],
    [201, 148, 199],
    [223, 101, 176],
    [231, 41, 138],
    [206, 18, 86],
    [152, 0, 67],
    [103, 0, 31],
  ],
  sequentialRed_9: [
    [247, 244, 249],
    [255, 247, 236],
    [254, 232, 200],
    [253, 187, 132],
    [252, 141, 89],
    [239, 101, 72],
    [215, 48, 31],
    [179, 0, 0],
    [127, 0, 0],
  ],
  sequentialLightRed_9: [
    [255, 255, 204],
    [255, 237, 160],
    [254, 217, 118],
    [254, 178, 76],
    [253, 141, 60],
    [252, 78, 42],
    [227, 26, 28],
    [189, 0, 38],
    [128, 0, 38],
  ],
  sequentialBrown_9: [
    [255, 255, 229],
    [255, 247, 188],
    [254, 227, 145],
    [254, 196, 79],
    [254, 153, 41],
    [236, 112, 20],
    [204, 76, 2],
    [153, 52, 4],
    [47, 55, 66],
  ],
  singlehuePurple_9: [
    [252, 251, 253],
    [239, 237, 245],
    [218, 218, 235],
    [188, 189, 220],
    [158, 154, 200],
    [128, 125, 186],
    [106, 81, 163],
    [84, 39, 143],
    [63, 0, 125],
  ],
  singlehueBlue_9: [
    [255, 247, 251],
    [222, 235, 247],
    [198, 219, 239],
    [158, 202, 225],
    [107, 174, 214],
    [66, 146, 198],
    [33, 113, 181],
    [8, 81, 156],
    [8, 48, 107],
  ],
  singlehueGreen_9: [
    [247, 252, 245],
    [229, 245, 224],
    [199, 233, 192],
    [161, 217, 155],
    [116, 196, 118],
    [65, 171, 93],
    [35, 139, 69],
    [0, 109, 44],
    [0, 68, 27],
  ],
  singlehueBrown_9: [
    [255, 247, 251],
    [254, 230, 206],
    [253, 208, 162],
    [253, 174, 107],
    [253, 141, 60],
    [241, 105, 19],
    [217, 72, 1],
    [166, 54, 3],
    [127, 39, 4],
  ],
  singlehueRed_9: [
    [255, 245, 240],
    [254, 224, 210],
    [252, 187, 161],
    [252, 146, 114],
    [251, 106, 74],
    [239, 59, 44],
    [203, 24, 29],
    [165, 15, 21],
    [103, 0, 13],
  ],
  singlehueBlack_9: [
    [255, 255, 255],
    [240, 240, 240],
    [217, 217, 217],
    [189, 189, 189],
    [150, 150, 150],
    [115, 115, 115],
    [82, 82, 82],
    [37, 37, 37],
    [0, 0, 0],
  ],
  divergingBrownPurple_9: [
    [179, 88, 6],
    [224, 130, 20],
    [253, 184, 99],
    [254, 224, 182],
    [247, 247, 247],
    [216, 218, 235],
    [178, 171, 210],
    [128, 115, 172],
    [84, 39, 136],
  ],
  divergingBrownGreen_9: [
    [140, 81, 10],
    [191, 129, 45],
    [223, 194, 125],
    [246, 232, 195],
    [245, 245, 245],
    [199, 234, 229],
    [128, 205, 193],
    [53, 151, 143],
    [1, 102, 94],
  ],
  divergingPurpleGreen_9: [
    [118, 42, 131],
    [153, 112, 171],
    [194, 165, 207],
    [231, 212, 232],
    [247, 247, 247],
    [217, 240, 211],
    [166, 219, 160],
    [90, 174, 97],
    [27, 120, 55],
  ],
  divergingPinkGreen_9: [
    [197, 27, 125],
    [222, 119, 174],
    [241, 182, 218],
    [253, 224, 239],
    [247, 247, 247],
    [230, 245, 208],
    [184, 225, 134],
    [127, 188, 65],
    [77, 146, 33],
  ],
  divergingRedBlue1_9: [
    [178, 24, 43],
    [214, 96, 77],
    [244, 165, 130],
    [253, 219, 199],
    [247, 247, 247],
    [209, 229, 240],
    [146, 197, 222],
    [67, 147, 195],
    [33, 102, 172],
  ],
  divergingRedGray_9: [
    [178, 24, 43],
    [214, 96, 77],
    [244, 165, 130],
    [253, 219, 199],
    [255, 255, 255],
    [224, 224, 224],
    [186, 186, 186],
    [135, 135, 135],
    [77, 77, 77],
  ],
  divergingRedBlue2_9: [
    [215, 48, 39],
    [244, 109, 67],
    [253, 174, 97],
    [254, 224, 144],
    [255, 255, 191],
    [224, 243, 248],
    [171, 217, 233],
    [116, 173, 209],
    [69, 117, 180],
  ],
  divergingPinkBlue_9: [
    [213, 62, 79],
    [244, 109, 67],
    [253, 174, 97],
    [254, 224, 139],
    [255, 255, 191],
    [230, 245, 152],
    [171, 221, 164],
    [102, 194, 165],
    [50, 136, 189],
  ],
  divergingRedGreen_9: [
    [215, 48, 39],
    [244, 109, 67],
    [253, 174, 97],
    [254, 224, 139],
    [255, 255, 191],
    [217, 239, 139],
    [166, 217, 106],
    [102, 189, 99],
    [26, 152, 80],
  ],
  qualitativeBluePurple_9: [
    [166, 206, 227],
    [31, 120, 180],
    [178, 223, 138],
    [51, 160, 44],
    [251, 154, 153],
    [227, 26, 28],
    [253, 191, 111],
    [255, 127, 0],
    [202, 178, 214],
  ],
  qualitativePinkLightPink_9: [
    [251, 180, 174],
    [179, 205, 227],
    [204, 235, 197],
    [222, 203, 228],
    [254, 217, 166],
    [255, 255, 204],
    [229, 216, 189],
    [253, 218, 236],
    [242, 242, 242],
  ],
  qualitativeRedGray_9: [
    [228, 26, 28],
    [55, 126, 184],
    [77, 175, 74],
    [152, 78, 163],
    [255, 127, 0],
    [255, 255, 51],
    [166, 86, 40],
    [247, 129, 191],
    [153, 153, 153],
  ],
  qualitativeLightBlueGray_9: [
    [141, 211, 199],
    [255, 255, 179],
    [190, 186, 218],
    [251, 128, 114],
    [128, 177, 211],
    [253, 180, 98],
    [179, 222, 105],
    [252, 205, 229],
    [217, 217, 217],
  ],
  rDivergingBlueRed_9: [
    [0, 147, 156],
    [73, 166, 174],
    [101, 179, 186],
    [178, 220, 223],
    [254, 238, 232],
    [248, 192, 170],
    [236, 147, 112],
    [218, 100, 54],
    [194, 46, 0],
  ].reverse(),
  rQualitativeBlueLightBlue_9: [
    [18, 147, 154],
    [221, 178, 124],
    [136, 87, 44],
    [255, 153, 31],
    [241, 92, 23],
    [34, 63, 154],
    [218, 112, 191],
    [18, 92, 119],
    [77, 193, 156],
  ].reverse(),
  rDivergingBlueYellow_9: [
    [44, 81, 190],
    [72, 43, 189],
    [122, 13, 166],
    [174, 14, 127],
    [207, 23, 80],
    [227, 26, 26],
    [253, 121, 0],
    [250, 194, 0],
    [250, 227, 0],
  ].reverse(),
  rSequentialGreen1_9: [
    [255, 255, 229],
    [247, 252, 185],
    [217, 240, 163],
    [173, 221, 142],
    [120, 198, 121],
    [65, 171, 93],
    [35, 132, 67],
    [0, 104, 55],
    [0, 69, 41],
  ].reverse(),
  rSequentialBlue1_9: [
    [255, 255, 217],
    [237, 248, 177],
    [199, 233, 180],
    [127, 205, 187],
    [65, 182, 196],
    [29, 145, 192],
    [34, 94, 168],
    [37, 52, 148],
    [8, 29, 88],
  ].reverse(),
  rSequentialLightBlue_9: [
    [247, 252, 240],
    [224, 243, 219],
    [204, 235, 197],
    [168, 221, 181],
    [123, 204, 196],
    [78, 179, 211],
    [43, 140, 190],
    [8, 104, 172],
    [8, 64, 129],
  ].reverse(),
  rSequentialGreen2_9: [
    [247, 252, 253],
    [229, 245, 249],
    [204, 236, 230],
    [153, 216, 201],
    [102, 194, 164],
    [65, 174, 118],
    [35, 139, 69],
    [0, 109, 44],
    [0, 68, 27],
  ].reverse(),
  rSequentialBluishGreen_9: [
    [255, 247, 251],
    [236, 226, 240],
    [208, 209, 230],
    [166, 189, 219],
    [103, 169, 207],
    [54, 144, 192],
    [2, 129, 138],
    [1, 108, 89],
    [1, 70, 54],
  ].reverse(),
  rSequentialBlue2_9: [
    [255, 247, 251],
    [236, 231, 242],
    [208, 209, 230],
    [166, 189, 219],
    [116, 169, 207],
    [54, 144, 192],
    [5, 112, 176],
    [4, 90, 141],
    [2, 56, 88],
  ].reverse(),
  rSequentialPurple_9: [
    [247, 252, 253],
    [224, 236, 244],
    [191, 211, 230],
    [158, 188, 218],
    [140, 150, 198],
    [140, 107, 177],
    [136, 65, 157],
    [129, 15, 124],
    [77, 0, 75],
  ].reverse(),
  rSequentialPinkishPurple_9: [
    [255, 247, 243],
    [253, 224, 221],
    [252, 197, 192],
    [250, 159, 181],
    [247, 104, 161],
    [221, 52, 151],
    [174, 1, 126],
    [122, 1, 119],
    [73, 0, 106],
  ].reverse(),
  rSequentialReddishPink_9: [
    [247, 244, 249],
    [231, 225, 239],
    [212, 185, 218],
    [201, 148, 199],
    [223, 101, 176],
    [231, 41, 138],
    [206, 18, 86],
    [152, 0, 67],
    [103, 0, 31],
  ].reverse(),
  rSequentialRed_9: [
    [247, 244, 249],
    [255, 247, 236],
    [254, 232, 200],
    [253, 187, 132],
    [252, 141, 89],
    [239, 101, 72],
    [215, 48, 31],
    [179, 0, 0],
    [127, 0, 0],
  ].reverse(),
  rSequentialLightRed_9: [
    [255, 255, 204],
    [255, 237, 160],
    [254, 217, 118],
    [254, 178, 76],
    [253, 141, 60],
    [252, 78, 42],
    [227, 26, 28],
    [189, 0, 38],
    [128, 0, 38],
  ].reverse(),
  rSequentialBrown_9: [
    [255, 255, 229],
    [255, 247, 188],
    [254, 227, 145],
    [254, 196, 79],
    [254, 153, 41],
    [236, 112, 20],
    [204, 76, 2],
    [153, 52, 4],
    [47, 55, 66],
  ].reverse(),
  rSinglehuePurple_9: [
    [252, 251, 253],
    [239, 237, 245],
    [218, 218, 235],
    [188, 189, 220],
    [158, 154, 200],
    [128, 125, 186],
    [106, 81, 163],
    [84, 39, 143],
    [63, 0, 125],
  ].reverse(),
  rSinglehueBlue_9: [
    [255, 247, 251],
    [222, 235, 247],
    [198, 219, 239],
    [158, 202, 225],
    [107, 174, 214],
    [66, 146, 198],
    [33, 113, 181],
    [8, 81, 156],
    [8, 48, 107],
  ].reverse(),
  rSinglehueGreen_9: [
    [247, 252, 245],
    [229, 245, 224],
    [199, 233, 192],
    [161, 217, 155],
    [116, 196, 118],
    [65, 171, 93],
    [35, 139, 69],
    [0, 109, 44],
    [0, 68, 27],
  ].reverse(),
  rSinglehueBrown_9: [
    [255, 247, 251],
    [254, 230, 206],
    [253, 208, 162],
    [253, 174, 107],
    [253, 141, 60],
    [241, 105, 19],
    [217, 72, 1],
    [166, 54, 3],
    [127, 39, 4],
  ].reverse(),
  rSinglehueRed_9: [
    [255, 245, 240],
    [254, 224, 210],
    [252, 187, 161],
    [252, 146, 114],
    [251, 106, 74],
    [239, 59, 44],
    [203, 24, 29],
    [165, 15, 21],
    [103, 0, 13],
  ].reverse(),
  rSinglehueBlack_9: [
    [255, 255, 255],
    [240, 240, 240],
    [217, 217, 217],
    [189, 189, 189],
    [150, 150, 150],
    [115, 115, 115],
    [82, 82, 82],
    [37, 37, 37],
    [0, 0, 0],
  ].reverse(),
  rDivergingBrownPurple_9: [
    [179, 88, 6],
    [224, 130, 20],
    [253, 184, 99],
    [254, 224, 182],
    [247, 247, 247],
    [216, 218, 235],
    [178, 171, 210],
    [128, 115, 172],
    [84, 39, 136],
  ].reverse(),
  rDivergingBrownGreen_9: [
    [140, 81, 10],
    [191, 129, 45],
    [223, 194, 125],
    [246, 232, 195],
    [245, 245, 245],
    [199, 234, 229],
    [128, 205, 193],
    [53, 151, 143],
    [1, 102, 94],
  ].reverse(),
  rDivergingPurpleGreen_9: [
    [118, 42, 131],
    [153, 112, 171],
    [194, 165, 207],
    [231, 212, 232],
    [247, 247, 247],
    [217, 240, 211],
    [166, 219, 160],
    [90, 174, 97],
    [27, 120, 55],
  ].reverse(),
  rDivergingPinkGreen_9: [
    [197, 27, 125],
    [222, 119, 174],
    [241, 182, 218],
    [253, 224, 239],
    [247, 247, 247],
    [230, 245, 208],
    [184, 225, 134],
    [127, 188, 65],
    [77, 146, 33],
  ].reverse(),
  rDivergingRedBlue1_9: [
    [178, 24, 43],
    [214, 96, 77],
    [244, 165, 130],
    [253, 219, 199],
    [247, 247, 247],
    [209, 229, 240],
    [146, 197, 222],
    [67, 147, 195],
    [33, 102, 172],
  ].reverse(),
  rDivergingRedGray_9: [
    [178, 24, 43],
    [214, 96, 77],
    [244, 165, 130],
    [253, 219, 199],
    [255, 255, 255],
    [224, 224, 224],
    [186, 186, 186],
    [135, 135, 135],
    [77, 77, 77],
  ].reverse(),
  rDivergingRedBlue2_9: [
    [215, 48, 39],
    [244, 109, 67],
    [253, 174, 97],
    [254, 224, 144],
    [255, 255, 191],
    [224, 243, 248],
    [171, 217, 233],
    [116, 173, 209],
    [69, 117, 180],
  ].reverse(),
  rDivergingPinkBlue_9: [
    [213, 62, 79],
    [244, 109, 67],
    [253, 174, 97],
    [254, 224, 139],
    [255, 255, 191],
    [230, 245, 152],
    [171, 221, 164],
    [102, 194, 165],
    [50, 136, 189],
  ].reverse(),
  rDivergingRedGreen_9: [
    [215, 48, 39],
    [244, 109, 67],
    [253, 174, 97],
    [254, 224, 139],
    [255, 255, 191],
    [217, 239, 139],
    [166, 217, 106],
    [102, 189, 99],
    [26, 152, 80],
  ].reverse(),
  rQualitativeBluePurple_9: [
    [166, 206, 227],
    [31, 120, 180],
    [178, 223, 138],
    [51, 160, 44],
    [251, 154, 153],
    [227, 26, 28],
    [253, 191, 111],
    [255, 127, 0],
    [202, 178, 214],
  ].reverse(),
  rQualitativePinkLightPink_9: [
    [251, 180, 174],
    [179, 205, 227],
    [204, 235, 197],
    [222, 203, 228],
    [254, 217, 166],
    [255, 255, 204],
    [229, 216, 189],
    [253, 218, 236],
    [242, 242, 242],
  ].reverse(),
  rQualitativeRedGray_9: [
    [228, 26, 28],
    [55, 126, 184],
    [77, 175, 74],
    [152, 78, 163],
    [255, 127, 0],
    [255, 255, 51],
    [166, 86, 40],
    [247, 129, 191],
    [153, 153, 153],
  ].reverse(),
  rQualitativeLightBlueGray_9: [
    [141, 211, 199],
    [255, 255, 179],
    [190, 186, 218],
    [251, 128, 114],
    [128, 177, 211],
    [253, 180, 98],
    [179, 222, 105],
    [252, 205, 229],
    [217, 217, 217],
  ].reverse(),
  divergingBlueRed_10: [
    [0, 147, 156],
    [47, 167, 174],
    [93, 186, 191],
    [140, 206, 209],
    [186, 225, 226],
    [248, 192, 170],
    [235, 156, 128],
    [221, 119, 85],
    [208, 83, 43],
    [194, 46, 0],
  ],
  qualitativeBlueBrownishGreen_10: [
    [18, 147, 154],
    [221, 178, 124],
    [136, 87, 44],
    [255, 153, 31],
    [241, 92, 23],
    [34, 63, 154],
    [218, 112, 191],
    [18, 92, 119],
    [77, 193, 156],
    [119, 110, 87],
  ],
  divergingBlueYellow_10: [
    [34, 63, 154],
    [44, 81, 190],
    [72, 43, 189],
    [122, 13, 166],
    [174, 14, 127],
    [207, 23, 80],
    [227, 26, 26],
    [253, 121, 0],
    [250, 194, 0],
    [250, 227, 0],
  ],
  divergingBrownPurple_10: [
    [127, 59, 8],
    [179, 88, 6],
    [224, 130, 20],
    [253, 184, 99],
    [254, 224, 182],
    [216, 218, 235],
    [178, 171, 210],
    [128, 115, 172],
    [84, 39, 136],
    [45, 0, 75],
  ],
  divergingBrownGreen_10: [
    [127, 59, 8],
    [179, 88, 6],
    [224, 130, 20],
    [253, 184, 99],
    [254, 224, 182],
    [199, 234, 229],
    [128, 205, 193],
    [53, 151, 143],
    [1, 102, 94],
    [0, 60, 48],
  ],
  divergingPurpleGreen_10: [
    [64, 0, 75],
    [118, 42, 131],
    [153, 112, 171],
    [194, 165, 207],
    [231, 212, 232],
    [217, 240, 211],
    [166, 219, 160],
    [90, 174, 97],
    [27, 120, 55],
    [0, 68, 27],
  ],
  divergingPinkGreen_10: [
    [142, 1, 82],
    [197, 27, 125],
    [222, 119, 174],
    [241, 182, 218],
    [253, 224, 239],
    [230, 245, 208],
    [184, 225, 134],
    [127, 188, 65],
    [77, 146, 33],
    [39, 100, 25],
  ],
  divergingRedBlue_10: [
    [103, 0, 31],
    [178, 24, 43],
    [214, 96, 77],
    [244, 165, 130],
    [253, 219, 199],
    [209, 229, 240],
    [146, 197, 222],
    [67, 147, 195],
    [33, 102, 172],
    [5, 48, 97],
  ],
  divergingRedGray_10: [
    [103, 0, 31],
    [178, 24, 43],
    [214, 96, 77],
    [244, 165, 130],
    [253, 219, 199],
    [224, 224, 224],
    [186, 186, 186],
    [135, 135, 135],
    [77, 77, 77],
    [26, 26, 26],
  ],
  divergingLightRedBlue_10: [
    [165, 0, 38],
    [215, 48, 39],
    [244, 109, 67],
    [253, 174, 97],
    [254, 224, 144],
    [224, 243, 248],
    [171, 217, 233],
    [116, 173, 209],
    [69, 117, 180],
    [49, 54, 149],
  ],
  divergingRedPurple_10: [
    [158, 1, 66],
    [213, 62, 79],
    [244, 109, 67],
    [253, 174, 97],
    [254, 224, 139],
    [230, 245, 152],
    [171, 221, 164],
    [102, 194, 165],
    [50, 136, 189],
    [94, 79, 162],
  ],
  divergingRedGreen_10: [
    [165, 0, 38],
    [215, 48, 39],
    [244, 109, 67],
    [253, 174, 97],
    [254, 224, 139],
    [217, 239, 139],
    [166, 217, 106],
    [102, 189, 99],
    [26, 152, 80],
    [0, 104, 55],
  ],
  qualitativeLightBluePurple_10: [
    [166, 206, 227],
    [31, 120, 180],
    [178, 223, 138],
    [51, 160, 44],
    [251, 154, 153],
    [227, 26, 28],
    [253, 191, 111],
    [255, 127, 0],
    [202, 178, 214],
    [106, 61, 154],
  ],
  qualitativeBluePurple_10: [
    [141, 211, 199],
    [255, 255, 179],
    [190, 186, 218],
    [251, 128, 114],
    [128, 177, 211],
    [253, 180, 98],
    [179, 222, 105],
    [252, 205, 229],
    [217, 217, 217],
    [188, 128, 189],
  ],
  rDivergingBlueRed_10: [
    [0, 147, 156],
    [47, 167, 174],
    [93, 186, 191],
    [140, 206, 209],
    [186, 225, 226],
    [248, 192, 170],
    [235, 156, 128],
    [221, 119, 85],
    [208, 83, 43],
    [194, 46, 0],
  ].reverse(),
  rQualitativeBlueBrownishGreen_10: [
    [18, 147, 154],
    [221, 178, 124],
    [136, 87, 44],
    [255, 153, 31],
    [241, 92, 23],
    [34, 63, 154],
    [218, 112, 191],
    [18, 92, 119],
    [77, 193, 156],
    [119, 110, 87],
  ].reverse(),
  rDivergingBlueYellow_10: [
    [34, 63, 154],
    [44, 81, 190],
    [72, 43, 189],
    [122, 13, 166],
    [174, 14, 127],
    [207, 23, 80],
    [227, 26, 26],
    [253, 121, 0],
    [250, 194, 0],
    [250, 227, 0],
  ].reverse(),
  rDivergingBrownPurple_10: [
    [127, 59, 8],
    [179, 88, 6],
    [224, 130, 20],
    [253, 184, 99],
    [254, 224, 182],
    [216, 218, 235],
    [178, 171, 210],
    [128, 115, 172],
    [84, 39, 136],
    [45, 0, 75],
  ].reverse(),
  rDivergingBrownGreen_10: [
    [127, 59, 8],
    [179, 88, 6],
    [224, 130, 20],
    [253, 184, 99],
    [254, 224, 182],
    [199, 234, 229],
    [128, 205, 193],
    [53, 151, 143],
    [1, 102, 94],
    [0, 60, 48],
  ].reverse(),
  rDivergingPurpleGreen_10: [
    [64, 0, 75],
    [118, 42, 131],
    [153, 112, 171],
    [194, 165, 207],
    [231, 212, 232],
    [217, 240, 211],
    [166, 219, 160],
    [90, 174, 97],
    [27, 120, 55],
    [0, 68, 27],
  ].reverse(),
  rDivergingPinkGreen_10: [
    [142, 1, 82],
    [197, 27, 125],
    [222, 119, 174],
    [241, 182, 218],
    [253, 224, 239],
    [230, 245, 208],
    [184, 225, 134],
    [127, 188, 65],
    [77, 146, 33],
    [39, 100, 25],
  ].reverse(),
  rDivergingRedBlue_10: [
    [103, 0, 31],
    [178, 24, 43],
    [214, 96, 77],
    [244, 165, 130],
    [253, 219, 199],
    [209, 229, 240],
    [146, 197, 222],
    [67, 147, 195],
    [33, 102, 172],
    [5, 48, 97],
  ].reverse(),
  rDivergingRedGray_10: [
    [103, 0, 31],
    [178, 24, 43],
    [214, 96, 77],
    [244, 165, 130],
    [253, 219, 199],
    [224, 224, 224],
    [186, 186, 186],
    [135, 135, 135],
    [77, 77, 77],
    [26, 26, 26],
  ].reverse(),
  rDivergingLightRedBlue_10: [
    [165, 0, 38],
    [215, 48, 39],
    [244, 109, 67],
    [253, 174, 97],
    [254, 224, 144],
    [224, 243, 248],
    [171, 217, 233],
    [116, 173, 209],
    [69, 117, 180],
    [49, 54, 149],
  ].reverse(),
  rDivergingRedPurple_10: [
    [158, 1, 66],
    [213, 62, 79],
    [244, 109, 67],
    [253, 174, 97],
    [254, 224, 139],
    [230, 245, 152],
    [171, 221, 164],
    [102, 194, 165],
    [50, 136, 189],
    [94, 79, 162],
  ].reverse(),
  rDivergingRedGreen_10: [
    [165, 0, 38],
    [215, 48, 39],
    [244, 109, 67],
    [253, 174, 97],
    [254, 224, 139],
    [217, 239, 139],
    [166, 217, 106],
    [102, 189, 99],
    [26, 152, 80],
    [0, 104, 55],
  ].reverse(),
  rQualitativeLightBluePurple_10: [
    [166, 206, 227],
    [31, 120, 180],
    [178, 223, 138],
    [51, 160, 44],
    [251, 154, 153],
    [227, 26, 28],
    [253, 191, 111],
    [255, 127, 0],
    [202, 178, 214],
    [106, 61, 154],
  ].reverse(),
  rQualitativeBluePurple_10: [
    [141, 211, 199],
    [255, 255, 179],
    [190, 186, 218],
    [251, 128, 114],
    [128, 177, 211],
    [253, 180, 98],
    [179, 222, 105],
    [252, 205, 229],
    [217, 217, 217],
    [188, 128, 189],
  ].reverse(),
  divergingBlueRed_11: [
    [0, 147, 156],
    [73, 166, 174],
    [113, 186, 191],
    [151, 206, 209],
    [186, 225, 226],
    [254, 238, 232],
    [248, 192, 170],
    [242, 165, 135],
    [230, 128, 89],
    [213, 90, 43],
    [194, 46, 0],
  ],
  divergingBrownPurple_11: [
    [127, 59, 8],
    [179, 88, 6],
    [224, 130, 20],
    [253, 184, 99],
    [254, 224, 182],
    [247, 247, 247],
    [216, 218, 235],
    [178, 171, 210],
    [128, 115, 172],
    [84, 39, 136],
    [45, 0, 75],
  ],
  divergingBrownGreen_11: [
    [84, 48, 5],
    [140, 81, 10],
    [191, 129, 45],
    [223, 194, 125],
    [246, 232, 195],
    [245, 245, 245],
    [199, 234, 229],
    [128, 205, 193],
    [53, 151, 143],
    [1, 102, 94],
    [0, 60, 48],
  ],
  divergingPurpleGreen_11: [
    [64, 0, 75],
    [118, 42, 131],
    [153, 112, 171],
    [194, 165, 207],
    [231, 212, 232],
    [247, 247, 247],
    [217, 240, 211],
    [166, 219, 160],
    [90, 174, 97],
    [27, 120, 55],
    [0, 68, 27],
  ],
  divergingPinkGreen_11: [
    [142, 1, 82],
    [197, 27, 125],
    [222, 119, 174],
    [241, 182, 218],
    [253, 224, 239],
    [247, 247, 247],
    [230, 245, 208],
    [184, 225, 134],
    [127, 188, 65],
    [77, 146, 33],
    [39, 100, 25],
  ],
  divergingRedBlue_11: [
    [103, 0, 31],
    [178, 24, 43],
    [214, 96, 77],
    [244, 165, 130],
    [253, 219, 199],
    [247, 247, 247],
    [209, 229, 240],
    [146, 197, 222],
    [67, 147, 195],
    [33, 102, 172],
    [5, 48, 97],
  ],
  divergingRedGray_11: [
    [103, 0, 31],
    [178, 24, 43],
    [214, 96, 77],
    [244, 165, 130],
    [253, 219, 199],
    [247, 247, 247],
    [224, 224, 224],
    [186, 186, 186],
    [135, 135, 135],
    [77, 77, 77],
    [26, 26, 26],
  ],
  divergingLightRedBlue_11: [
    [165, 0, 38],
    [215, 48, 39],
    [244, 109, 67],
    [253, 174, 97],
    [254, 224, 144],
    [255, 255, 191],
    [224, 243, 248],
    [171, 217, 233],
    [116, 173, 209],
    [69, 117, 180],
    [49, 54, 149],
  ],
  divergingRedPurple_11: [
    [158, 1, 66],
    [213, 62, 79],
    [244, 109, 67],
    [253, 174, 97],
    [254, 224, 139],
    [255, 255, 191],
    [230, 245, 152],
    [171, 221, 164],
    [102, 194, 165],
    [50, 136, 189],
    [94, 79, 162],
  ],
  divergingRedGreen_11: [
    [165, 0, 38],
    [215, 48, 39],
    [244, 109, 67],
    [253, 174, 97],
    [254, 224, 139],
    [255, 255, 191],
    [217, 239, 139],
    [166, 217, 106],
    [102, 189, 99],
    [26, 152, 80],
    [0, 104, 55],
  ],
  qualitativeLightBlueYellow_11: [
    [166, 206, 227],
    [31, 120, 180],
    [178, 223, 138],
    [51, 160, 44],
    [251, 154, 153],
    [227, 26, 28],
    [253, 191, 111],
    [255, 127, 0],
    [202, 178, 214],
    [106, 61, 154],
    [255, 255, 153],
  ],
  qualitativeLightBlueLightGreen_11: [
    [141, 211, 199],
    [255, 255, 179],
    [190, 186, 218],
    [251, 128, 114],
    [128, 177, 211],
    [253, 180, 98],
    [179, 222, 105],
    [252, 205, 229],
    [217, 217, 217],
    [188, 128, 189],
    [204, 235, 197],
  ],
  rDivergingBlueRed_11: [
    [0, 147, 156],
    [73, 166, 174],
    [113, 186, 191],
    [151, 206, 209],
    [186, 225, 226],
    [254, 238, 232],
    [248, 192, 170],
    [242, 165, 135],
    [230, 128, 89],
    [213, 90, 43],
    [194, 46, 0],
  ].reverse(),
  rDivergingBrownPurple_11: [
    [127, 59, 8],
    [179, 88, 6],
    [224, 130, 20],
    [253, 184, 99],
    [254, 224, 182],
    [247, 247, 247],
    [216, 218, 235],
    [178, 171, 210],
    [128, 115, 172],
    [84, 39, 136],
    [45, 0, 75],
  ].reverse(),
  rDivergingBrownGreen_11: [
    [84, 48, 5],
    [140, 81, 10],
    [191, 129, 45],
    [223, 194, 125],
    [246, 232, 195],
    [245, 245, 245],
    [199, 234, 229],
    [128, 205, 193],
    [53, 151, 143],
    [1, 102, 94],
    [0, 60, 48],
  ].reverse(),
  rDivergingPurpleGreen_11: [
    [64, 0, 75],
    [118, 42, 131],
    [153, 112, 171],
    [194, 165, 207],
    [231, 212, 232],
    [247, 247, 247],
    [217, 240, 211],
    [166, 219, 160],
    [90, 174, 97],
    [27, 120, 55],
    [0, 68, 27],
  ].reverse(),
  rDivergingPinkGreen_11: [
    [142, 1, 82],
    [197, 27, 125],
    [222, 119, 174],
    [241, 182, 218],
    [253, 224, 239],
    [247, 247, 247],
    [230, 245, 208],
    [184, 225, 134],
    [127, 188, 65],
    [77, 146, 33],
    [39, 100, 25],
  ].reverse(),
  rDivergingRedBlue_11: [
    [103, 0, 31],
    [178, 24, 43],
    [214, 96, 77],
    [244, 165, 130],
    [253, 219, 199],
    [247, 247, 247],
    [209, 229, 240],
    [146, 197, 222],
    [67, 147, 195],
    [33, 102, 172],
    [5, 48, 97],
  ].reverse(),
  rDivergingRedGray_11: [
    [103, 0, 31],
    [178, 24, 43],
    [214, 96, 77],
    [244, 165, 130],
    [253, 219, 199],
    [247, 247, 247],
    [224, 224, 224],
    [186, 186, 186],
    [135, 135, 135],
    [77, 77, 77],
    [26, 26, 26],
  ].reverse(),
  rDivergingLightRedBlue_11: [
    [165, 0, 38],
    [215, 48, 39],
    [244, 109, 67],
    [253, 174, 97],
    [254, 224, 144],
    [255, 255, 191],
    [224, 243, 248],
    [171, 217, 233],
    [116, 173, 209],
    [69, 117, 180],
    [49, 54, 149],
  ].reverse(),
  rDivergingRedPurple_11: [
    [158, 1, 66],
    [213, 62, 79],
    [244, 109, 67],
    [253, 174, 97],
    [254, 224, 139],
    [255, 255, 191],
    [230, 245, 152],
    [171, 221, 164],
    [102, 194, 165],
    [50, 136, 189],
    [94, 79, 162],
  ].reverse(),
  rDivergingRedGreen_11: [
    [165, 0, 38],
    [215, 48, 39],
    [244, 109, 67],
    [253, 174, 97],
    [254, 224, 139],
    [255, 255, 191],
    [217, 239, 139],
    [166, 217, 106],
    [102, 189, 99],
    [26, 152, 80],
    [0, 104, 55],
  ].reverse(),
  rQualitativeLightBlueYellow_11: [
    [166, 206, 227],
    [31, 120, 180],
    [178, 223, 138],
    [51, 160, 44],
    [251, 154, 153],
    [227, 26, 28],
    [253, 191, 111],
    [255, 127, 0],
    [202, 178, 214],
    [106, 61, 154],
    [255, 255, 153],
  ].reverse(),
  rQualitativeLightBlueLightGreen_11: [
    [141, 211, 199],
    [255, 255, 179],
    [190, 186, 218],
    [251, 128, 114],
    [128, 177, 211],
    [253, 180, 98],
    [179, 222, 105],
    [252, 205, 229],
    [217, 217, 217],
    [188, 128, 189],
    [204, 235, 197],
  ].reverse(),
  qualitativeBlueBrown_12: [
    [166, 206, 227],
    [31, 120, 180],
    [178, 223, 138],
    [51, 160, 44],
    [251, 154, 153],
    [227, 26, 28],
    [253, 191, 111],
    [255, 127, 0],
    [202, 178, 214],
    [106, 61, 154],
    [255, 255, 153],
    [177, 89, 40],
  ],
  qualitativeBlueYellow_12: [
    [141, 211, 199],
    [255, 255, 179],
    [190, 186, 218],
    [251, 128, 114],
    [128, 177, 211],
    [253, 180, 98],
    [179, 222, 105],
    [252, 205, 229],
    [217, 217, 217],
    [188, 128, 189],
    [204, 235, 197],
    [255, 237, 111],
  ],
  rQualitativeBlueBrown_12: [
    [166, 206, 227],
    [31, 120, 180],
    [178, 223, 138],
    [51, 160, 44],
    [251, 154, 153],
    [227, 26, 28],
    [253, 191, 111],
    [255, 127, 0],
    [202, 178, 214],
    [106, 61, 154],
    [255, 255, 153],
    [177, 89, 40],
  ].reverse(),
  rQualitativeBlueYellow_12: [
    [141, 211, 199],
    [255, 255, 179],
    [190, 186, 218],
    [251, 128, 114],
    [128, 177, 211],
    [253, 180, 98],
    [179, 222, 105],
    [252, 205, 229],
    [217, 217, 217],
    [188, 128, 189],
    [204, 235, 197],
    [255, 237, 111],
  ].reverse(),
  qualitativeBluePink_15: [
    [18, 147, 154],
    [221, 178, 124],
    [136, 87, 44],
    [255, 153, 31],
    [241, 92, 23],
    [34, 63, 154],
    [218, 112, 191],
    [18, 92, 119],
    [77, 193, 156],
    [119, 110, 87],
    [23, 184, 190],
    [246, 209, 138],
    [183, 136, 94],
    [255, 203, 153],
    [248, 149, 112],
  ],
  rQualitativeBluePink_15: [
    [18, 147, 154],
    [221, 178, 124],
    [136, 87, 44],
    [255, 153, 31],
    [241, 92, 23],
    [34, 63, 154],
    [218, 112, 191],
    [18, 92, 119],
    [77, 193, 156],
    [119, 110, 87],
    [23, 184, 190],
    [246, 209, 138],
    [183, 136, 94],
    [255, 203, 153],
    [248, 149, 112],
  ].reverse(),
  qualitativeBlueGray_20: [
    [18, 147, 154],
    [221, 178, 124],
    [136, 87, 44],
    [255, 153, 31],
    [241, 92, 23],
    [34, 63, 154],
    [218, 112, 191],
    [18, 92, 119],
    [77, 193, 156],
    [119, 110, 87],
    [23, 184, 190],
    [246, 209, 138],
    [183, 136, 94],
    [255, 203, 153],
    [248, 149, 112],
    [130, 154, 227],
    [231, 159, 213],
    [30, 150, 190],
    [137, 218, 193],
    [179, 173, 158],
  ],
  rQualitativeBlueGray_20: [
    [18, 147, 154],
    [221, 178, 124],
    [136, 87, 44],
    [255, 153, 31],
    [241, 92, 23],
    [34, 63, 154],
    [218, 112, 191],
    [18, 92, 119],
    [77, 193, 156],
    [119, 110, 87],
    [23, 184, 190],
    [246, 209, 138],
    [183, 136, 94],
    [255, 203, 153],
    [248, 149, 112],
    [130, 154, 227],
    [231, 159, 213],
    [30, 150, 190],
    [137, 218, 193],
    [179, 173, 158],
  ].reverse(),
  yellowOrange_6: [
    [244, 222, 0],
    [241, 191, 0],
    [236, 161, 0],
    [228, 129, 0],
    [217, 98, 0],
    [198, 59, 0],
  ],
  rYellowOrange_6: [
    [244, 222, 0],
    [241, 191, 0],
    [236, 161, 0],
    [228, 129, 0],
    [217, 98, 0],
    [198, 59, 0],
  ].reverse(),
  grade: [
    [37, 153, 5],
    [56, 194, 17],
    [107, 222, 75],
    [223, 230, 21],
    [230, 167, 21],
    [230, 125, 21],
    [230, 56, 21],
    [10, 10, 10],
  ],
  rGrade: [
    [37, 153, 5],
    [56, 194, 17],
    [107, 222, 75],
    [223, 230, 21],
    [230, 167, 21],
    [230, 125, 21],
    [230, 56, 21],
    [10, 10, 10],
  ].reverse(),
  grade_7: [
    [37, 153, 5],
    [56, 194, 17],
    [107, 222, 75],
    [223, 230, 21],
    [230, 167, 21],
    [230, 125, 21],
    [230, 56, 21],
  ],
  rGrade_7: [
    [37, 153, 5],
    [56, 194, 17],
    [107, 222, 75],
    [223, 230, 21],
    [230, 167, 21],
    [230, 125, 21],
    [230, 56, 21],
  ].reverse(),
  greyRed_9: [
    [247, 247, 247],
    [30, 81, 123],
    [75, 119, 190],
    [137, 196, 244],
    [144, 198, 149],
    [255, 255, 159],
    [255, 148, 112],
    [231, 109, 137],
    [196, 77, 86],
  ],
  rGreyRed: [
    [196, 77, 86],
    [231, 109, 137],
    [255, 148, 112],
    [255, 255, 159],
    [144, 198, 149],
    [137, 196, 244],
    [75, 119, 190],
    [30, 81, 123],
    [247, 247, 247],
  ],
};
