import { useEffect, useState } from "react";
//@ts-ignore
import { VerticalBarSeries, XYPlot } from "react-vis";
import { getHistogram } from "../functions/layerProperties";

const BarPlot = ({ dataset, field, appliedFilter, fieldId }: any) => {
  const [data, setData] = useState();
  const { type, rows } = dataset;

  useEffect(() => {
    const { minmax } = appliedFilter.filter(
      (item: any) => item["fieldId"] === fieldId
    )[0].data;
    const res = getHistogram(minmax, rows, field, type);
    setData(res);
    //eslint-disable-next-line
  }, [field]);

  return (
    <XYPlot
      className="bar-plot"
      width={300}
      height={66}
      margin={0}
      style={{
        position: "absolute",
        left: 0,
        right: 0,
        margin: "0 auto",
        backgroundColor: "#f0f8ff",
      }}
    >
      <VerticalBarSeries data={data} barWidth={0.45} />
    </XYPlot>
  );
};
export default BarPlot;
