export const leoDevfirebaseConfig = {
  apiKey: process.env.REACT_APP_LEO_DEV_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_LEO_DEV_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_LEO_DEV_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_LEO_DEV_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_LEO_DEV_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_LEO_DEV_FIREBASE_APP_ID,
};

export const leoProdfirebaseConfig = {
  apiKey: process.env.REACT_APP_LEO_PROD_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_LEO_PROD_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_LEO_PROD_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_LEO_PROD_FIREBASE_STORAGE_BUCKET,
  messagingSenderId:
    process.env.REACT_APP_LEO_PROD_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_LEO_PROD_FIREBASE_APP_ID,
};

export const resopDevfirebaseConfig = {
  apiKey: process.env.REACT_APP_RESOP_DEV_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_RESOP_DEV_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_RESOP_DEV_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_RESOP_DEV_FIREBASE_STORAGE_BUCKET,
  messagingSenderId:
    process.env.REACT_APP_RESOP_DEV_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_RESOP_DEV_FIREBASE_APP_ID,
};

export const pkcDevfirebaseConfig = {
  apiKey: process.env.REACT_APP_PKC_DEV_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_PKC_DEV_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PKC_DEV_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_PKC_DEV_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_PKC_DEV_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_PKC_DEV_FIREBASE_APP_ID,
};

export const wmcaDevfirebaseConfig = {
  apiKey: process.env.REACT_APP_WMCA_DEV_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_WMCA_DEV_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_WMCA_DEV_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_WMCA_DEV_FIREBASE_STORAGE_BUCKET,
  messagingSenderId:
    process.env.REACT_APP_WMCA_DEV_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_WMCA_DEV_FIREBASE_APP_ID,
};

export const ukpnDevfirebaseConfig = {
  apiKey: process.env.REACT_APP_UKPN_DEV_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_UKPN_DEV_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_UKPN_DEV_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_UKPN_DEV_FIREBASE_STORAGE_BUCKET,
  messagingSenderId:
    process.env.REACT_APP_UKPN_DEV_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_UKPN_DEV_FIREBASE_APP_ID,
};
