//@ts-ignore
import { CloseOutlined } from "@ant-design/icons";
//@ts-ignore
import DeckGl from "@deck.gl/react";
import { Button, Descriptions } from "antd";
import { useMemo, useState } from "react";
import { StaticMap, Popup, _MapContext as MapContext } from "react-map-gl";
import { useSelector } from "react-redux";
import { selectLayer } from "../functions/selectLayer";
import MapTools from "./MapTools";

export default function Deck({ dataset }: any) {
  const { configlayers, viewState } = useSelector((state: any) => state.deckgl);
  const [objectInfo, setObjectInfo] = useState<any>(undefined);
  const layers = useMemo(() => {
    const res = Object.keys(dataset).map((id: string) =>
      selectLayer(id, dataset[id].value, configlayers[id])
    );

    return res;
  }, [dataset, configlayers]);

  return (
    <div style={{ position: "relative", width: "100%", height: "100%" }}>
      <DeckGl
        layers={layers}
        initialViewState={viewState}
        controller={true}
        onClick={({ object, coordinate }: any) => {
          if (object) setObjectInfo({ object, coordinate });
          else setObjectInfo(undefined);
        }}
        getCursor={({ isDragging, isHovering }: any) =>
          isDragging ? "grabbing" : isHovering ? "pointer" : "grab"
        }
        ContextProvider={MapContext.Provider}
      >
        <StaticMap
          mapStyle="mapbox://styles/mapbox/streets-v11"
          mapboxApiAccessToken="pk.eyJ1Ijoic2FtYWl0bCIsImEiOiJja3E3eWlndGgwMjhvMnVtc2huenR6Z3N0In0.Vdj6e5sGXIBGfOxchYk20w"
        />
        {objectInfo && (
          <Popup
            anchor="top"
            latitude={objectInfo?.coordinate[1]}
            longitude={objectInfo?.coordinate[0]}
            onClose={() => setObjectInfo(undefined)}
            captureScroll={true}
            captureClick={true}
            closeButton={false}
          >
            <Descriptions
              column={1}
              layout="horizontal"
              bordered
              style={{ height: 350, overflowY: "scroll" }}
              labelStyle={{
                maxWidth: 150,
                padding: 4,
                height: 24,
                overflow: "scroll",
              }}
              contentStyle={{
                maxWidth: 175,
                padding: 4,
                height: 24,
                overflow: "scroll",
              }}
              extra={
                <Button
                  icon={<CloseOutlined />}
                  size="small"
                  onClick={() => setObjectInfo(undefined)}
                />
              }
            >
              {Object.keys(objectInfo.object).map((item, i) => (
                <Descriptions.Item label={item} key={i}>
                  {objectInfo.object[item].toString()}
                </Descriptions.Item>
              ))}
            </Descriptions>
          </Popup>
        )}
      </DeckGl>
      <MapTools dataset={dataset} />
    </div>
  );
}
