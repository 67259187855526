import { MoreOutlined } from "@ant-design/icons";
import {
  Button,
  Collapse,
  Dropdown,
  Space,
  Switch,
  Tooltip,
  Typography,
} from "antd";
import { isArray } from "lodash";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
// @ts-ignore
import styled from "styled-components";
import { COLOR_RANGE } from "../../../constants/colorRange";
import { HextoRGB, RGBToHex } from "../../../functions/colorConversion";
import useUpdateConfig from "../../../hooks/useUpdateConfig";
import ColorPalette from "./ColorPalette";
import ColorRange from "./ColorRange";
import NullishValue from "./NullishValue";
import SelectField from "./SelectField";
import SelectScale from "./SelectScale";

const { Panel } = Collapse;

const ColorComponent = (props: any) => {
  const {
    id,
    name,
    dataset: { fields, rows },
  } = props;

  const configlayers = useSelector((state: any) => state.deckgl.configlayers);

  const [selectedColor, setSelectedColor] = useState<any>("#fff");
  const [scaleVisible, setScaleVisible] = useState(false);
  const [colorRangeVisible, setColorRangeVisible] = useState(false);
  const [colorEnabled, setColorEnabled] = useState<boolean>(true);
  const [strokeEnabled, setStrokeEnabled] = useState<boolean>(true);
  const { updateConfig, updateConfigObject } = useUpdateConfig();

  useEffect(() => {
    const assignDefaultValue = (name: string) => {
      switch (name) {
        case "Fill Color":
          configlayers[id].colorBasedOn && configlayers[id].colorRange
            ? setSelectedColor(configlayers[id].colorRange)
            : setSelectedColor(RGBToHex(configlayers[id].fillColor));
          configlayers[id].colorBasedOn
            ? setColorRangeVisible(true)
            : setColorRangeVisible(false);
          configlayers[id].filled
            ? setStrokeEnabled(true)
            : setStrokeEnabled(false);
          return null;

        case "Stroke Color":
          configlayers[id].strokeColorBasedOn &&
          configlayers[id].strokeColorRange
            ? setSelectedColor(configlayers[id].strokeColorRange)
            : setSelectedColor(RGBToHex(configlayers[id].lineColor));
          configlayers[id].strokeColorBasedOn
            ? setColorRangeVisible(true)
            : setColorRangeVisible(false);
          configlayers[id].stroked
            ? setStrokeEnabled(true)
            : setStrokeEnabled(false);
          return null;
      }
    };
    assignDefaultValue(name);
    // eslint-disable-next-line
  }, [configlayers[id]]);

  const checkExpand = (e: any) => {
    if (e.target.id === "layer-header" || e.target.nodeName === "svg") return;
    e.stopPropagation();
  };

  const changeSingleColor = (value: any) => {
    var rgb = HextoRGB(value);
    setSelectedColor(value);
    if (name === "Fill Color") updateConfig(id, "fillColor", rgb);
    else if (name === "Stroke Color") updateConfig(id, "lineColor", rgb);
  };

  const changeColorRange = (value: string) => {
    setSelectedColor(value);
    if (name === "Fill Color")
      updateConfigObject(id, { colorRange: value, customColor: null });
    else if (name === "Stroke Color")
      updateConfigObject(id, {
        strokeColorRange: value,
        customStrokeColor: null,
      });
  };

  const handleEnable = (val: boolean) => {
    if (name === "Fill Color") {
      setColorEnabled(val);
      updateConfig(id, "filled", val);
    } else {
      setStrokeEnabled(val);
      updateConfig(id, "stroked", val);
    }
  };

  const getColorRange = () => {
    if (name === "Fill Color") {
      //@ts-ignore
      return COLOR_RANGE[selectedColor] ?? configlayers[id]?.customColor;
    } else {
      //@ts-ignore
      return COLOR_RANGE[selectedColor] ?? configlayers[id]?.customStrokeColor;
    }
  };

  return (
    <Collapse
      className={`layer-properties color-component ${
        (!strokeEnabled && name === "Stroke Color") ||
        (!colorEnabled && name === "Fill Color")
          ? "layer-properties-overlay"
          : ""
      }`}
      ghost
    >
      <Panel
        className="secondary"
        key={name}
        header={
          <Space
            id="default"
            direction="vertical"
            style={{ width: "100%" }}
            onClick={(e) => checkExpand(e)}
          >
            <Space
              id="layer-header"
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography.Text className="config-name">{name}</Typography.Text>
              {name !== "Opacity" ? (
                <Space>
                  {name === "Stroke Color" || name === "Fill Color" ? (
                    <Tooltip
                      title={
                        name === "Fill Color"
                          ? colorEnabled
                            ? "Disable"
                            : "Enable"
                          : strokeEnabled
                          ? "Disable"
                          : "Enable"
                      }
                      getTooltipContainer={(): any =>
                        document.querySelector(".layer-properties")
                      }
                    >
                      <Switch
                        size="small"
                        checked={
                          name === "Fill Color" ? colorEnabled : strokeEnabled
                        }
                        onChange={(val) => handleEnable(val)}
                      />
                    </Tooltip>
                  ) : null}
                  <Tooltip
                    title="More"
                    getTooltipContainer={(): any =>
                      document.querySelector(".layer-properties")
                    }
                  >
                    <MoreOutlined
                      className="more-icon"
                      id="expand"
                      style={{ fontSize: 14 }}
                    />
                  </Tooltip>
                </Space>
              ) : null}
            </Space>

            <Dropdown
              overlayStyle={{ height: 350, overflowY: "scroll" }}
              trigger={["click"]}
              overlay={
                colorRangeVisible ? (
                  <ColorRange
                    id={id}
                    config={configlayers[id]}
                    changeColorRange={changeColorRange}
                    selectedColor={selectedColor}
                    name={name}
                  />
                ) : (
                  <ColorPalette changeSingleColor={changeSingleColor} />
                )
              }
              getPopupContainer={(): any => document.querySelector(".layer")}
            >
              <Button
                block={true}
                style={{
                  // marginTop: 0,
                  backgroundColor: "lightgray",
                  textAlign: "left",
                  border: 0,
                  width: "100%",
                  height: 40,
                  color: "rgb(106, 116, 133)",
                  fontSize: 14,
                  display: "flex",
                  justifyContent: colorRangeVisible ? "center" : "start",
                  alignItems: "center",
                }}
              >
                {colorRangeVisible ? (
                  <ColorRangeRow>
                    {getColorRange()?.map((color: any, i: number) => {
                      const hexColor = isArray(color) ? RGBToHex(color) : color;
                      return (
                        <ColorElement
                          key={i}
                          style={{ backgroundColor: hexColor }}
                        />
                      );
                    })}
                  </ColorRangeRow>
                ) : (
                  <input
                    readOnly={true}
                    type="color"
                    value={selectedColor}
                    style={{ border: 0, outline: "none", cursor: "pointer" }}
                  />
                )}
              </Button>
            </Dropdown>
          </Space>
        }
        showArrow={false}
      >
        <Space style={{ width: "100%" }} direction="vertical">
          <Typography.Text className="label">{name} based on</Typography.Text>
          <SelectField
            associatedName={name}
            fields={fields}
            setScaleVisible={setScaleVisible}
            setColorRangeVisible={setColorRangeVisible}
            setSelectedColor={setSelectedColor}
            id={id}
          />
          {scaleVisible && (
            <>
              <Typography.Text className="label">{name} scale</Typography.Text>{" "}
              <SelectScale name={name} id={id} />
            </>
          )}
          {name === "Fill Color" && (
            <NullishValue id={id} config={configlayers[id]} data={{ rows }} />
          )}
        </Space>
      </Panel>
    </Collapse>
  );
};

const ColorRangeRow = styled.ul`
  display: flex;
  width: 100%;
  list-style-type: none;
  cursor: pointer;
  margin: 0;
  padding: 0;
  li:first-child {
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
  }
  li:last-child {
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
  }
`;

const ColorElement = styled.li`
  height: 15px;
  flex-grow: 1;
`;

export default ColorComponent;
