import React, { useContext, useEffect, useRef, useState } from "react";
import { Button, InputRef } from "antd";
import { Form, Input, Table } from "antd";
import type { FormInstance } from "antd/es/form";
import { isEmpty } from "lodash";

const EditableContext = React.createContext<FormInstance<any> | null>(null);

interface Item {
  key: string;
  name: string;
  age: string;
  address: string;
}

interface EditableRowProps {
  index: number;
}

const EditableRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

interface EditableCellProps {
  title: React.ReactNode;
  editable: boolean;
  children: React.ReactNode;
  dataIndex: keyof Item;
  record: Item;
  handleSave: (record: Item) => void;
}

const EditableCell: React.FC<EditableCellProps> = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef<InputRef>(null);
  const form = useContext(EditableContext)!;

  useEffect(() => {
    if (editing) {
      inputRef.current!.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({ [dataIndex]: record[dataIndex] });
  };

  const save = async () => {
    try {
      const values = await form.validateFields();

      toggleEdit();
      handleSave({ ...record, ...values });
    } catch (errInfo) {
      console.log("Save failed:", errInfo);
    }
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{ margin: 0 }}
        name={dataIndex}
        rules={[
          {
            required: true,
            message: `${title} is required.`,
          },
        ]}
      >
        <Input ref={inputRef} onPressEnter={save} onBlur={save} />
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{ paddingRight: 24 }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

type EditableTableProps = Parameters<typeof Table>[0];

interface DataType {
  key: React.Key;
  column_name: string;
  data_type: string;
  column_desc: string;
}

type ColumnTypes = Exclude<EditableTableProps["columns"], undefined>;

const ColumnModal: any = ({
  selectedProject,
  selectedDatasetKey,
  columnData,
  handleColumnDescription,
}: any) => {
  const [dataSource, setDataSource] = useState<DataType[]>([]);

  const [enableSave, setEnableSave] = useState<boolean>(false);


  useEffect(() => {
    setDataSource(columnData);
  }, [columnData]);

  const defaultColumns: (ColumnTypes[number] & {
    editable?: boolean;
    dataIndex: string;
  })[] = [
    {
      title: "Column Name",
      dataIndex: "column_name",
      width: "30%",
    },
    {
      title: "Data type",
      dataIndex: "data_type",
    },
    {
      title: "Column Description",
      dataIndex: "column_desc",
      editable: true,
    },
  ];

  const handleSave = (row: any) => {
    // const updatedRowValue = {
    //   [row.column_name]: row.column_desc,
    // };
    // setUpdatedRow((prev: any) => ({ ...prev, ...updatedRowValue }));
    const newData = [...dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    setEnableSave(true);
    setDataSource(newData);
  };

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: DataType) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });

  const onSaveDescriptionData = () => {
    // const newData = dataSource.map((item) => {
    //   return {
    //     column_name: item.column_name,
    //     data_type: item.data_type,
    //     column_desc: item.column_desc,
    //   };
    // });
    const newData = dataSource.map((item) => {
      return {
        [item.column_name]: item.column_desc,
      };
    });
    const updatedData = Object.assign({}, ...newData);
    handleColumnDescription(updatedData);
    setEnableSave(false);
  };

  return (
    <div>
      <Table
        components={components}
        rowClassName={() => "editable-row"}
        bordered
        dataSource={dataSource}
        columns={columns as ColumnTypes}
        className="columnmodal-table"
      />
      {!isEmpty(columnData) ? (
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            type="primary"
            disabled={!enableSave}
            onClick={onSaveDescriptionData}
          >
            Save Data
          </Button>
        </div>
      ) : null}
    </div>
  );
};

export default ColumnModal;
