import React, { useEffect, useState } from "react";
// import useMapDatasets from "../hooks/datasetmaps";
import { COLOR_RANGE } from "../constants/colorRange";
import { scaleOrdinal, scaleQuantile, scaleQuantize } from "d3-scale";
import { Descriptions, Space, Typography } from "antd";
//@ts-ignore
import { extent, map } from "d3-array";
import { compact, isArray } from "lodash";
import { RGBToHex } from "../functions/colorConversion";

const LegendColor = ({
  mapDataset,
  colorBasedOn,
  color,
  isFillColor,
  layerId,
  colorScale,
  conditionEnabled,
  condition,
  nullConfig,
}: any) => {
  // const { mapDataset } = useMapDatasets();
  const [fieldDataType, setFieldDataType] = useState<string>("");
  const [colorRange, setColorRange] = useState<any>();
  const [rowValueRangeBasedOnColor, setRowValueRangeBasedOnColor] =
    useState<any>();
  const [stringColorValue, setStringColorValue] = useState<any>();
  const booleanValueRange = ["false", "true"];

  useEffect(() => {
    const dataTypeOfField = mapDataset[layerId]?.value.fields?.find(
      (field: any) => field.id === colorBasedOn
    )?.type;

    setFieldDataType(dataTypeOfField);
  }, [colorBasedOn, layerId, mapDataset]);

  useEffect(() => {
    // @ts-ignore
    const colorRange = isArray(color) ? color : COLOR_RANGE[color];
    setColorRange(colorRange);
  }, [color]);

  useEffect(() => {
    if (
      fieldDataType !== "string" &&
      fieldDataType !== "geometry" &&
      fieldDataType !== "boolean" &&
      colorRange
    ) {
      const [minimum, maximum] = extent(
        compact(
          map(mapDataset[layerId]?.value.rows, (row: any) =>
            nullConfig && row[colorBasedOn] === nullConfig.value
              ? undefined
              : row[colorBasedOn]
          )
        )
      );

      if (colorScale === "quantile") {
        // eslint-disable-next-line
        const rowValueRange = Array();
        const colorScale = scaleQuantile()
          .domain(
            mapDataset[layerId]?.value.rows.map((row: any) =>
              nullConfig && row[colorBasedOn] === nullConfig.value
                ? undefined
                : row[colorBasedOn]
            )
          )
          .range(
            colorRange.map(
              (range: any) => `rgb(${range[0]}, ${range[1]}, ${range[2]})`
            )
          )
          .unknown("black");

        colorRange.forEach((range: any, index: number) => {
          const a = `rgb(${range[0]}, ${range[1]}, ${range[2]})`;
          // @ts-ignore
          const colorValueRange = colorScale.invertExtent(a);
          rowValueRange[index] = colorValueRange[0];
          rowValueRange[index + 1] = colorValueRange[1];
        });
        setRowValueRangeBasedOnColor(rowValueRange);
      } else {
        // eslint-disable-next-line
        const rowValueRange = Array();
        const colorScale = scaleQuantize()
          .domain([minimum, maximum])
          .range(
            colorRange.map(
              (range: any) => `rgb(${range[0]}, ${range[1]}, ${range[2]})`
            )
          )
          .unknown("black");
        colorRange.forEach((range: any, index: number) => {
          const a = `rgb(${range[0]}, ${range[1]}, ${range[2]})`;
          // @ts-ignore
          const colorValueRange = colorScale.invertExtent(a);
          rowValueRange[index] = colorValueRange[0];
          rowValueRange[index + 1] = colorValueRange[1];
        });
        setRowValueRangeBasedOnColor(rowValueRange);
      }
    }
    // eslint-disable-next-line
  }, [colorBasedOn, fieldDataType, layerId, colorRange, colorScale]);

  useEffect(() => {
    if (fieldDataType === "string") {
      const colorDomain =
        conditionEnabled && condition?.fieldNames.includes(colorBasedOn)
          ? condition?.values
          : compact(
              mapDataset[layerId]?.value.rows.map((row: any) =>
                nullConfig && nullConfig.value === row[colorBasedOn]
                  ? undefined
                  : row[colorBasedOn]
              )
            );
      const colorScale = scaleOrdinal()
        .domain(colorDomain)
        .range(
          colorRange.map(
            (range: any) => `rgb(${range[0]}, ${range[1]}, ${range[2]})`
          )
        );
      const newStringColorRange = colorRange.reduce((acc: any, range: any) => {
        acc[`rgb(${range[0]}, ${range[1]}, ${range[2]})`] = [];
        return acc;
      }, {});
      mapDataset[layerId]?.value.rows?.forEach((row: any) => {
        // @ts-ignore
        if (!newStringColorRange[colorScale(row[colorBasedOn])]) {
          // @ts-ignore
          newStringColorRange[colorScale(row[colorBasedOn])] = [];
        }
        if (
          // @ts-ignore
          !newStringColorRange[colorScale(row[colorBasedOn])].includes(
            row[colorBasedOn]
          )
        ) {
          // @ts-ignore
          newStringColorRange[colorScale(row[colorBasedOn])]?.push(
            row[colorBasedOn]
          );
        }
      });
      // @ts-ignore
      setStringColorValue(newStringColorRange);
    }
    // eslint-disable-next-line
  }, [
    colorBasedOn,
    colorRange,
    fieldDataType,
    layerId,
    condition,
    conditionEnabled,
  ]);

  return (
    <div style={{ display: "flex", gap: 8, flexDirection: "column" }}>
      <Descriptions layout="vertical">
        <Descriptions.Item
          label={
            <Typography.Text>
              {isFillColor
                ? "Fill colour determined by"
                : "Stroke colour determined by"}
            </Typography.Text>
          }
          className="legend-items"
        >
          <Typography.Text
            style={{ marginBottom: "0", color: "var(--default-grey)" }}
          >
            {colorBasedOn}
          </Typography.Text>
        </Descriptions.Item>
      </Descriptions>

      <div
        style={{
          maxHeight: "250px",
          maxWidth: "300px",
          overflowY: "scroll",
        }}
      >
        {nullConfig && (
          <Space>
            <div
              style={{
                width: 30,
                height: 20,
                backgroundColor: nullConfig
                  ? RGBToHex(nullConfig.color)
                  : "black",
              }}
            />
            Undefined or Null
          </Space>
        )}
        {fieldDataType === "string" && stringColorValue
          ? Object.keys(stringColorValue)?.map((color: any, index: number) => (
              <div
                key={index}
                style={{
                  display: "flex",
                  width: "fit-content",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    backgroundColor: color,
                    display: "flex",
                    width: "30px",
                    height: "20px",
                  }}
                ></div>
                <p
                  style={{
                    paddingLeft: "5px",
                    margin: 2,
                    overflow: "scroll",
                    whiteSpace: "nowrap",
                  }}
                >
                  [
                  {stringColorValue[color]?.map((value: any, index: number) => {
                    return (
                      <React.Fragment key={index}>
                        {value}
                        {index !== stringColorValue[color].length - 1 && ", "}
                      </React.Fragment>
                    );
                  })}
                  ]
                </p>
              </div>
            ))
          : fieldDataType === "boolean"
          ? booleanValueRange.map((value: string, index: number) => (
              <div
                key={index}
                style={{
                  display: "flex",
                  width: "fit-content",
                  maxHeight: "150px",
                  overflow: "scroll",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    backgroundColor: `rgb(${colorRange[index][0]},${colorRange[index][1]},${colorRange[index][2]})`,
                    display: "flex",
                    width: "30px",
                    height: "20px",
                  }}
                />
                <p style={{ paddingLeft: "5px", margin: 2 }}>{value}</p>
              </div>
            ))
          : colorRange?.map((color: any, index: number) => (
              <div
                key={index}
                style={{
                  display: "flex",
                  width: "fit-content",
                  maxHeight: "150px",
                  overflow: "scroll",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    backgroundColor: `rgb(${color[0]},${color[1]},${color[2]})`,
                    display: "flex",
                    width: "30px",
                    height: "20px",
                  }}
                />
                <p style={{ paddingLeft: "5px", margin: 2 }}>
                  {rowValueRangeBasedOnColor &&
                    (index < rowValueRangeBasedOnColor.length
                      ? rowValueRangeBasedOnColor[index]?.toFixed(2)
                      : rowValueRangeBasedOnColor[
                          rowValueRangeBasedOnColor.length - 1
                        ]?.toFixed(2))}{" "}
                  -{" "}
                  {rowValueRangeBasedOnColor &&
                    (index < rowValueRangeBasedOnColor.length - 2
                      ? rowValueRangeBasedOnColor[index + 1]?.toFixed(2)
                      : rowValueRangeBasedOnColor[
                          rowValueRangeBasedOnColor.length - 1
                        ]?.toFixed(2))}
                </p>
              </div>
            ))}
      </div>
    </div>
  );
};

export default LegendColor;
