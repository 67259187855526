import { Alert, Button, Dropdown, Menu, Space } from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import useUpdateConfig from "../../../hooks/useUpdateConfig";
import "../../components.css";

const strokeScale = ["linear", "sqrt", "log"];
const colorScale = ["quantile", "quantize", "ordinal"];
//const radiusUnits = ["meters", "pixels", "common"];

const SelectScale = (props: any) => {
  const { name, id } = props;
  const configlayers = useSelector((state: any) => state.deckgl.configlayers);
  const [selectedScale, setSelectedScale] = useState<string>("");
  const [fields] = useState(
    name === "Fill Color" || name === "Stroke Color" ? colorScale : strokeScale
  );

  const { updateConfig } = useUpdateConfig();

  useEffect(() => {
    const assignDefaultValues = (name: string) => {
      switch (name) {
        case "Fill Color":
          setSelectedScale(configlayers[id].colorScale);
          return null;
        case "Stroke Color":
          setSelectedScale(configlayers[id].strokeColorScale);
          return null;
        case "Stroke Width":
          setSelectedScale(configlayers[id].strokeWidthScale);
          return null;
        case "Height":
          setSelectedScale(configlayers[id].heightScale);
          return null;
      }
    };
    assignDefaultValues(name);
    // eslint-disable-next-line
  }, [configlayers[id]]);

  const handleSelect = (value: string) => {
    setSelectedScale(value);
    if (name === "Fill Color") updateConfig(id, "colorScale", value);
    else if (name === "Stroke Color")
      updateConfig(id, "strokeColorScale", value);
    else if (name === "Stroke Width")
      updateConfig(id, "strokeWidthScale", value);
    else if (name === "Height") updateConfig(id, "heightScale", value);
  };

  const overlay = (
    <Menu className="select-fields">
      {fields &&
        fields.map((data: any, j: number) => (
          <Menu.Item onClick={() => handleSelect(data)} key={j}>
            {data}
          </Menu.Item>
        ))}
    </Menu>
  );

  return (
    <Space direction="vertical" style={{ width: "100%" }}>
      <Alert
        type="info"
        message='Choose "Ordinal" scale if you are applying conditions or the values need unique colors'
      />
      <Dropdown
        overlay={overlay}
        trigger={["click"]}
        getPopupContainer={(): any => document.querySelector(".layer")}
      >
        <Button
          block={true}
          style={{
            backgroundColor: "lightgrey",
            textAlign: "left",
            border: 0,
            width: "100%",
            height: 40,
            color: "black",
            fontSize: 14,
          }}
        >
          {selectedScale}
        </Button>
      </Dropdown>
    </Space>
  );
};

export default SelectScale;
