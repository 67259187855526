import { Tree } from "antd";
import type { DataNode, TreeProps } from "antd/es/tree";
import React from "react";

const { DirectoryTree } = Tree;


const DraggableTree = ({ categories, setCategories }: any) => {
  const [treeData, setTreeData] = React.useState(categories);

  // Function to change isLeaf property of a node to false when children is not empty
  const changeIsLeaf = (data: DataNode[]) => {
    data.forEach((node) => {
      if (node.children && node.children.length > 0) {
        node.isLeaf = false;
        changeIsLeaf(node.children);
      } else {
        node.isLeaf = true;
      }
    });
  };

  // UseEffect to change isLeaf property of a node to false when children is not empty
  React.useEffect(() => {
    changeIsLeaf(treeData);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [treeData]);


  const onDrop: TreeProps["onDrop"] = (info) => {
    console.log(info);
    const dropKey = info.node.key;
    const dragKey = info.dragNode.key;
    const dropPos = info.node.pos.split("-");
    const dropPosition =
      info.dropPosition - Number(dropPos[dropPos.length - 1]);

    const loop = (
      data: DataNode[],
      key: React.Key,
      callback: (node: DataNode, i: number, data: DataNode[]) => void
    ) => {
      for (let i = 0; i < data.length; i++) {
        if (data[i].key === key) {
          return callback(data[i], i, data);
        }
        if (data[i].children) {
          loop(data[i].children!, key, callback);
        }
      }
    };
    const data = [...treeData];

    // Find dragObject
    let dragObj: DataNode;
    loop(data, dragKey, (item, index, arr) => {
      arr.splice(index, 1);
      dragObj = item;
      console.log(dragObj)
    });

    if (!info.dropToGap) {
      // Drop on the content
      loop(data, dropKey, (item) => {
        item.children = item.children || [];
        // where to insert 示例添加到头部，可以是随意位置
        item.children.unshift(dragObj);
      });
    } else if (
      ((info.node as any).props.children || []).length > 0 && // Has children
      (info.node as any).props.expanded && // Is expanded
      dropPosition === 1 // On the bottom gap
    ) {
      loop(data, dropKey, (item) => {
        item.children = item.children || [];
        // where to insert 示例添加到头部，可以是随意位置
        item.children.unshift(dragObj);
        // in previous version, we use item.children.push(dragObj) to insert the
        // item to the tail of the children
      });
    } else {
      let ar: DataNode[] = [];
      let i: number;
      loop(data, dropKey, (_item, index, arr) => {
        ar = arr;
        i = index;
      });
      if (dropPosition === -1) {
        ar.splice(i!, 0, dragObj!);
      } else {
        ar.splice(i! + 1, 0, dragObj!);
      }
    }
    console.log(data);
    setTreeData(data);
    setCategories(data);
  };
  console.log(treeData)
  return (
    <>
      <DirectoryTree
        multiple
        //   defaultExpandAll
        //   onSelect={onSelect}
        //   onExpand={onExpand}
        treeData={treeData}
        className="updated-directory-tree"
        // showLine={{ showLeafIcon: false }}
        showLine={true}
        showIcon={true}
        draggable={true}
        onDrop={onDrop}
      />
    </>
  );
};

export default DraggableTree;




