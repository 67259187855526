import { CloseOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, Dropdown, Input, Menu, Space, Tag, Typography } from "antd";
import { isEmpty } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getFieldValues } from "../functions/layerProperties";
import useUpdateConfig from "../hooks/useUpdateConfig";
import { updateFilterFieldData } from "../models/deckgl";

const SelectFilterValue = ({
  type,
  minmax,
  dataset,
  filterObj,
  selectedField,
}: any) => {
  const { datasetId, fieldId } = filterObj;
  const { filters } = useSelector((state: any) => state.deckgl);
  const [search, setSearch] = useState<string>("");
  const [selectedValues, setSelectedValues] = useState<string[]>([]);
  const { updateConfigObject } = useUpdateConfig();
  const dispatch = useDispatch();

  const fieldValues = useMemo(() => {
    const result = getFieldValues(dataset, selectedField);
    return result;
  }, [dataset, selectedField]);

  const handleSelect = (data: any) => {
    if (type === "boolean") {
      dispatchUpdates([data]);
    } else {
      const values = [...selectedValues, data];
      dispatchUpdates(values);
    }
  };

  const handleClose = (val: string) => {
    const values = selectedValues.filter((a) => a !== val);
    dispatchUpdates(values);
  };
  const dispatchUpdates = (values: any) => {
    setSelectedValues(values);
    dispatch(
      updateFilterFieldData({
        datasetId,
        fieldId,
        value: selectedField,
        values,
        range: minmax,
        minmax: minmax,
      })
    );
    makeFilterObject(datasetId, fieldId, selectedField, values);
  };
  const makeFilterObject = (
    datasetId: string,
    fieldId: string,
    value: string,
    values: string[]
  ) => {
    const newObj = filters[datasetId];
    const res = newObj.map((item: any) =>
      item.fieldId === fieldId
        ? {
            fieldId,
            data: { fieldBasedOn: value, values: values, range: [0, 1] },
          }
        : item
    );
    updateConfigObject(datasetId, { filterConfig: res, filterEnabled: true });
  };

  useEffect(() => {
    const values = filters[datasetId].filter(
      (item: any) => item.fieldId === fieldId
    )[0].data.values;
    if (!isEmpty(values)) {
      setSelectedValues(values);
    } else setSelectedValues([]);
    // eslint-disable-next-line
  }, []);

  const overlay = (
    <Menu className="select-fields">
      <Menu.Item style={{ pointerEvents: "inherit" }} key="search" disabled>
        <Input
          placeholder="search value.."
          suffix={<SearchOutlined />}
          style={{
            border: 0,
          }}
          value={search}
          onChange={(val) => setSearch(val.target.value)}
        />
      </Menu.Item>
      {!isEmpty(fieldValues) &&
        fieldValues
          .filter(
            (item: any) => selectedValues && !selectedValues.includes(item)
          )
          .filter((item: any) =>
            item.toString().toLowerCase().includes(search.toLowerCase())
          )
          .map((data: any, j: number) => (
            <Menu.Item onClick={() => handleSelect(data)} key={j}>
              <Space
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                }}
              >
                <Typography.Text
                  style={{ width: 235, color: "rgb(103,116,133)" }}
                  ellipsis
                >
                  {data.toString()}
                </Typography.Text>
              </Space>
            </Menu.Item>
          ))}
    </Menu>
  );

  return (
    <Space direction="vertical" style={{ width: "100%" }}>
      <Typography.Text style={{ color: "rgb(103,116,133)" }}>
        {type === "string" ? "Value In" : "Value Equals"}
      </Typography.Text>
      <Dropdown
        overlay={overlay}
        trigger={["click"]}
        placement="bottom"
        getPopupContainer={(): any => document.querySelector(".filters")}
      >
        <Button
          block
          style={{
            // marginTop: 0,
            backgroundColor: "lightgrey",
            textAlign: "left",
            border: 0,
            height: "100%",
            color: "rgb(106, 116, 133)",
            fontSize: 14,
            display: "flex",
            flexWrap: "wrap",
            gap: 8,
            padding: 8,
          }}
        >
          {!isEmpty(selectedValues)
            ? selectedValues.map((item) => (
                <Tag
                  key={item}
                  style={{
                    backgroundColor: "#262a33",
                    border: "1px solid rgb(103,116,133)",
                    color: "rgb(103,116,133)",
                    fontSize: 16,
                  }}
                  closeIcon={
                    <CloseOutlined style={{ color: "rgb(103,116,133)" }} />
                  }
                  closable
                  onClose={() => handleClose(item)}
                >
                  <Typography.Text
                    style={{ color: "rgb(103,116,133)", maxWidth: 125 }}
                    ellipsis
                  >
                    {item}
                  </Typography.Text>
                </Tag>
              ))
            : "select value.."}
        </Button>
      </Dropdown>
    </Space>
  );
};

export default SelectFilterValue;
