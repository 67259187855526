import firebase from "firebase/app";
import "firebase/firestore";

// import firebase config
import {
  leoDevfirebaseConfig,
  leoProdfirebaseConfig,
  resopDevfirebaseConfig,
  pkcDevfirebaseConfig,
  wmcaDevfirebaseConfig,
  ukpnDevfirebaseConfig,
} from "../utils/firebase.config";

// initialize firebase

const leoFbDev = firebase
  .initializeApp(leoDevfirebaseConfig, "aitl-lear-dev")
  .firestore();
const leoFbProd = firebase
  .initializeApp(leoProdfirebaseConfig, "aitl-lear-prod")
  .firestore();
const resopFb = firebase
  .initializeApp(resopDevfirebaseConfig, "resop-dev")
  .firestore();
const pkcFbDev = firebase
  .initializeApp(pkcDevfirebaseConfig, "pkc-dev")
  .firestore();
const wmcaFbDev = firebase
  .initializeApp(wmcaDevfirebaseConfig, "wmca-dev")
  .firestore();
const ukpnFbDev = firebase
  .initializeApp(ukpnDevfirebaseConfig, "ukpn-dev")
  .firestore();

export const chooseDb = (id: string) => {
  switch (id) {
    case "aitl-lear":
      return { dev: leoFbDev, prod: leoFbProd };
    case "resop":
      return { dev: resopFb, prod: resopFb };
    case "pkc":
      return { dev: pkcFbDev, prod: pkcFbDev };
    case "wmca":
      return { dev: wmcaFbDev, prod: wmcaFbDev };
    case "ukpn":
      return { dev: ukpnFbDev, prod: ukpnFbDev };
    default:
      return { dev: ukpnFbDev, prod: ukpnFbDev };
  }
};
