import {
  DeleteOutlined,
  FolderOpenOutlined,
  FolderOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { Space, Typography } from "antd";
import { useState } from "react";

const DirectoryTree = ({
  item,
  itemKey,
  onAddCategory,
  onRemoveCategory,
  isChild = false,
  parentKey = null,
}: any) => {
  const [enabled, setEnabled] = useState(false);

  const checkEle = (e: any) => {
    return e.target.nodeName === "H5" ? true : false;
  };

  if (!item.isLeaf) {
    return (
      <>
        <Typography.Title
          level={5}
          className={`tree-element ${isChild ? "switcher-element" : ""}`}
          ellipsis
          onClick={(e: any) => checkEle(e) && setEnabled(!enabled)}
        >
          {enabled ? <FolderOpenOutlined /> : <FolderOutlined />} {item.title}
          <Space className="action-buttons">
            <PlusOutlined onClick={() => onAddCategory(item.key)} />
            <DeleteOutlined
              onClick={() => onRemoveCategory(parentKey, item.key)}
            />
          </Space>
        </Typography.Title>

        {item.children.map((item: any) => {
          return (
            <div
              key={item.key}
              style={{ paddingLeft: 12, display: enabled ? "block" : "none" }}
            >
              <DirectoryTree
                key={item.key}
                item={item}
                itemKey={item.key}
                onAddCategory={onAddCategory}
                onRemoveCategory={onRemoveCategory}
                isChild={true}
                parentKey={itemKey}
              />
            </div>
          );
        })}
      </>
    );
  } else
    return (
      <Typography.Paragraph
        style={{ paddingLeft: 12, position: "relative" }}
        className="tree-element switcher-element"
        ellipsis
      >
        {item.title}
        <Space className="action-buttons">
          <DeleteOutlined
            onClick={() => onRemoveCategory(parentKey, item.key)}
          />
        </Space>
      </Typography.Paragraph>
    );
};

export default DirectoryTree;
