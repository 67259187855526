import "./components.css";
import { Button, Dropdown, Input, Menu, Space, Typography } from "antd";
import { difference, lowerCase } from "lodash";
import { useDispatch } from "react-redux";
import { PlusOutlined, SearchOutlined } from "@ant-design/icons";
import { useState } from "react";
import { addFilter } from "../models/deckgl";

const AddFilter = ({ mapData, filters }: any) => {
  const [datasetSearch, setDatasetSearch] = useState<string>("");
  const dispatch = useDispatch();

  return (
    <Dropdown
      trigger={["click"]}
      overlay={
        <Menu className="select-fields filter" title="">
          <Menu.Item key="title">
            <p style={{ margin: 0, fontWeight: 600 }}>
              Select one of these dataset to apply filter
            </p>
          </Menu.Item>
          <Menu.Item style={{ pointerEvents: "inherit" }} key="search" disabled>
            <Input
              placeholder="search dataset..."
              suffix={
                <SearchOutlined style={{ color: "var(--default-grey)" }} />
              }
              style={{
                border: 0,
              }}
              value={datasetSearch}
              onChange={(val) => setDatasetSearch(val.target.value)}
            />
          </Menu.Item>
          {difference(Object.keys(mapData), Object.keys(filters))
            .filter((item: any) =>
              lowerCase(mapData[item].name).includes(lowerCase(datasetSearch))
            )
            .map((id: any, j: number) => (
              <Menu.Item
                onClick={() => {
                  setDatasetSearch("");
                  dispatch(addFilter(id));
                }}
                key={id}
              >
                <Space
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "center",
                  }}
                >
                  <Typography.Text
                    ellipsis
                    style={{ width: 265, color: "var(--default-grey)" }}
                  >
                    {mapData[id].name}
                  </Typography.Text>
                </Space>
              </Menu.Item>
            ))}
        </Menu>
      }
    >
      <Button
        block
        type="primary"
        icon={<PlusOutlined />}
        style={{
          backgroundColor: "rgb(15, 150, 104)",
          color: "rgb(255, 255, 255)",
        }}
        onClick={() => null}
      >
        Add Filter
      </Button>
    </Dropdown>
  );
};
export default AddFilter;
