import "../../components.css";
import { MoreOutlined } from "@ant-design/icons";
import {
  Collapse,
  InputNumber,
  Slider,
  Space,
  Switch,
  Tooltip,
  Typography,
} from "antd";
import { useEffect, useState } from "react";
import SelectField from "./SelectField";
import SelectScale from "./SelectScale";
import { useSelector } from "react-redux";
import useUpdateConfig from "../../../hooks/useUpdateConfig";

const { Panel } = Collapse;

const SliderComponent = (props: any) => {
  const {
    id,
    name,
    dataset: { fields, type },
  } = props;

  const {
    deckgl: { configlayers },
  } = useSelector((state: any) => state);

  const [sliderValue, setSliderValue] = useState(0);
  const [scaleVisible, setScaleVisible] = useState(false);
  const [enabled, setEnabled] = useState<boolean>(false);
  const { updateConfig, updateConfigObject } = useUpdateConfig();

  function onChange(value: any) {
    setSliderValue(value);
    if (name === "Opacity") {
      updateConfig(id, "opacity", value);
    } else if (name === "Radius") {
      type === "Point"
        ? updateConfig(id, "radiusScale", value)
        : updateConfig(id, "pointRadiusScale", value);
    } else if (name === "Stroke Width") {
      updateConfig(id, "lineWidthScale", value / 10);
    } else {
      updateConfig(id, "elevationScale", value);
    }
  }

  function handleEnable(value: boolean) {
    setEnabled(value);
    if (name === "Stroke Width") {
      updateConfig(id, "stroked", value);
    } else {
      updateConfigObject(id, { extruded: value, wireframe: value });
    }
  }

  const checkExpand = (e: any) => {
    if (e.target.id === "layer-header" || e.target.nodeName === "svg") return;
    e.stopPropagation();
  };

  useEffect(() => {
    const assignDefaultValue = (name: string) => {
      switch (name) {
        case "Radius":
          const radius =
            typeof configlayers[id].radiusScale === "string"
              ? 10
              : configlayers[id].radiusScale;
          setSliderValue(configlayers[id].pointRadiusScale || radius);
          return null;
        case "Opacity":
          setSliderValue(configlayers[id].opacity);
          return null;
        case "Stroke Width":
          setEnabled(configlayers[id].stroked);
          setSliderValue(configlayers[id].lineWidthScale);
          return null;
        case "Height":
          setEnabled(configlayers[id].extruded);
          setSliderValue(configlayers[id].elevationScale);
      }
    };
    assignDefaultValue(name);
    // eslint-disable-next-line
  }, [configlayers[id]]);

  return (
    <Collapse
      className={`layer-properties slider-component ${
        !enabled && name !== "Opacity" && name !== "Radius"
          ? "layer-properties-overlay"
          : ""
      }`}
      ghost
    >
      <Panel
        className="secondary"
        key={name}
        header={
          <Space
            id="default"
            direction="vertical"
            style={{ width: "100%" }}
            onClick={(e) => checkExpand(e)}
          >
            <Space
              id="layer-header"
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography.Text className="config-name">{name}</Typography.Text>
              {name !== "Opacity" ? (
                <Space>
                  {name === "Height" || name === "Stroke Width" ? (
                    <Tooltip
                      title={enabled ? "Disable" : "Enable"}
                      getTooltipContainer={(): any =>
                        document.querySelector(".layer-properties")
                      }
                    >
                      <Switch
                        size="small"
                        checked={enabled}
                        onChange={(val) => handleEnable(val)}
                      />
                    </Tooltip>
                  ) : null}

                  <Tooltip
                    title="More"
                    getTooltipContainer={(): any =>
                      document.querySelector(".layer-properties")
                    }
                  >
                    <MoreOutlined className="more-icon" id="expand" />
                  </Tooltip>
                </Space>
              ) : null}
            </Space>
            <Space
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Slider
                step={name === "Opacity" ? 0.1 : 1}
                min={0}
                max={name === "Opacity" ? 1 : 100}
                value={name === "Stroke Width" ? sliderValue * 10 : sliderValue}
                onChange={(value) => onChange(value)}
                style={{ width: 150 }}
                handleStyle={{ borderRadius: 0 }}
              />
              <InputNumber
                step={name === "Opacity" ? 0.1 : 1}
                min={0}
                max={name === "Opacity" ? 1 : 100}
                value={name === "Stroke Width" ? sliderValue * 10 : sliderValue}
                onChange={(value) => onChange(value)}
                style={{ width: 50, borderRadius: 0 }}
              />
            </Space>
          </Space>
        }
        collapsible={name !== "Opacity" ? "header" : "disabled"}
        showArrow={false}
      >
        {name !== "Opacity" ? (
          <Space style={{ width: "100%" }} direction="vertical">
            <Typography.Text className="label">{name} based on</Typography.Text>
            <SelectField
              associatedName={name}
              fields={fields}
              setScaleVisible={setScaleVisible}
              id={id}
            />
            {scaleVisible && (
              <>
                <Typography.Text className="label">
                  {name} scale
                </Typography.Text>
                <SelectScale name={name} id={id} />
              </>
            )}
          </Space>
        ) : null}
      </Panel>
    </Collapse>
  );
};

export default SliderComponent;
