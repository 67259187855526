import { MoreOutlined } from "@ant-design/icons";
import { Collapse, Space, Switch, Tooltip, Typography } from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import useUpdateConfig from "../../../hooks/useUpdateConfig";
import ChooseConditionFields from "./ChooseConditionFields";

const { Panel } = Collapse;

const ConditionComponent = (props: any) => {
  const {
    id,
    name,
    dataset: { fields, rows },
  } = props;
  const configlayers = useSelector((state: any) => state.deckgl.configlayers);

  const [conditionEnabled, setConditionEnabled] = useState<boolean>(false);
  const { updateConfig } = useUpdateConfig();

  function handleEnable(value: boolean) {
    setConditionEnabled(value);
    if (name === "Fill") updateConfig(id, "fillConditionEnabled", value);
    else updateConfig(id, "strokeConditionEnabled", value);
  }

  const checkExpand = (e: any) => {
    if (e.target.id === "layer-header" || e.target.nodeName === "svg") return;
    e.stopPropagation();
  };

  useEffect(() => {
    if (name === "Fill") {
      setConditionEnabled(configlayers[id]?.fillConditionEnabled);
    } else {
      setConditionEnabled(configlayers[id]?.strokeConditionEnabled);
    }
    // eslint-disable-next-line
  }, [configlayers[id]]);

  return (
    <Collapse
      className={`layer-properties slider-component ${
        !conditionEnabled ? "layer-properties-overlay" : ""
      }`}
      ghost
    >
      <Panel
        className="secondary"
        key={name}
        header={
          <Space
            id="default"
            direction="vertical"
            style={{ width: "100%" }}
            onClick={(e) => checkExpand(e)}
          >
            <Space
              id="layer-header"
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography.Text className="config-name">
                {name} Condition
              </Typography.Text>

              <Space>
                <Tooltip
                  title={conditionEnabled ? "Disable" : "Enable"}
                  getTooltipContainer={(): any =>
                    document.querySelector(".layer-properties")
                  }
                >
                  <Switch
                    size="small"
                    checked={conditionEnabled}
                    onChange={(val) => handleEnable(val)}
                  />
                </Tooltip>

                <Tooltip
                  title="More"
                  getTooltipContainer={(): any =>
                    document.querySelector(".layer-properties")
                  }
                >
                  <MoreOutlined className="more-icon" id="expand" />
                </Tooltip>
              </Space>
            </Space>
          </Space>
        }
        collapsible="header"
        showArrow={false}
      >
        <Space style={{ width: "100%" }} direction="vertical">
          <Typography.Text className="label">
            {name} color based on
          </Typography.Text>
          <ChooseConditionFields
            id={id}
            fields={fields}
            rows={rows}
            config={configlayers[id]}
            name={name}
          />
        </Space>
      </Panel>
    </Collapse>
  );
};

export default ConditionComponent;
