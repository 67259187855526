import { geoConfig } from "../constants/layerdefaultconfig";
import { pointConfig } from "../constants/layerdefaultconfig";

export const selectConfig = (datasetType: string) => {
  switch (datasetType) {
    case "FeatureCollections":
    case "CsvCollections":
      return { ...geoConfig };
    case "Point":
      return { ...pointConfig };
  }
};
