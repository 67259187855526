import "./components.css";
import { useEffect, useMemo, useState } from "react";
import {
  Alert,
  Button,
  Card,
  Divider,
  Form,
  Input,
  Modal,
  notification,
  Select,
  // Slider,
  Space,
  Tooltip,
  Typography,
} from "antd";
import UploadFile from "./UploadFile";
import { processFieldsAndRows, processRow } from "../functions/dataProcessor";
//@ts-ignore
import { selectConfig } from "../functions/selectConfig";
import { batch, useDispatch, useSelector } from "react-redux";
import {
  saveConfigLayers,
  setDataTableActiveKey,
  setDataTableVisible,
  updatemetadata,
} from "../models/deckgl";
import Layer from "./layers/Layer";
import { isEmpty } from "lodash";
import DataTable from "./DataTable";
import Filter from "./Filter";
import AddFilter from "./AddFilter";
import { ExclamationCircleFilled, PlusOutlined } from "@ant-design/icons";
import CategoryModal from "./CategoryModal";
// import { SliderMarks } from "antd/lib/slider";
import axios from "axios";
// import {
//   controlInitialState,
//   controlReducer,
// } from "../reducers/controlReducer";
// import { ControlActionTypes } from "../reducers/controlActionTypes";
import { chooseDb } from "../services/firebase";
import ColumnModal from "./ColumnModal";
import {
  resetProjectData,
  saveColumnData,
  saveColumnList,
  saveDatasetFormKey,
  saveDatasetKey,
  saveProjectData,
  saveProjectName,
  saveRecordCount,
  saveTableName,
  saveTablesList,
  setSeletedDataColumn,
} from "../models/dashboardmodel";

const { Option } = Select;

export default function Control({ dataset, setDataset }: any) {
  const { filters, configlayers, metaData, categories } = useSelector(
    (state: any) => state.deckgl
  );
  const {
    projectData,
    tablesList,
    selectedProjectName,
    selectedTableName,
    // recordCount,
    selectedDatasetKey,
    datasetFormKey,
    columnData,
    columnList,
    selectedDataColumn,
  } = useSelector((state: any) => state.dashboardmodel);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [showCategoryModal, setShowCategoryModal] = useState(false);
  const [fsDb, setFsDb] = useState<string>("");
  const [loadmetaformRef] = Form.useForm();
  const [recordDataLoading, setRecordDataLoading] = useState(false);
  const [metaInformationLoading, setMetaInformationLoading] = useState(false);
  const { TextArea } = Input;
  const { confirm } = Modal;
  const [loadmodalformRef] = Form.useForm();
  const [loadDataModal, setLoadDataModal] = useState(false);
  const [metaInformationModal, setMetaInformationModal] = useState(false);
  const [columnDescriptionModal, setColumnDescriptionModal] = useState(false);

  const db = useMemo(() => {
    return chooseDb(fsDb);
  }, [fsDb]);

  useEffect(() => {
    if (selectedProjectName) {
      if (selectedProjectName === "pkc") {
        setFsDb("pkc");
      } else if (selectedProjectName === "dcc") {
        setFsDb("resop");
      } else if (selectedProjectName === "occ") {
        setFsDb("aitl-lear");
      } else if (selectedProjectName === "wmca") {
        setFsDb("wmca");
      } else if (["ukpn", "ukpn_pbh"].includes(selectedProjectName)) {
        setFsDb("ukpn");
      }
    }
  }, [selectedProjectName]);

  // const [url, setUrl] = useState("");
  // const [fileName, setFileName] = useState("");
  // const [info, setInfo] = useState({
  //   error: false,
  //   message: "",
  //   loading: false,
  // });
  const dispatch = useDispatch();

  // const marks: SliderMarks = {
  //   0: {
  //     style: {
  //       // color: '#f50',
  //       color: "black",
  //     },
  //     label: <strong>0</strong>,
  //   },
  //   [recordCount]: {
  //     style: {
  //       // color: '#f50',
  //       color: "black",
  //     },
  //     label: <strong>{recordCount}</strong>,
  //   },
  // };

  const processFile = async (file: any, name: string, datasetId: string) => {
    columnDataCall(selectedProjectName, datasetId);
    const processedDataset = processRow(processFieldsAndRows(file));
    const config = isEmpty(configlayers[datasetId])
      ? selectConfig(processedDataset.type)
      : configlayers[datasetId];

    dispatch(saveConfigLayers({ [datasetId]: config }));
    setDataset((prestate: any) => ({
      // ...prestate,
      [datasetId]: { id: datasetId, value: processedDataset, name },
    }));
    dispatch(resetProjectData());
    setLoadDataModal(false);
    loadmodalformRef.setFieldsValue({
      project: undefined,
      table: undefined,
      // records: undefined,
      column: undefined,
      columnValue: undefined,
    });
  };

  const handleViewData = (id: string) => {
    batch(() => {
      dispatch(setDataTableActiveKey(id));
      dispatch(setDataTableVisible(true));
    });
  };

  const handleLoadDataset = () => {
    setLoadDataModal(true);
  };

  const handleMetaInformationModal = async (value: boolean) => {
    setMetaInformationModal(value);
    if (value) {
      await db.dev
        .collection("static_datasets")
        .doc(selectedDatasetKey)
        .get()
        .then((doc: any) => {
          if (doc.exists) {
            loadmetaformRef.setFieldsValue({
              datasetname: doc.data().metadata.filename,
              datasetdescription: doc.data().metadata.description,
            });
          }
        });
    } else if (!value) {
      const datasetmetaInformation = await db.dev
        .collection("static_datasets")
        .doc(selectedDatasetKey)
        .get()
        .then((doc: any) => {
          if (doc.exists) {
            return doc.data().metadata;
          }
        });
      dispatch(
        updatemetadata({ [selectedDatasetKey]: datasetmetaInformation })
      );
    }
  };

  const handleColumnDescriptionModal = (value: boolean) => {
    setColumnDescriptionModal(value);
    if (value) {
      columnDataCall(selectedProjectName, selectedDatasetKey);
    }
  };

  const handleMetaInformation = async (value: any) => {
    setMetaInformationLoading(true);
    const docRef = db.dev.collection("static_datasets").doc(selectedDatasetKey);

    docRef
      .get()
      .then((docSnapshot) => {
        if (docSnapshot.exists) {
          docRef.update({
            "metadata.description": value.datasetdescription,
            "metadata.filename": value.datasetname,
          });
        } else {
          docRef.set({
            metadata: {
              description: value.datasetdescription,
              filename: value.datasetname,
            },
          });
        }
      })
      .then(() => {
        notification.config({
          placement: "topLeft",
        });
        notification.open({
          message: "Document successfully updated!",
        });
        handleMetaInformationModal(false);
        setMetaInformationLoading(false);
      });
    const rdsObj = {
      meta: {
        display_name: value.datasetname,
        description: value.datasetdescription,
      },
      dataset_id: selectedDatasetKey,
    };
    await axios
      .post(
        `https://9kzcw8mae2.execute-api.eu-west-2.amazonaws.com/dev/properties/meta?project=${selectedProjectName}`,
        rdsObj
      )
      .then(() => {
        notification.config({
          placement: "topLeft",
        });
        notification.open({
          message: "Document successfully written to RDS!",
        });
      });
  };

  // Column Description Modal Code -----------------------------------------------------------------------

  const columnDataCall = async (project: any, datasetKey: any) => {
    await axios
      .get(
        `https://9kzcw8mae2.execute-api.eu-west-2.amazonaws.com/dev/properties/columns?project=${project}&dataset_id=${datasetKey}`
      )
      .then((response) => {
        const updatedColumnData = response?.data?.data.map(
          (item: any, index: number) => {
            return {
              key: index,
              column_name: item.column_name,
              data_type: item.data_type,
              column_desc: item.column_desc,
            };
          }
        );
        dispatch(saveColumnData(updatedColumnData));
      })
      .catch((error) => {
        console.log("error", error);
        notification.error({
          message: "Error",
          description: "Error while fetching column data",
        });
        dispatch(saveColumnData([]));
      });
  };

  const handleColumnDescription = (value: any) => {
    const updatedColumnDataCall = async () => {
      await axios
        .post(
          `https://9kzcw8mae2.execute-api.eu-west-2.amazonaws.com/dev/properties/columns?project=${selectedProjectName}&dataset_id=${selectedDatasetKey}`,
          value
        )
        .then((response) => {
          notification.config({
            placement: "topLeft",
          });
          notification.open({
            message: "Document successfully updated!",
          });
          columnDataCall(selectedProjectName, selectedDatasetKey);
        })
        .catch((error) => {
          console.log("error", error);
          notification.error({
            message: "Error",
            description: "Error while updating column data",
          });
          columnDataCall(selectedProjectName, selectedDatasetKey);
        });
    };
    updatedColumnDataCall();
  };

  const showConfirm = () => {
    const productionObj = {
      dataset_id: selectedDatasetKey,
      DNO: selectedProjectName,
      export: {
        // categories_data: categories,
        layer_config: configlayers[selectedDatasetKey],
        meta_information: metaData[selectedDatasetKey],
        column_data: columnData,
        filter_data: isEmpty(filters[selectedDatasetKey])
          ? []
          : filters[selectedDatasetKey],
      },
    };
    console.log("productionObj", productionObj);
    confirm({
      title: "This will push updates to production.",
      icon: <ExclamationCircleFilled />,
      content: "Are you sure you want to continue?",
      onOk: async () => {
        await axios
          .post(
            `https://9kzcw8mae2.execute-api.eu-west-2.amazonaws.com/dev/upload/export?project=${selectedProjectName}`,
            productionObj
          )
          .then((response) => {
            notification.config({
              placement: "topLeft",
            });
            notification.open({
              message: "Document successfully updated!",
            });
            db.prod
              .collection("constants")
              .doc("categories")
              .set({ data: categories });
          })
          .catch((error) => {
            console.log("error", error);
            notification.error({
              message: "Error",
              description: "Error while updating data to production",
            });
          });
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  // Rework Code -----------------------------------------------------------------------

  const onFinishLoadModal = (values: any) => {
    const { project, column, table, columnValue } = values;
    setRecordDataLoading(true);
    axios
      .get(
        "https://9kzcw8mae2.execute-api.eu-west-2.amazonaws.com/dev/upload/records",
        {
          params: {
            project: project,
            table_name: table,
            columns: JSON.stringify(columnList),
            column_name: column,
            value_count: columnValue,
            // limit: records,
            // limit: 1000
          },
        }
      )
      .then((res) => {
        if (res.data.data !== 404) {
          setRecordDataLoading(false);
          dispatch(saveDatasetKey(datasetFormKey));
          db.dev
            .collection("static_datasets")
            .doc(datasetFormKey)
            .get()
            .then((doc: any) => {
              if (doc.exists) {
                const datasetmetaInformation = doc.data().metadata;
                const existingConfig = doc.data().config;
                dispatch(
                  updatemetadata({ [datasetFormKey]: datasetmetaInformation })
                );
                if (!isEmpty(existingConfig))
                  dispatch(
                    saveConfigLayers({ [datasetFormKey]: existingConfig })
                  );
              }
            });
          processFile(res.data.data, selectedTableName, datasetFormKey);
        } else {
          setRecordDataLoading(false);
          notification.error({
            message: "Error",
            description: "Error while fetching data",
          });
        }
      })
      .catch(() => {
        notification.error({
          message: "Please try again with fewer records",
        });
      })
      .finally(() => setRecordDataLoading(false));
  };

  useEffect(() => {
    axios
      .get(
        "https://9kzcw8mae2.execute-api.eu-west-2.amazonaws.com/dev/upload/projects"
      )
      .then(
        (res) => {
          dispatch(saveProjectData(res.data.data));
        },
        (err) => {
          notification.error({
            message: "Error",
            description: "Error loading project data",
          });
        }
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onProjectChange = (projectValue: any) => {
    if (projectValue) {
      dispatch(saveProjectName(projectValue));
      dispatch(resetProjectData());
      loadmodalformRef.setFieldsValue({
        table: undefined,
        column: undefined,
        columValue: undefined,
      });
      // loadmodalformRef.setFieldsValue({ table: undefined, records: undefined });
      axios
        .get(
          "https://9kzcw8mae2.execute-api.eu-west-2.amazonaws.com/dev/upload/tables",
          { params: { project: projectValue } }
        )
        .then((res: any) => {
          if (res.data.data.length > 0) {
            dispatch(saveTablesList(res.data.data));
          } else {
            dispatch(saveTablesList([]));
          }
        })
        .catch((err) => {
          notification.error({
            message: "Error",
            description: "Error loading tables list",
          });
        });
    }
  };

  const onTableChange = (tableValue: any) => {
    if (tableValue) {
      dispatch(saveTableName(tableValue));
      dispatch(saveRecordCount(0));
      loadmodalformRef.setFieldsValue({
        column: undefined,
        columnValue: undefined,
      });
      // loadmodalformRef.setFieldsValue({ records: undefined });

      // test
      axios
        .get(
          "https://9kzcw8mae2.execute-api.eu-west-2.amazonaws.com/dev/upload/tables/columns",
          {
            params: {
              project: selectedProjectName,
              table_name: tableValue,
            },
          }
        )
        .then((res) => {
          dispatch(saveColumnList(res.data.data.columns));
          dispatch(saveDatasetFormKey(res.data.data.dataset_id));
        })
        .catch(() =>
          notification.error({
            message: "Network Error!",
            description: "Try again after some time",
          })
        );

      // axios
      //   .get(
      //     "https://9kzcw8mae2.execute-api.eu-west-2.amazonaws.com/dev/upload/tables/count",
      //     {
      //       params: {
      //         project: selectedProjectName,
      //         table_name: tableValue,
      //       },
      //     }
      //   )
      //   .then((res: any) => {
      //     dispatch(saveRecordCount(res.data.data.count));
      //     dispatch(saveDatasetFormKey(res.data.data.dataset_id));
      //   })
      //   .catch((err) => {
      //     notification.error({
      //       message: "Error",
      //       description: "Error loading record count",
      //     });
      //   });
    }
  };

  // const onSliderAfterChange = (value: any) => {
  //   // controlDispatch({
  //   //   type: ControlActionTypes.SET_SLIDER_VALUE,
  //   //   payload: value,
  //   // });
  //   dispatch(saveRecordCount(value));
  // };

  const onCancelLoadModal = () => {
    setLoadDataModal(false);
    setRecordDataLoading(false);
    dispatch(resetProjectData());
    loadmodalformRef.setFieldsValue({
      project: undefined,
      table: undefined,
      column: undefined,
      columnValue: undefined,
      // records: undefined,
    });
  };

  return (
    <>
      <Card
        className="controll"
        title="ETL Post Actions"
        extra={
          <Space>
            <Button type="primary" onClick={handleLoadDataset}>
              Load Data
            </Button>
            {/* <Button type="primary" onClick={() => setShowUploadModal(true)}>
              Upload Data
            </Button> */}
          </Space>
        }
        bodyStyle={{
          height: "90vh",
          overflowY: "scroll",
          overflowX: "hidden",
        }}
      >
        {/* <Alert
          showIcon
          type="info"
          description=" The dataset should be a proper geojson format or CSV format with
            valid geometry fields or Collection of objects with Latitude and
            Longitude fields"
          style={{ padding: 8 }}
        /> */}
        <Typography.Title level={4} style={{ margin: "24px 0px" }}>
          Categories
        </Typography.Title>
        <Tooltip
          title={`${
            !selectedProjectName || !selectedDatasetKey
              ? "no dataset key provided"
              : "Add category"
          } `}
        >
          <Button
            block
            type="primary"
            icon={<PlusOutlined />}
            style={
              selectedProjectName && selectedDatasetKey
                ? {
                    backgroundColor: "rgb(15, 150, 104)",
                    color: "rgb(255, 255, 255)",
                  }
                : {
                    backgroundColor: "rgba(0,0,0,.04)",
                    color: "rgba(0,0,0,.25)",
                    borderColor: "#d9d9d9",
                    border: "1px solid #d9d9d9",
                  }
            }
            onClick={() => setShowCategoryModal(true)}
            disabled={!selectedProjectName || !selectedDatasetKey}
          >
            Add Category
          </Button>
        </Tooltip>

        <Typography.Title level={4} style={{ margin: "24px 0px" }}>
          Layers
        </Typography.Title>
        <Space direction="vertical" style={{ width: "100%" }}>
          {isEmpty(dataset) ? (
            <Alert type="warning" showIcon message="No dataset" />
          ) : (
            Object.keys(dataset).map((id) => (
              <Layer
                name={dataset[id].name}
                dataset={dataset[id].value}
                datasetId={id}
                handleViewData={handleViewData}
                key={id}
                selectedProject={selectedProjectName}
              />
            ))
          )}
        </Space>
        <Typography.Title level={4} style={{ margin: "24px 0px" }}>
          Filters
        </Typography.Title>
        {Object.keys(filters).map((datasetId) => (
          <Filter mapDataset={dataset} datasetId={datasetId} key={datasetId} />
        ))}
        <Divider />
        <AddFilter mapData={dataset} filters={filters} />
        <Typography.Title level={4} style={{ margin: "24px 0px" }}>
          Meta Information
        </Typography.Title>
        <Button
          block
          type="primary"
          icon={<PlusOutlined />}
          style={
            selectedProjectName && selectedDatasetKey
              ? {
                  backgroundColor: "rgb(15, 150, 104)",
                  color: "rgb(255, 255, 255)",
                }
              : {
                  backgroundColor: "rgba(0,0,0,.04)",
                  color: "rgba(0,0,0,.25)",
                  borderColor: "#d9d9d9",
                  border: "1px solid #d9d9d9",
                }
          }
          onClick={() => handleMetaInformationModal(true)}
          disabled={!selectedProjectName || !selectedDatasetKey}
        >
          Add Meta
        </Button>
        <Divider />
        <Typography.Title level={4} style={{ margin: "24px 0px" }}>
          Column Description
        </Typography.Title>
        <Button
          block
          type="primary"
          icon={<PlusOutlined />}
          style={
            selectedProjectName && selectedDatasetKey
              ? {
                  backgroundColor: "rgb(15, 150, 104)",
                  color: "rgb(255, 255, 255)",
                }
              : {
                  backgroundColor: "rgba(0,0,0,.04)",
                  color: "rgba(0,0,0,.25)",
                  borderColor: "#d9d9d9",
                  border: "1px solid #d9d9d9",
                }
          }
          onClick={() => handleColumnDescriptionModal(true)}
          disabled={!selectedProjectName || !selectedDatasetKey}
        >
          Add Column Description
        </Button>
        <Button
          block
          type="primary"
          // icon={<PlusOutlined />}
          style={
            selectedProjectName && selectedDatasetKey
              ? {
                  // backgroundColor: "rgb(15, 150, 104)",
                  backgroundColor: "#ff0058",
                  color: "rgb(255, 255, 255)",
                  marginTop: "1.5em",
                  height: "3em",
                  borderRadius: 4,
                }
              : {
                  backgroundColor: "rgba(0,0,0,.04)",
                  color: "rgba(0,0,0,.25)",
                  borderColor: "#d9d9d9",
                  border: "1px solid #d9d9d9",
                  marginTop: "1.5em",
                  height: "3em",
                  borderRadius: 4,
                }
          }
          onClick={showConfirm}
          disabled={!selectedProjectName || !selectedDatasetKey}
        >
          Push to Production
        </Button>
      </Card>
      <Modal
        title="Column Description"
        visible={columnDescriptionModal}
        closable
        width={725}
        footer={false}
        className="dataset-modal column-modal-container"
        onCancel={() => handleColumnDescriptionModal(false)}
      >
        <ColumnModal
          selectedProject={selectedProjectName}
          selectedDatasetKey={selectedDatasetKey}
          columnData={columnData}
          handleColumnDescription={handleColumnDescription}
        />
      </Modal>
      <Modal
        title="Meta Information"
        visible={metaInformationModal}
        closable
        width={725}
        footer={false}
        className="dataset-modal"
        onCancel={() => setMetaInformationModal(false)}
      >
        <Space direction="vertical" style={{ width: "100%" }}>
          <Form
            form={loadmetaformRef}
            layout="vertical"
            onFinish={handleMetaInformation}
          >
            <Form.Item
              label="Enter dataset description "
              name="datasetdescription"
              rules={[
                {
                  // required: true,
                  message: "Please enter the dataset information",
                },
              ]}
            >
              <TextArea
                onChange={(e) => null}
                placeholder="Enter dataset description"
                size="large"
                rows={4}
              />
            </Form.Item>
            <Form.Item
              label="Enter dataset name"
              name="datasetname"
              rules={[
                {
                  // required: true,
                  message: "Please enter the dataset name",
                },
              ]}
            >
              <Input
                onChange={(e) => null}
                placeholder="Enter dataset name"
                size="large"
              />
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                block
                loading={metaInformationLoading}
              >
                Save Information
              </Button>
            </Form.Item>
          </Form>
        </Space>
      </Modal>
      <Modal
        title="Upload Dataset"
        visible={showUploadModal}
        closable
        onCancel={() => setShowUploadModal(false)}
        width={725}
        footer={false}
      >
        <UploadFile
          processFile={processFile}
          setShowUploadModal={setShowUploadModal}
        />
      </Modal>
      <Modal
        title="Load Dataset"
        visible={loadDataModal}
        closable
        onCancel={onCancelLoadModal}
        width={725}
        footer={false}
        className="dataset-modal"
      >
        {/* <Space direction="vertical" style={{ width: "100%" }} size="large">
          <>
            <p
              style={{
                margin: 0,
                marginBottom: 12,
                fontWeight: 600,
                fontSize: 15,
              }}
            >
              Select Project
            </p>
            <Select
              showSearch
              placeholder="Select a project"
              optionFilterProp="children"
              value={selectedProjectName || undefined}
              onChange={onSelectedProject}
              options={projectData.map((project: any) => ({
                label: project.full_name,
                value: project.project_name,
              }))}
              size="middle"
              style={{ width: "210px" }}
            />
          </>
          <>
            <p
              style={{
                margin: 0,
                marginBottom: 12,
                marginTop: 25,
                fontWeight: 600,
                fontSize: 15,
              }}
            >
              Select Table
            </p>
            <Select
              showSearch
              placeholder="Select a table"
              optionFilterProp="children"
              onChange={onSelectedTable}
              size="large"
              value={selectedTableName || undefined}
              disabled={!selectedProjectName || tablesList.length === 0}
              options={tablesList.map((table: any) => ({
                label: table.table_name,
                value: table.table_name,
              }))}
              filterOption={(input: any, option: any) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              style={{ width: "400px" }}
            />
          </>
          <>
            <p
              style={{
                margin: 0,
                marginBottom: 12,
                marginTop: 25,
                fontWeight: 600,
                fontSize: 15,
              }}
            >
              Records to load
            </p>
            <div style={{ margin: 0, marginLeft: 50, marginRight: 50 }}>
              <Slider
                defaultValue={0}
                min={0}
                max={recordCount}
                marks={marks}
                disabled={!selectedTableName}
                // onAfterChange={onSliderAfterChange}
                onChange={onSliderAfterChange}
              />
            </div>
          </>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <Button
              type="primary"
              style={{
                marginTop: 25,
                padding: "0 25px",
                fontSize: 15,
                height: 36,
              }}
              onClick={fetchRecordData}
              disabled={!selectedTableName || !selectedProjectName}
              loading={recordDataLoading}
            >
              Load
            </Button>
          </div>
        </Space> */}
        <Form
          // {...layout}
          layout="vertical"
          // ref={loadmodalformRef}
          form={loadmodalformRef}
          name="control-ref"
          onFinish={onFinishLoadModal}
        >
          <Form.Item
            name="project"
            label="Select Project"
            rules={[{ required: true }]}
          >
            <Select
              placeholder="Select a project from the list"
              onChange={onProjectChange}
              // allowClear
              style={{ width: "210px" }}
            >
              {projectData &&
                projectData.map((project: any) => (
                  <Option
                    value={project.project_name}
                    key={project.project_name}
                  >
                    {project.full_name}
                  </Option>
                ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="table"
            label="Select Table"
            rules={[{ required: true }]}
          >
            <Select
              showSearch
              placeholder="Select a table from the list"
              optionFilterProp="children"
              onChange={onTableChange}
              // allowClear
              disabled={!selectedProjectName || tablesList.length === 0}
              options={tablesList.map((table: any) => ({
                label: table.table_name,
                value: table.table_name,
              }))}
              filterOption={(input: any, option: any) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              style={{ width: "400px" }}
            />
            {/* {tablesList &&
                tablesList.map((table: any) => (
                  <Option value={table.table_name} key={table.table_name}>
                    {table.table_name}
                  </Option>
                ))} */}
            {/* </Select> */}
          </Form.Item>
          <Space>
            <Form.Item
              name="column"
              label="Select column name"
              rules={[{ required: true }]}
            >
              <Select
                placeholder="select a column name"
                showSearch
                allowClear
                disabled={isEmpty(columnList)}
                value={selectedDataColumn}
                options={columnList.map((item: any, i: number) => ({
                  label: item.column_name,
                  value: item.column_name,
                  key: i,
                }))}
                onChange={(val: string | undefined) =>
                  dispatch(setSeletedDataColumn(val))
                }
                style={{ width: 240 }}
              />
            </Form.Item>
            <Form.Item
              name="columnValue"
              label="Enter a unique value (Integer)"
              rules={[{ required: true }]}
            >
              <Input
                type="number"
                placeholder="Enter value"
                disabled={isEmpty(selectedDataColumn)}
                style={{ width: 240 }}
              />
            </Form.Item>
          </Space>
          {/* <Form.Item
            name="records"
            label="Records to load"
            rules={[{ required: true }]}
          >
            <Slider
              // defaultValue={0}
              min={0}
              max={recordCount}
              marks={marks}
              disabled={!selectedTableName || !selectedProjectName}
              // onAfterChange={onSliderAfterChange}
              // onChange={onSliderAfterChange}
              style={{ margin: 0, marginLeft: 50, marginRight: 100 }}
            />
          </Form.Item> */}
          <Form.Item style={{ textAlign: "end", marginBottom: 0 }}>
            <Button
              type="primary"
              htmlType="submit"
              style={{
                marginTop: 25,
                fontSize: 15,
                height: 36,
              }}
              disabled={!selectedTableName || !selectedProjectName}
              loading={recordDataLoading}
            >
              Load
            </Button>
            {/* <Button htmlType="button" onClick={onReset}>
          Reset
        </Button> */}
          </Form.Item>
        </Form>
      </Modal>
      <CategoryModal
        visible={showCategoryModal}
        onCancel={() => setShowCategoryModal(false)}
        selectedProject={selectedProjectName}
        datasetKey={selectedDatasetKey}
      />
      <DataTable dataset={dataset} />
    </>
  );
}
