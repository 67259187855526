import { useDispatch, useSelector } from "react-redux";
import { editConfigLayers } from "../models/deckgl";

const useUpdateConfig = () => {
  const {
    deckgl: { configlayers },
  } = useSelector((state: any) => state);
  const dispatch = useDispatch();

  const updateConfig = (id: string, key: string, value: any) => {
    dispatch(
      editConfigLayers({
        layers: { ...configlayers[id], [key]: value },
        id,
      })
    );
  };

  const updateConfigObject = (id: string, data: Record<string, unknown>) => {
    dispatch(
      editConfigLayers({
        layers: { ...configlayers[id], ...data },
        id,
      })
    );
  };
  return {
    updateConfig,
    updateConfigObject,
  };
};

export default useUpdateConfig;
