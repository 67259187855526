import { configureStore, combineReducers } from "@reduxjs/toolkit";
import dashboardmodel from "./models/dashboardmodel";
import deckgl from "./models/deckgl";

const reducers = combineReducers({
  deckgl,
  dashboardmodel,
});

const store = configureStore({
  reducer: reducers,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      thunk: false,
      serializableCheck: false,
    }).concat(),
  devTools: true,
});

export default store;
