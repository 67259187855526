import "../components.css";

import { Button, Collapse, notification, Space, Typography } from "antd";
import SliderComponent from "./layerComponents/SliderComponent";
import { useDispatch, useSelector } from "react-redux";
import ColorComponent from "./layerComponents/ColorComponent";
import { isEmpty } from "lodash";
import {
  EyeFilled,
  EyeInvisibleFilled,
  TableOutlined,
} from "@ant-design/icons";
import useUpdateConfig from "../../hooks/useUpdateConfig";
import { useEffect, useMemo, useState } from "react";
import { editDataInfo } from "../../models/deckgl";
import { BRIGHT_COLORS } from "../../constants/colorPalette";
import ConditionComponent from "./layerComponents/ConditionComponent";
import { chooseDb } from "../../services/firebase";
import axios from "axios";

const { Panel } = Collapse;

// const PROJECTS = [
//   {
//     value: 1,
//     label: "LEO Development",
//   },
//   {
//     value: 2,
//     label: "LEO Production",
//   },
//   {
//     value: 3,
//     label: "EVCP Development",
//   },
//   {
//     value: 4,
//     label: "EVCP Production",
//   },
//   {
//     value: 5,
//     label: "LEAR Development",
//   },
//   {
//     value: 6,
//     label: "LEAR Production",
//   },
//   {
//     value: 7,
//     label: "RESOP Development",
//   },
//   {
//     value: 8,
//     label: "RESOP Production",
//   },
// ];

function Layer({
  name,
  dataset,
  datasetId,
  handleViewData,
  selectedProject,
}: any) {
  const {
    deckgl: { configlayers, dataInfo, metaData },
  } = useSelector((state: any) => state);
  // const [exporting, setExporting] = useState(false);
  // const [datasetRenameModal, setDatasetRenameModal] = useState(false);
  // const [projectId, setProjectId] = useState(null);
  // const [newDatasetId, setNewDatasetId] = useState("");
  const [exportConfigLoading, setExportConfigLoading] = useState(false);
  const { updateConfig } = useUpdateConfig();
  const dispatch = useDispatch();
  const [fsDb, setFsDb] = useState<string>("");

  const db = useMemo(() => {
    return chooseDb(fsDb);
  }, [fsDb]);

  useEffect(() => {
    if (selectedProject) {
      if (selectedProject === "pkc") {
        setFsDb("pkc");
      } else if (selectedProject === "dcc") {
        setFsDb("resop");
      } else if (selectedProject === "occ") {
        setFsDb("aitl-lear");
      } else if (selectedProject === "wmca") {
        setFsDb("wmca");
      } else if (["ukpn", "ukpn_pbh"].includes(selectedProject)) {
        setFsDb("ukpn");
      }
    }
  }, [selectedProject]);

  const renderLayer = (type: string, subType: string) => {
    switch (type) {
      case "Point":
        return (
          <Space
            direction="vertical"
            size="large"
            style={{ width: "100%", overflow: "hidden", marginTop: 10 }}
          >
            <ColorComponent
              id={datasetId}
              name="Fill Color"
              dataset={dataset}
            />
            <SliderComponent id={datasetId} name="Radius" dataset={dataset} />
            <SliderComponent id={datasetId} name="Opacity" dataset={dataset} />
          </Space>
        );
      case "FeatureCollections":
      case "CsvCollections":
        return (
          <Space
            direction="vertical"
            size="large"
            style={{ width: "100%", overflow: "hidden", marginTop: 10 }}
          >
            {subType !== "LineString" && (
              <ColorComponent
                id={datasetId}
                name="Fill Color"
                dataset={dataset}
              />
            )}
            <ColorComponent
              id={datasetId}
              name="Stroke Color"
              dataset={dataset}
            />
            {subType === "Point" && (
              <SliderComponent id={datasetId} name="Radius" dataset={dataset} />
            )}
            <SliderComponent
              id={datasetId}
              name="Stroke Width"
              dataset={dataset}
            />
            {(subType === "Polygon" ||
              subType === "PolygonLine" ||
              subType === "PolygonPointLine" ||
              subType === "PolygonPoint") && (
              <SliderComponent id={datasetId} name="Height" dataset={dataset} />
            )}
            <SliderComponent id={datasetId} name="Opacity" dataset={dataset} />
            <ConditionComponent id={datasetId} name="Fill" dataset={dataset} />
            <ConditionComponent
              id={datasetId}
              name="Stroke"
              dataset={dataset}
            />
          </Space>
        );
    }
  };

  // const exportConfig = async (e: any) => {
  //   e.preventDefault();

  //   // const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
  //   //   JSON.stringify(configlayers[datasetId])
  //   // )}`;
  //   // const a = document.createElement("a");
  //   // a.href = jsonString;
  //   // a.download = `${name.split(".")[0]}.json`;
  //   // a.click();

  //   if (newDatasetId !== "") {
  //     setExporting(true);

  //     const url =
  //       "https://yy6ilzatii.execute-api.eu-central-1.amazonaws.com/dev/config";
  //     axios
  //       .post(url, {
  //         project_id: projectId,
  //         dataset_id: newDatasetId,
  //         config: JSON.stringify(configlayers[datasetId]),
  //       })
  //       .then(() => message.success("Config exported to RDS"))
  //       .catch(() => message.error("Error while exporting, please try again"))
  //       .finally(() => {
  //         setExporting(false);
  //         setProjectId(null);
  //         setNewDatasetId("");
  //         setDatasetRenameModal(false);
  //       });
  //   }
  // };

  useEffect(() => {
    const colorKeys = Object.keys(BRIGHT_COLORS);
    const dataColor =
      // @ts-ignore
      BRIGHT_COLORS[colorKeys[Math.floor(Math.random() * colorKeys.length)]];

    dispatch(editDataInfo({ id: datasetId, content: { color: dataColor } }));
    //eslint-disable-next-line
  }, []);

  if (isEmpty(configlayers[datasetId])) return null;

  const exportConfigurationHandler = async () => {
    const config = configlayers[datasetId];
    setExportConfigLoading(true);
    const metaInformation = metaData[datasetId];

    const metaDataInfo = isEmpty(metaInformation)
      ? {
          description: "",
          filename: "",
          license: null,
          real_data: null,
          url: "",
        }
      : metaInformation;

    db.dev
      .collection("static_datasets")
      .doc(datasetId)
      .set({
        config: config,
        filters: [],
        loaded: false,
        metadata: metaDataInfo,
      })
      .then(() => {
        notification.config({
          placement: "topLeft",
        });
        notification.open({
          message: "Document successfully written!",
        });
        setExportConfigLoading(false);
      });
    const rdsObj = {
      config: configlayers[datasetId],
      dataset_id: datasetId,
    };
    axios
      .post(
        ` https://9kzcw8mae2.execute-api.eu-west-2.amazonaws.com/dev/properties/config?project=${selectedProject}`,
        rdsObj
      )
      .then(() => {
        notification.config({
          placement: "topLeft",
        });
        notification.open({
          message: "Document successfully written to RDS!",
        });
      });
  };

  return (
    <>
      <Collapse
        expandIconPosition="right"
        ghost
        style={{ position: "relative" }}
      >
        <div
          style={{
            position: "absolute",
            height: 52,
            zIndex: 1,
            borderRadius: 5,
            borderLeft: !isEmpty(dataInfo[datasetId])
              ? `3px solid ${dataInfo[datasetId].color}`
              : "white",
          }}
        />
        <Panel
          className="layer"
          header={
            <Typography.Title
              level={5}
              ellipsis={true}
              style={{
                maxWidth: 175,
                margin: 0,
                color: "#efe6e6 !important",
                fontFamily: "Inter",
                fontSize: 12,
              }}
            >
              {name.split(".")[0]}
            </Typography.Title>
          }
          key="basic"
          extra={
            <Space>
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  handleViewData(datasetId);
                }}
              >
                <TableOutlined
                  style={{
                    color: "#efe6e6",
                    cursor: "pointer",
                    marginRight: 10,
                  }}
                />
              </div>

              <div
                onClick={(e) => {
                  e.stopPropagation();
                  updateConfig(
                    datasetId,
                    "visible",
                    !configlayers[datasetId].visible
                  );
                }}
              >
                {configlayers[datasetId].visible ? (
                  <EyeFilled
                    style={{
                      marginTop: 1,
                      fontSize: 12,
                      color: "#efe6e6",
                    }}
                  />
                ) : (
                  <EyeInvisibleFilled
                    style={{
                      marginTop: 1,
                      fontSize: 12,
                      color: "#efe6e6",
                    }}
                  />
                )}
              </div>
            </Space>
          }
          showArrow
        >
          {renderLayer(dataset.type, dataset.subType)}

          <Button
            block
            style={{
              backgroundColor: "var(--default-green)",
              border: 0,
              marginTop: 16,
            }}
            type="primary"
            onClick={exportConfigurationHandler}
            loading={exportConfigLoading}
          >
            Export Configuration
          </Button>
        </Panel>
      </Collapse>
      {/* <Modal
        visible={datasetRenameModal}
        closable
        onCancel={() => setDatasetRenameModal(false)}
        title="Edit Dataset Info"
        centered
        footer={false}
      >
        <Space direction="vertical" style={{ width: "100%" }}>
          <Form
            layout="vertical"
            initialValues={{ project: projectId, newDatasetId }}
          >
            <Form.Item
              label="Select project"
              name="project"
              rules={[
                { required: true, message: "Please select the project!" },
              ]}
            >
              <Select
                placeholder="Select project..."
                options={PROJECTS}
                size="large"
                onChange={(val) => setProjectId(val)}
                style={{ width: "100%" }}
              />
            </Form.Item>
            <Form.Item
              label="Enter new dataset id"
              name="newDatasetId"
              rules={[
                { required: true, message: "Please enter the dataset id!" },
              ]}
            >
              <Input
                onChange={(e) => setNewDatasetId(e.target.value)}
                placeholder="Enter new dataset id..."
                size="large"
              />
            </Form.Item>

            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                block
                onClick={exportConfig}
                loading={exporting}
              >
                Export Configuration
              </Button>
            </Form.Item>
          </Form>
        </Space>
      </Modal> */}
    </>
  );
}

export default Layer;
