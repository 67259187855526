//@ts-ignore
import { isEmpty, isObject } from "lodash";
import {
  isGeoJson,
  isRowObject,
  //@ts-ignore
} from "kepler.gl/dist/processors/file-handler";
import {
  processGeojson,
  processRowObject,
  processCsvData,
  //@ts-ignore
} from "kepler.gl/processors";

export const mapLatLon = ({ fields, rows }: any) => {
  const formattedFields = [...fields].map((field) => {
    if (
      /[a-zA-Z]_lat/.test(field.name) ||
      /[a-zA-Z]_latitude/.test(field.name) ||
      /^lat+[a-zA-Z]?/i.test(field.name)
    )
      return { ...field, name: "lat" };
    if (
      /[a-zA-Z]_lon/.test(field.name) ||
      /[a-zA-Z]_longitude/.test(field.name) ||
      /[a-zA-Z]_lng/.test(field.name) ||
      /^lng/.test(field.name) ||
      /^lon+[a-zA-Z]?/i.test(field.name)
    )
      return { ...field, name: "lon" };
    return field;
  });
  return { fields: formattedFields, rows };
};

// Process fields and rows
export const processFieldsAndRows = (
  dataset: Record<string, unknown>,
  dataHash?: string
) => {
  let data;
  if (isGeoJson(dataset)) data = processGeojson(dataset);
  else if (isRowObject(dataset)) data = mapLatLon(processRowObject(dataset));
  else data = mapLatLon(processCsvData(dataset));

  return data;
};

export const processRow = (data: any) => {
  const { fields, rows } = data;
  const hasGeoJson = fields.some((item: any) => item.type === "geojson");
  const geoIndex = fields.findIndex((item: any) => item.type === "geojson");
  const newData = rows.map((rowData: any) => {
    const itemObj: Record<string, any> = {};
    rowData.map((item: any, i: number) => {
      if (hasGeoJson && i === geoIndex) {
        return (itemObj["geometry"] = isObject(item)
          ? //@ts-ignore
            item.geometry
          : parseFile(JSON.parse(item)));
      } else {
        if (item === true) {
          return (itemObj[fields[i].name] = "true");
        }
        if (item === false) {
          return (itemObj[fields[i].name] = "false");
        }
        if (item === null) {
          if (fields[i]?.type !== "real" && fields[i]?.type !== "integer")
            return (itemObj[fields[i]?.name] = "No");
          else if (typeof item === "number" && !isEmpty(item.toString()))
            return (itemObj[fields[i]?.name] = item);
          else return (itemObj[fields[i]?.name] = 0);
        } else return (itemObj[fields[i]?.name] = item);
      }
    });
    return itemObj;
  });

  return {
    type: hasGeoJson ? "CsvCollections" : "Point",
    subType: hasGeoJson ? findLayerType(newData) : "Point",
    fields,
    rows: newData,
  };
};

const findLayerType = (data: any) => {
  const types = Array.from(
    new Set(
      data.map(
        (obj: any) => obj?.geometry?.type || obj?._geojson?.geometry?.type
      )
    )
  );

  if (
    (types.includes("LineString") || types.includes("MultiLineString")) &&
    (types.includes("Polygon") || types.includes("MultiPolygon")) &&
    (types.includes("Point") || types.includes("MultiPoint"))
  )
    return "PolygonPointLine";
  else if (
    (types.includes("MultiPoint") || types.includes("Point")) &&
    (types.includes("LineString") || types.includes("MultiLineString"))
  )
    return "PointLine";
  else if (
    (types.includes("MultiPoint") || types.includes("Point")) &&
    (types.includes("Polygon") || types.includes("MultiPolygon"))
  )
    return "PolygonPoint";
  else if (
    (types.includes("MultiLineString") || types.includes("LineString")) &&
    (types.includes("Polygon") || types.includes("MultiPolygon"))
  )
    return "PolygonLine";
  else if (
    (types.length === 1 || types.length === 2) &&
    (types.includes("Point") || types.includes("MultiPoint"))
  )
    return "Point";
  else if (
    (types.length === 1 || types.length === 2) &&
    (types.includes("LineString") || types.includes("MultiLineString"))
  )
    return "LineString";
  else return "Polygon";
};

export const parseFile = (file: any) => {
  try {
    const result = JSON.parse(file);
    return result;
  } catch (e) {
    return file;
  }
};
