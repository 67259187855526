//@ts-ignore
import { Button, Dropdown, Menu, Switch, Typography } from "antd";
import { useEffect, useState } from "react";
//@ts-ignore
import styled from "styled-components";
import { COLOR_RANGE } from "../../../constants/colorRange";
import { HextoRGB, RGBToHex } from "../../../functions/colorConversion";
import useUpdateConfig from "../../../hooks/useUpdateConfig";
import CustomPalette from "./CustomPalette";

const ColorRangeContainer = styled.div`
  padding: 12px;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: 12px;
  flex-direction: column;
  background-color: white;
  border: 2px solid lightgrey;
`;

const ColorRangeRow = styled.ul`
  display: flex;
  width: 100%;
  list-style-type: none;
  cursor: pointer;
  margin: 0px;
  padding: 2px 4px 2px 4px;
  li:first-child {
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
  }
  li:last-child {
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
  }
`;

const ColorElement = styled.li`
  height: 15px;
  flex-grow: 1;
`;

function checkColorLength(name: string, config: any) {
  if (config?.customColor && config?.customStrokeColor) {
    if (name === "Fill Color") {
      return config?.customColor ? config?.customColor.length : null;
    } else
      return config?.customStrokeColor
        ? config?.customStrokeColor.length
        : null;
  } else return null;
}

const ColorRange = (props: any) => {
  const { updateConfigObject } = useUpdateConfig();
  const colorMap = new Map([
    ["yellowBlue", "yellowBlue_2"],
    ["green", "sequentialGreen1_6"],
    ["blue", "sequentialBlue3_6"],
    ["pink", "sequentialPurple4_6"],
    ["default", "sequentialRed2_6"],
    ["red", "sequentialBrown_6"],
    ["diverging_1", "divergingBrownGreen_6"],
    ["diverging_2", "divergingPinkBlue_6"],
    ["quantitative", "qualitativeGreenPink_6"],
    ["qualitative_2", "qualitativeBluishGreenOrange_6"],
    ["whiteBlack", "sequentialBlack_6"],
    ["invertedRed", "rSequentialBrown_6"],
    ["aqua", "sequentialBlue1_8"],
  ]);
  const children = [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 15, 20];

  const { changeColorRange, selectedColor, id, config, name } = props;
  const [selectedValueLength, setSelectedValueLength] = useState(
    checkColorLength(name, config) ??
      parseInt(
        colorMap.has(selectedColor)
          ? colorMap.get(selectedColor)?.split("_")[1]
          : selectedColor.split("_")[1]
      )
  );

  const [reverse, setReverse] = useState(
    config?.customColor || config?.customStrokeColor
      ? false
      : colorMap.has(selectedColor)
      ? colorMap.get(selectedColor)?.startsWith("r")
      : selectedColor.startsWith("r")
  );

  const [showCustomPalette, setShowCustomPalette] = useState<boolean>(false);

  function handleChange(value: any) {
    setSelectedValueLength(parseInt(value));
  }

  function onChange(value: any) {
    setReverse(value);
  }

  const selectColorRange = (
    <Menu className="select-fields">
      {children.map((value) => (
        <Menu.Item onClick={() => handleChange(value)} key={value.toString()}>
          {value.toString()}
        </Menu.Item>
      ))}
    </Menu>
  );

  const saveCustomPaletteHandler = (colors: any[]) => {
    updateConfigObject(id, {
      [name === "Fill Color" ? "customColor" : "customStrokeColor"]: colors.map(
        (item: any) => RGBToHex(item)
      ),
      [name === "Fill Color" ? "colorRange" : "strokeColorRange"]: null,
    });
    // save colors to firebase
  };

  const cancelCustomPalette = (val: boolean) => {
    setShowCustomPalette(val);
    if (!val) {
      name === "Fill Color"
        ? updateConfigObject(id, {
            customColor: null,
            colorRange: "default",
          })
        : updateConfigObject(id, {
            customStrokeColor: null,
            strokeColorRange: "default",
          });
    }
  };

  useEffect(() => {
    const res = config?.customColor
      ? config.customColor?.length
      : //@ts-ignore
        COLOR_RANGE[selectedColor]?.length;

    setSelectedValueLength(res);
  }, [selectedColor, config.customColor]);

  return (
    <ColorRangeContainer className="color-range-container">
      {!showCustomPalette && (
        <>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography.Text
              style={{ margin: 0, width: "100%", fontWeight: 600 }}
            >
              Color range
            </Typography.Text>
            <Dropdown
              overlay={selectColorRange}
              trigger={["click"]}
              placement="bottom"
            >
              <Button
                type="text"
                block
                style={{
                  backgroundColor: "var(--default-background-color)",
                  color: "var(--default-grey)",
                }}
              >
                {selectedValueLength}
              </Button>
            </Dropdown>
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography.Text style={{ margin: 0, fontWeight: 600 }}>
              {reverse ? "Disable Reverse" : "Enable Reverse"}
            </Typography.Text>
            <Switch defaultChecked={reverse} size="small" onChange={onChange} />
          </div>
        </>
      )}
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography.Text style={{ margin: 0, fontWeight: 600 }}>
          Custom Palette
        </Typography.Text>
        <Switch
          checked={showCustomPalette}
          size="small"
          onChange={(val) => cancelCustomPalette(val)}
        />
      </div>
      {showCustomPalette ? (
        <CustomPalette
          // @ts-ignore
          colorRange={COLOR_RANGE[selectedColor]}
          onCancel={() => cancelCustomPalette(false)}
          onConfirm={saveCustomPaletteHandler}
          customColor={
            name === "Fill Color"
              ? config?.customColor?.map((item: any) => HextoRGB(item))
              : config?.customStrokeColor?.map((item: any) => HextoRGB(item))
          }
        />
      ) : (
        Object.entries(COLOR_RANGE)
          .filter(
            (item) =>
              item[1].length === selectedValueLength &&
              (reverse ? item[0].startsWith("r") : !item[0].startsWith("r")) &&
              item[0] !== selectedColor
          )
          .map((colorList) => (
            <ColorRangeRow
              key={colorList[0]}
              onClick={() => changeColorRange(colorList[0])}
            >
              {colorList[1].map((color, i) => {
                let hexColor = RGBToHex(color);
                return (
                  <ColorElement key={i} style={{ backgroundColor: hexColor }} />
                );
              })}
            </ColorRangeRow>
          ))
      )}
    </ColorRangeContainer>
  );
};

export default ColorRange;
