import { Alert, Button, Dropdown, Select, Space, Typography } from "antd";
import { ColorsByTheme, Themes } from "../../../constants/colorPalette";
//@ts-ignore
import { range } from "d3-array";
//@ts-ignore
import styled from "styled-components";
import { useState } from "react";
import { isEmpty, uniq } from "lodash";
import useUpdateConfig from "../../../hooks/useUpdateConfig";
import { HextoRGB } from "../../../functions/colorConversion";

const PALETTE_HEIGHT = "8px";
const ROWS = 22;

const StyledColorPalette = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 12px;
  :hover {
    cursor: pointer;
  }
  background-color: white;
  border: 2px solid lightgrey;
  width: 300px;
`;

const StyledColorColumn = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: space-between;
`;

const StyledColorBlock = styled.div`
  flex-grow: 1;
  height: ${PALETTE_HEIGHT};
  border-width: 1px;
  border-style: solid;
`;

const NullishValue = ({ id, config, data }: any) => {
  const { rows } = data;
  const [selectedColor, setSelectedColor] = useState("#000000");
  const [nullValue, setNullValue] = useState<string | number | boolean>("");
  const { updateConfig } = useUpdateConfig();

  const setNullColor = () => {
    if (!isEmpty(nullValue.toString())) {
      updateConfig(id, "nullConfig", {
        value: nullValue,
        color: HextoRGB(selectedColor),
      });
    }
  };

  const clearNullConfig = () => {
    if (!isEmpty(nullValue.toString())) {
      setNullValue("");
      updateConfig(id, "nullConfig", null);
    }
  };

  return (
    <Space
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
      }}
      className="nullish"
    >
      <Typography.Text>Nullish Value</Typography.Text>
      <Alert message="Use this to assign a color if the dataset has any null or undefined values" />
      <Space style={{ backgroundColor: "lightgrey", padding: 6, width: 306 }}>
        <Dropdown
          overlay={<ColorPalette setSelectedColor={setSelectedColor} />}
          trigger={["click"]}
        >
          <Button
            style={{
              border: "none",
              outline: "none",
              padding: 0,
            }}
          >
            <input
              readOnly={true}
              type="color"
              value={selectedColor}
              style={{
                border: "none",
                outline: "none",
                cursor: "pointer",
                backgroundColor: "lightgray",
                height: "100%",
              }}
            />
          </Button>
        </Dropdown>

        <Select
          size="small"
          placeholder="select value"
          style={{ width: 230 }}
          showArrow={false}
          allowClear
          showSearch
          value={nullValue ? nullValue : undefined}
          disabled={isEmpty(config.colorBasedOn)}
          options={uniq(
            rows.map((item: any) => item[config.colorBasedOn])
          )?.map((item: any, i: number) => ({
            lable: item,
            value: item,
            key: i,
          }))}
          onSelect={(val: any) => setNullValue(val)}
          onDeselect={() => setNullValue("")}
        />
      </Space>
      <Space>
        <Button
          type="primary"
          style={{ backgroundColor: "var(--default-green)" }}
          disabled={isEmpty(nullValue.toString())}
          onClick={setNullColor}
        >
          Apply Color
        </Button>
        <Button
          type="primary"
          style={{ backgroundColor: "var(--default-red)" }}
          disabled={isEmpty(nullValue.toString())}
          onClick={clearNullConfig}
        >
          Remove
        </Button>
      </Space>
    </Space>
  );
};

function ColorPalette({ setSelectedColor }: any) {
  return (
    <StyledColorPalette>
      {Themes.map((theme, col) => (
        <StyledColorColumn key={col}>
          {range(1, ROWS + 1, 1).map((key: any) => (
            <StyledColorBlock
              style={{
                //@ts-ignore
                backgroundColor: ColorsByTheme[theme][key],
                //@ts-ignore
                borderColor: ColorsByTheme[theme][key],
              }}
              //@ts-ignore
              onClick={() => setSelectedColor(ColorsByTheme[theme][key])}
              key={`${theme}_${key}`}
            />
          ))}
        </StyledColorColumn>
      ))}
    </StyledColorPalette>
  );
}

export default NullishValue;
