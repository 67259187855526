import { useState } from "react";
import { useDispatch } from "react-redux";
import { Space, Button, Popover, Tooltip } from "antd";
import { CodeSandboxOutlined, DatabaseOutlined } from "@ant-design/icons";
import { changeViewState } from "../models/deckgl";
import Legend from "./Legend";

const MapTools = ({ dataset }: any) => {
  const [is3DEnabled, setIs3DEnabled] = useState(false);
  const [isLegendVisible, setIsLegendVisible] = useState<boolean>(false);

  const dispatch = useDispatch();

  const handle3D = () => {
    if (is3DEnabled) {
      dispatch(changeViewState({ bearing: 0, pitch: 0 }));
      setIs3DEnabled(false);
      return;
    }
    dispatch(changeViewState({ bearing: -24, pitch: 50 }));
    setIs3DEnabled(true);
    return;
  };

  const toggleLegend = () => {
    setIsLegendVisible((prev) => !prev);
  };

  return (
    <Space direction="vertical" className="map-tools">
      <Tooltip
        title={is3DEnabled ? "Back to 2D" : "View map in 3D"}
        placement="right"
        getTooltipContainer={(): any => document.querySelector(".map-tools")}
      >
        <Button icon={<CodeSandboxOutlined />} onClick={handle3D} />
      </Tooltip>
      <Tooltip
        title={isLegendVisible ? "Hide legend" : "Show legend"}
        placement="right"
        getTooltipContainer={(): any => document.querySelector(".map-tools")}
      >
        <Popover
          style={{ padding: 12, position: "absolute" }}
          placement="rightTop"
          trigger="click"
          visible={isLegendVisible}
          content={
            <Legend
              mapDataset={dataset}
              setIsLegendVisible={setIsLegendVisible}
            />
          }
          getPopupContainer={(): any => document.querySelector(".map-tools")}
        >
          <Button
            className="map-tools-icon"
            icon={<DatabaseOutlined />}
            onClick={() => toggleLegend()}
          />
        </Popover>
      </Tooltip>
    </Space>
  );
};

export default MapTools;
