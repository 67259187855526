import "./components.css";
import {
  Table,
  Typography,
  Space,
  Tag,
  Drawer,
  PageHeader,
  Button,
} from "antd";
import { batch, useDispatch, useSelector } from "react-redux";
import { setDataTableActiveKey, setDataTableVisible } from "../models/deckgl";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
//@ts-ignore
import { Resizable } from "react-resizable";

const ResizableTitle = (props: any) => {
  const { onResize, width, ...restProps } = props;

  if (!width) {
    return <th {...restProps} />;
  }

  return (
    <Resizable
      width={width}
      height={0}
      handle={
        <span
          className="react-resizable-handle"
          onClick={(e) => {
            e.stopPropagation();
          }}
        />
      }
      onResize={onResize}
      draggableOpts={{ enableUserSelectHack: false }}
    >
      <th {...restProps} />
    </Resizable>
  );
};

const DataTable = ({ dataset }: any) => {
  const { dataTableVisible, dataTableActiveKey } = useSelector(
    (state: any) => state.deckgl
  );
  const { type, fields, rows } = dataset[dataTableActiveKey]?.value || {
    type: null,
    fields: [],
    rows: [],
    name: "",
  };

  const [columns, setColumns] = useState<any[]>([]);

  useEffect(() => {
    if (fields && dataTableVisible) {
      const cols = fields.map((item: any) => ({
        title: () => (
          <Space
            direction="vertical"
            key={item.fieldIdx}
            className="table-title"
          >
            <Typography.Title level={5}>{item.displayName}</Typography.Title>
            <Tag color={getTagColor(item.analyzerType)}>{item.type}</Tag>
          </Space>
        ),
        dataIndex: item.name.match(/geometry[a-zA-Z_]+/g)
          ? "geometry"
          : item.name,
        dataType: item.analyzerType,
        type: item.type,
        ellipsis: true,
        width:
          item.name === "_geojson" || item.type === "geojson"
            ? 500
            : item.name === "DateTime"
            ? 200
            : 125,

        sorter:
          item.type === "integer" ||
          item.type === "real" ||
          item.type === "boolean"
            ? (a: any, b: any) => a[item.name] - b[item.name]
            : false,
      }));
      setColumns(cols);
    }
  }, [fields, dataTableVisible]);

  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      batch(() => {
        dispatch(setDataTableVisible(false));
        dispatch(setDataTableActiveKey(""));
      });
    };
    //eslint-disable-next-line
  }, []);

  const createDataSource = (type: string) => {
    if (dataTableVisible) {
      const geoIndex: any = fields?.find(
        (item: any) => item.type === "geojson"
      )?.name;
      const booleanIndex: any = fields?.find(
        (item: any) => item.type === "boolean"
      )?.name;

      const newRow = rows?.map((item: any, i: number) => {
        if (geoIndex) {
          if (type === "FeatureCollections")
            return {
              ...item.properties,
              [geoIndex]: JSON.stringify(item.geometry),
              key: i.toString(),
            };
          else
            return {
              ...item,
              geometry: JSON.stringify(item.geometry),
              key: i.toString(),
            };
        }
        if (booleanIndex) {
          if (type === "FeatureCollections")
            return {
              ...item.properties,
              [booleanIndex]: item.properties[booleanIndex].toString(),
              key: i.toString(),
            };
          else
            return {
              ...item,
              [booleanIndex]: item[booleanIndex].toString(),
              key: i.toString(),
            };
        }
        return { ...item, key: i.toString() };
      });
      return newRow;
    } else return [];
  };
  const dataSource = createDataSource(type);

  const getTagColor = (type: string) => {
    return type === "INT"
      ? "blue"
      : type === "FLOAT"
      ? "green"
      : type === "DATETIME"
      ? "volcano"
      : type === "BOOLEAN"
      ? "cyan"
      : "magenta";
  };

  const handleResize =
    (index: number) =>
    (e: any, { size }: any) => {
      const nextColumns = [...columns];
      nextColumns[index] = {
        ...nextColumns[index],
        width: size.width,
      };

      setColumns(nextColumns);
    };

  const components = {
    header: {
      cell: ResizableTitle,
    },
  };

  const columnsData = columns.map((col, index) => ({
    ...col,
    onHeaderCell: (column: any) => ({
      width: column.width,
      onResize: handleResize(index),
    }),
  }));

  return (
    <Drawer
      getContainer={false}
      closable={false}
      title={
        <PageHeader
          title={
            <Typography.Title level={4} style={{ marginBottom: 0 }}>
              {dataset[dataTableActiveKey]?.name.split(".")[0]}
            </Typography.Title>
          }
          tags={[
            <Tag key="type" style={{ padding: 5 }} color="blue">
              Private
            </Tag>,
            <Tag
              key="length"
              color="blue"
              style={{ padding: 5 }}
            >{`${rows?.length} Rows`}</Tag>,
            <Tag key="data-type" color="blue" style={{ padding: 5 }}>
              {dataset[dataTableActiveKey]?.value.type}
            </Tag>,
          ]}
          backIcon={
            <Button icon={<ArrowLeftOutlined />} type="text">
              Back
            </Button>
          }
          onBack={() => {
            dispatch(setDataTableVisible(false));
            setTimeout(() => dispatch(setDataTableActiveKey(null)), 500);
          }}
          style={{
            padding: 0,
          }}
        />
      }
      visible={dataTableVisible}
      style={{ position: "absolute", overflow: "hidden", zIndex: 10001 }}
      width={"100%"}
      height={"100%"}
      className="data-table-drawer"
    >
      <Table
        className="data-table"
        dataSource={dataSource && dataSource}
        columns={columnsData}
        components={components}
        bordered
        size="small"
        tableLayout="fixed"
        scroll={{ x: "75vw", y: 494 }}
        pagination={{ defaultPageSize: 50 }}
      />
    </Drawer>
  );
};

export default DataTable;
