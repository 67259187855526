import { ColorsByTheme, Themes } from "../../../constants/colorPalette";
import { range } from "d3-array";
import styled from "styled-components";

const PALETTE_HEIGHT = "8px";
const ROWS = 22;

const StyledColorPalette = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 12px;
  :hover {
    cursor: pointer;
  }
  background-color: white;
  border: 2px solid lightgrey;
`;

const StyledColorColumn = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: space-between;
`;

const StyledColorBlock = styled.div`
  flex-grow: 1;
  height: ${PALETTE_HEIGHT};
  border-width: 1px;
  border-style: solid;
`;

const ColorPalette = ({ changeSingleColor }) => {
  return (
    <StyledColorPalette>
      {Themes.map((theme, col) => (
        <StyledColorColumn key={col}>
          {range(1, ROWS + 1, 1).map((key) => (
            <StyledColorBlock
              style={{
                backgroundColor: ColorsByTheme[theme][key],
                borderColor: ColorsByTheme[theme][key],
              }}
              onClick={(e) => changeSingleColor(ColorsByTheme[theme][key])}
              key={`${theme}_${key}`}
            ></StyledColorBlock>
          ))}
        </StyledColorColumn>
      ))}
    </StyledColorPalette>
  );
};

export default ColorPalette;
