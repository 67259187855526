import "../../components.css";
import { Dropdown, Menu, Space, Tag, Input, Typography } from "antd";
import { CloseCircleOutlined, SearchOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import useUpdateConfig from "../../../hooks/useUpdateConfig";
import { useSelector } from "react-redux";
import { isEmpty, lowerCase } from "lodash";

const SelectField = (props: any) => {
  const {
    associatedName,
    fields,
    setScaleVisible,
    setColorRangeVisible,
    setSelectedColor,
    id,
  } = props;

  const configlayers = useSelector((state: any) => state.deckgl.configlayers);

  const [selectedColumn, setSelectedColumn] = useState("Select Column");
  const [search, setSearch] = useState("");
  const { updateConfig, updateConfigObject } = useUpdateConfig();

  const currentConfig = configlayers[id];

  useEffect(() => {
    const assignDefaultValue = (name: string) => {
      switch (name) {
        case "Radius":
          setSelectedColumn(configlayers[id].radiusBasedOn || "");
          return null;
        case "Fill Color":
          setSelectedColumn(configlayers[id].colorBasedOn || "");
          checkFieldType(configlayers[id].colorBasedOn);
          return null;
        case "Stroke Color":
          setSelectedColumn(configlayers[id].strokeColorBasedOn || "");
          checkFieldType(configlayers[id].strokeColorBasedOn);
          return null;
        case "Stroke Width":
          setSelectedColumn(configlayers[id].strokeWidthBasedOn || "");
          return null;
        case "Height":
          setSelectedColumn(configlayers[id].heightBasedOn || "");
          return null;
      }
    };
    assignDefaultValue(associatedName);
    // eslint-disable-next-line
  }, [currentConfig]);

  /* setting field name
   * calling checkComponent method to check the type of the selected field
   */
  const handleSelect = (fieldName: string) => {
    checkComponentType(associatedName, fieldName);
    setSelectedColumn(fieldName);
    setTimeout(() => setSearch(""), 500);
  };

  /* Checking the component associated to this. example (slider, color, basic)
   * setting the color range component to visible only when the associated
   * component name is color
   */
  const checkComponentType = (associatedName: string, fieldName: string) => {
    const type = associatedName.match(/Color|Basic|Stroke|Height|Radius/g);
    if (type && type.includes("Color")) {
      if (associatedName === "Fill Color") {
        updateConfig(id, "colorBasedOn", fieldName);
        setSelectedColor(configlayers[id].colorRange);
      } else if (associatedName === "Stroke Color") {
        updateConfigObject(id, {
          strokeColorRange: "default",
          strokeColorBasedOn: fieldName,
        });
        setSelectedColor(configlayers[id].strokeColorRange);
      }
      setColorRangeVisible(true);
      checkFieldType(fieldName);
    }
    if (
      type &&
      (type[0] === "Stroke" || type[0] === "Radius" || type[0] === "Height")
    ) {
      checkFieldType(fieldName);
      if (associatedName === "Stroke Width")
        updateConfig(id, "strokeWidthBasedOn", fieldName);
      else if (associatedName === "Radius")
        updateConfig(id, "radiusBasedOn", fieldName);
      else if (associatedName === "Height")
        updateConfig(id, "heightBasedOn", fieldName);
    }
  };

  // Checking field type.
  // set scale component visible only when selected field type is integer or real
  const checkFieldType = (name: string) => {
    if (name) {
      const fieldObject = fields.filter(
        (field: Record<string, any>) => field.name === name
      );
      const fieldType = fieldObject[0]?.type;
      if (
        (fieldType === "integer" ||
          fieldType === "real" ||
          fieldType === "string" ||
          fieldType === "boolean") &&
        associatedName !== "Radius"
      ) {
        setScaleVisible(true);
      } else {
        setScaleVisible(false);
      }
    }
  };

  const clearField = () => {
    switch (associatedName) {
      case "Fill Color":
        if (!isEmpty(configlayers[id].colorBasedOn)) {
          updateConfig(id, "colorBasedOn", null);
          setScaleVisible(false);
        }
        return;
      case "Radius":
        if (!isEmpty(configlayers[id].radiusBasedOn)) {
          updateConfig(id, "radiusBasedOn", null);
          setScaleVisible(false);
        }
        return;
      case "Stroke Color":
        if (!isEmpty(configlayers[id].strokeColorBasedOn)) {
          updateConfig(id, "strokeColorBasedOn", null);
          setScaleVisible(false);
        }
        return;
      case "Stroke Width":
        if (!isEmpty(configlayers[id].strokeBasedOn)) {
          updateConfig(id, "strokeBasedOn", null);
          setScaleVisible(false);
        }
        return;
      case "Height":
        if (!isEmpty(configlayers[id].heightBasedOn)) {
          updateConfig(id, "heightBasedOn", null);
          setScaleVisible(false);
        }
        return;
    }
  };

  const overlay = (
    <Menu className="select-fields layer">
      <Menu.Item style={{ pointerEvents: "inherit" }} key="search" disabled>
        <Input
          placeholder="search fields..."
          suffix={<SearchOutlined />}
          style={{
            border: 0,
          }}
          value={search}
          onChange={(val) => setSearch(val.target.value)}
        />
      </Menu.Item>
      {fields &&
        fields
          .filter((item: any) =>
            lowerCase(item.name).includes(lowerCase(search))
          )
          .map((data: any, j: number) => (
            <Menu.Item
              onClick={() => handleSelect(data.name)}
              key={`${data.name}_${j}`}
            >
              <Space
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                }}
              >
                <Tag
                  style={{
                    // backgroundColor: "transparent",
                    fontSize: 8,
                    width: 55,
                    textAlign: "center",
                  }}
                  color={
                    data.analyzerType === "INT"
                      ? "success"
                      : data.analyzerType === "FLOAT"
                      ? "yellow"
                      : data.analyzerType === "BOOLEAN"
                      ? "pink"
                      : data.analyzerType === "DATETIME"
                      ? "volcano"
                      : "cyan"
                  }
                >
                  {data.type}
                </Tag>
                {data.name}
              </Space>
            </Menu.Item>
          ))}
    </Menu>
  );

  return (
    <Space className="select-fields-input">
      <Dropdown
        overlay={overlay}
        trigger={["click"]}
        placement="bottom"
        getPopupContainer={(): any => document.querySelector(".layer")}
      >
        <div
          style={{
            width: 190,
            height: 40,
            display: "flex",
            alignItems: "center",
          }}
        >
          {isEmpty(selectedColumn) ? (
            "select column"
          ) : (
            <Typography.Text style={{ width: 175, margin: 0 }} ellipsis>
              {selectedColumn}
            </Typography.Text>
          )}
        </div>
      </Dropdown>
      <CloseCircleOutlined
        onClick={clearField}
        className="close-icon"
        style={{ fontSize: 14 }}
      />
    </Space>
  );
};

export default SelectField;
