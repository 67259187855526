import "./components.css";
import { CloseCircleOutlined, SearchOutlined } from "@ant-design/icons";
import {
  Button,
  Divider,
  Dropdown,
  Input,
  InputNumber,
  Menu,
  Slider,
  Space,
  Tag,
} from "antd";
import { isEmpty, compact } from "lodash";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getMinMax } from "../functions/layerProperties";
import { deleteFilterField, updateFilterFieldData } from "../models/deckgl";
import useUpdateConfig from "../hooks/useUpdateConfig";
import BarPlot from "./BarPlot";
import SelectFilterValue from "./SelectFilterValue";

const SelectFilterField = ({ dataset, datasetId, fieldId }: any) => {
  const {
    deckgl: { filters },
  } = useSelector((state: any) => state);

  const [search, setSearch] = useState<string>("");
  const [selectedField, setSelectedField] = useState<string>("");
  const [fieldRange, setFieldRange] = useState<any>([]);

  const [minMaxRange, setMinMaxRange] = useState<any>([]);
  const [filterType, setFilterType] = useState<string>("");
  const dispatch = useDispatch();
  const { updateConfigObject } = useUpdateConfig();
  const { fields } = dataset;

  useEffect(() => {
    // Getting default values from redux store if filter values exists
    const filterFieldData = filters[datasetId].filter(
      (item: any) => item.fieldId === fieldId
    )[0].data;
    const { fieldBasedOn, range, minmax, values } = filterFieldData;

    if (!isEmpty(fieldBasedOn)) {
      setSelectedField(fieldBasedOn);
    } else setSelectedField("");

    if (!isEmpty(range)) {
      setFieldRange(range);
    } else setMinMaxRange([]);

    if (!isEmpty(minmax)) {
      setMinMaxRange(minmax);
    } else setMinMaxRange([]);

    if (!isEmpty(values)) {
      setFilterType("string");
    } else setFilterType("number");

    //eslint-disable-next-line
  }, []);

  if (isEmpty(fields)) return null;

  const handleSelect = (value: Record<string, any>) => {
    const { name, type } = value;
    setSelectedField(name);
    setFilterType(type);
    if (type === "string" || type === "boolean") {
      const minMax = [0, 1];
      setFieldRange(minMax);
      setMinMaxRange(minMax);
    } else {
      const minMax = getMinMax(dataset, name);
      dispatch(
        updateFilterFieldData({
          datasetId,
          fieldId,
          value: name,
          values: [],
          range: minMax,
          minmax: minMax,
        })
      );
      setFieldRange(minMax);
      setMinMaxRange(minMax);
      makeFilterObject(datasetId, fieldId, name, minMax);
    }
  };

  const handleRangeChange = (value: any) => {
    dispatch(
      updateFilterFieldData({
        datasetId,
        fieldId,
        value: selectedField,
        values: [],
        range: value,
        minmax: minMaxRange,
      })
    );
    setFieldRange(value);
    makeFilterObject(datasetId, fieldId, selectedField, value);
  };

  const makeFilterObject = (
    datasetId: string,
    fieldId: string,
    value: string,
    range: any[]
  ) => {
    const newObj = filters[datasetId];
    const res = newObj.map((item: any) =>
      item.fieldId === fieldId
        ? {
            fieldId,
            data: { fieldBasedOn: value, range: range, minmax: minMaxRange },
          }
        : item
    );
    updateConfigObject(datasetId, { filterConfig: res, filterEnabled: true });
  };

  const clearField = (fieldId: string) => {
    dispatch(deleteFilterField({ datasetId, fieldId }));
    const newObj = filters[datasetId].filter(
      (item: any) => item.fieldId !== fieldId
    );
    updateConfigObject(datasetId, { filterConfig: newObj });
  };

  const filteredFields = (fields: any) => {
    const requiredFields = fields.filter(
      (item: any) =>
        item.analyzerType === "INT" ||
        item.analyzerType === "FLOAT" ||
        item.analyzerType === "STRING" ||
        item.analyzerType === "BOOLEAN" ||
        item.type === "time" ||
        item.type === "timestamp"
    );
    const appliedFields = compact(
      filters[datasetId].map((field: any) => field.data.fieldBasedOn)
    );
    const result = requiredFields.filter(
      (item: any) => !appliedFields.includes(item.name)
    );
    return result;
  };

  const overlay = (
    <Menu className="select-fields">
      <Menu.Item style={{ pointerEvents: "inherit" }} key="search" disabled>
        <Input
          placeholder="search filed.."
          suffix={<SearchOutlined />}
          style={{
            border: 0,
          }}
          value={search}
          onChange={(val) => setSearch(val.target.value)}
        />
      </Menu.Item>
      {filteredFields(fields)
        .filter((item: any) => item.name.toLowerCase().includes(search))
        .map((data: any, j: number) => (
          <Menu.Item onClick={() => handleSelect(data)} key={data.name}>
            <Space
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
              }}
            >
              <Tag
                style={{
                  backgroundColor: "transparent",
                  fontSize: 8,
                  width: 55,
                  textAlign: "center",
                }}
                color={
                  data.analyzerType === "INT"
                    ? "cyan"
                    : data.analyzerType === "STRING"
                    ? "success"
                    : "yellow"
                }
              >
                {data.analyzerType}
              </Tag>
              {data.name}
            </Space>
          </Menu.Item>
        ))}
    </Menu>
  );
  return (
    <>
      <Space>
        <Dropdown
          overlay={overlay}
          trigger={["click"]}
          placement="bottom"
          getPopupContainer={(): any => document.querySelector(".filters")}
        >
          <Button
            style={{
              // marginTop: 0,
              backgroundColor: "lightgrey",
              textAlign: "left",
              border: 0,
              width: 280,
              height: 40,
              color: "rgb(106, 116, 133)",
              fontSize: 14,
            }}
          >
            {!isEmpty(selectedField) ? selectedField : "select field.."}
          </Button>
        </Dropdown>
        <Space>
          <Button
            type="text"
            onClick={() => clearField(fieldId)}
            icon={
              <CloseCircleOutlined
                style={{ color: "rgb(106, 116, 133)", marginLeft: 0 }}
              />
            }
          />
        </Space>
      </Space>
      {!isEmpty(selectedField) &&
      !isEmpty(fieldRange) &&
      !isEmpty(minMaxRange) ? (
        <>
          <Divider style={{ margin: "12px 0px" }} />
          {!isEmpty(filterType) &&
          filterType !== "string" &&
          filterType !== "boolean" ? (
            <Space
              direction="vertical"
              className="field-controls"
              style={{
                width: "100%",
              }}
            >
              <BarPlot
                dataset={dataset}
                field={selectedField}
                appliedFilter={filters[datasetId]}
                fieldId={fieldId}
              />
              <Slider
                range={{ draggableTrack: true }}
                tooltipVisible={false}
                step={0.1}
                getTooltipPopupContainer={(): any =>
                  document.querySelector(".filters")
                }
                onChange={(range: any) => handleRangeChange(range)}
                value={fieldRange}
                min={minMaxRange[0]}
                max={minMaxRange[1]}
                style={{ width: "93.5%", margin: "4px 0px 24px 8px" }}
              />
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <InputNumber style={{ width: 75 }} value={fieldRange[0]} />
                <InputNumber style={{ width: 75 }} value={fieldRange[1]} />
              </div>
            </Space>
          ) : (
            <SelectFilterValue
              type={filterType}
              minmax={minMaxRange}
              dataset={dataset}
              filterObj={{ datasetId, fieldId }}
              selectedField={selectedField && selectedField}
            />
          )}
        </>
      ) : null}
    </>
  );
};

export default SelectFilterField;
