// @ts-ignore
import styled, { css } from "styled-components";
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from "react-sortable-hoc";
import { DeleteOutlined, MoreOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Dropdown, Input } from "antd";
import classnames from "classnames";
import { useEffect, useState } from "react";

import { HextoRGB, RGBToHex } from "../../../functions/colorConversion";
import ColorPalette from "./ColorPalette";

const dragHandleActive = css`
  .layer__drag-handle {
    color: ${(props: any) => props.theme.textColorHl};
    opacity: 1;
    cursor: move;
  }
`;

const StyledSortableItem = styled.div`
  display: flex;
  align-items: center;
  padding-top: 6px;
  padding-bottom: 6px;
  z-index: ${(props: any) => props.theme.dropdownWrapperZ + 1};

  :not(.sorting) {
    :hover {
      background-color: ${(props: any) => props.theme.panelBackgroundHover};
      ${dragHandleActive};
    }
  }

  &.sorting-colors {
    background-color: ${(props: any) => props.theme.panelBackgroundHover};
    ${dragHandleActive};
  }
`;

const StyledDragHandle = styled.div`
  display: flex;
  align-items: center;
  opacity: 0;
`;

const StyledTrash = styled.div`
  color: ${(props: any) => props.theme.textColor};
  svg {
    :hover {
      cursor: pointer;
      color: ${(props: any) => props.theme.subtextColorActive};
    }
  }
  height: 20px;
  margin-left: 6px;
  margin-right: 12px;
  :hover {
    cursor: pointer;
  }
`;

const StyledLine = styled.div`
  width: calc(100% - 16px);
  height: 1px;
  background-color: var(--secondary-grey);
  margin-top: 8px;
  margin-left: 8px;
`;

const StyledSwatch = styled.div.attrs({
  className: "custom-palette__swatch",
})`
  background-color: ${(props: any) => props.color};
  width: 32px;
  height: 18px;
  display: inline-block;
  :hover {
    box-shadow: ${(props: any) => props.theme.boxShadow};
    cursor: pointer;
  }
`;

const StyledColorRange = styled.div`
  padding: 0 8px;
  :hover {
    background-color: ${(props: any) => props.theme.panelBackgroundHover};
    cursor: pointer;
  }
`;

const ColorRangeRow = styled.ul`
  display: flex;
  width: 100%;
  list-style-type: none;
  cursor: pointer;
  margin: 0px;
  padding: 2px 4px 2px 4px;
  li:first-child {
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
  }
  li:last-child {
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
  }
`;

const ColorElement = styled.li`
  height: 15px;
  flex-grow: 1;
`;

const StyledButtonContainer = styled.div`
  margin-top: 10px;
  display: flex;
  direction: rtl;
  gap: 5px;
`;

const StyledInlineInput = styled.div`
  margin-left: 12px;
  input {
    color: ${(props: any) => props.theme.textColorHl};
    font-size: 10px;
  }
`;

const SortableItem = SortableElement(({ children, isSorting }: any) => (
  <StyledSortableItem
    className={classnames("custom-palette__sortable-items", {
      sorting: isSorting,
    })}
  >
    {children}
  </StyledSortableItem>
));

const WrappedSortableContainer = SortableContainer(
  ({ children, className }: any) => <div className={className}>{children}</div>
);

const DragHandle = SortableHandle(({ className, children }: any) => (
  <StyledDragHandle className={className}>{children}</StyledDragHandle>
));

const arrayMoveMutate = (array: any[], from: any, to: any) => {
  array.splice(to < 0 ? array.length + to : to, 0, array.splice(from, 1)[0]);
};

export const arrayMove = (array: any[], from: any, to: any) => {
  array = array.slice();
  arrayMoveMutate(array, from, to);
  return array;
};

const CustomPalette = ({
  colorRange,
  onConfirm,
  onCancel,
  customColor,
}: any) => {
  const [colors, setColors] = useState<any[]>([]);
  const [isSorting, setIsSorting] = useState<boolean>(false);
  const [showSketcher, setShowSketcher] = useState<any>(null);

  useEffect(() => {
    setColors(colorRange ?? customColor);
  }, [colorRange, customColor]);

  const onPickerUpdate = (color: any) => {
    const newColors = [...colors];
    newColors[showSketcher] = HextoRGB(color);
    setColors(newColors);
  };

  const onSwatchClick = (index: any) => {
    setShowSketcher(index);
  };

  const onSwatchClose = () => {
    setShowSketcher(null);
  };

  const onColorDelete = (index: any) => {
    const newColors = [...colors];
    if (newColors.length > 1) {
      newColors.splice(index, 1);
    }
    setColors(newColors);
  };

  const onColorAdd = () => {
    const newColors = [...colors, colors[colors.length - 1]];
    setColors(newColors);
  };

  const onSortEnd = ({ oldIndex, newIndex }: any) => {
    const newColors = arrayMove(colors, oldIndex, newIndex);
    setColors(newColors);
    setIsSorting(false);
  };

  const onSortStart = () => {
    setIsSorting(true);
  };

  const inputColorHex = (index: any, { target: { value } }: any) => {
    const newColors = [...colors];
    newColors[index] = value.toUpperCase();
    setColors(newColors);
  };

  return (
    <div className="custom-palette-panel">
      <StyledColorRange>
        <ColorRangeRow key={colors}>
          {colors?.map((color: any, i: any) => {
            let hexColor = RGBToHex(color);
            return (
              <ColorElement key={i} style={{ backgroundColor: hexColor }} />
            );
          })}
        </ColorRangeRow>
      </StyledColorRange>
      <WrappedSortableContainer
        className="custom-palette-container"
        onSortEnd={onSortEnd}
        onSortStart={onSortStart}
        lockAxis="y"
        helperClass="sorting-colors"
        useDragHandle
      >
        {colors?.map((color: any, index: any) => (
          <SortableItem key={index} index={index} isSorting={isSorting}>
            <DragHandle className="layer__drag-handle">
              <MoreOutlined height="20px" />
            </DragHandle>

            <Dropdown
              overlayStyle={{ width: 200, height: 200 }}
              trigger={["click"]}
              overlay={<ColorPalette changeSingleColor={onPickerUpdate} />}
            >
              <StyledSwatch
                color={RGBToHex(color)}
                onClick={() => onSwatchClick(index)}
                onCancel={onSwatchClose}
              />
            </Dropdown>

            <StyledInlineInput>
              <Input
                type="text"
                disabled
                style={{ color: "black" }}
                value={RGBToHex(color).toUpperCase()}
                onClick={(e) => {
                  e.stopPropagation();
                }}
                onChange={(e) => inputColorHex(index, e)}
                id={`input-layer-label-${index}`}
              />
            </StyledInlineInput>
            <StyledTrash onClick={() => onColorDelete(index)}>
              <DeleteOutlined className="trashbin" />
            </StyledTrash>
          </SortableItem>
        ))}
      </WrappedSortableContainer>
      {/* Add Step Button */}
      <Button
        type="text"
        icon={<PlusOutlined />}
        className="add-step__button"
        onClick={onColorAdd}
      >
        Add Step
      </Button>
      <StyledLine />
      {/* Cancel or Confirm Buttons */}
      <StyledButtonContainer>
        <Button
          type="primary"
          style={{ backgroundColor: "var(--default-green)" }}
          className="confirm-apply__button"
          onClick={() => onConfirm(colors)}
        >
          Confirm
        </Button>
        <Button onClick={onCancel}>Cancel</Button>
      </StyledButtonContainer>
    </div>
  );
};

export default CustomPalette;

export {};
