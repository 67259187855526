//@ts-ignore
import { GeoJsonLayer as Geo } from "@deck.gl/layers";
import {
  scaleQuantize,
  scaleQuantile,
  scaleLinear,
  scaleSqrt,
  scaleLog,
  scaleOrdinal,
  //@ts-ignore
} from "d3-scale";
//@ts-ignore
import { DataFilterExtension } from "@deck.gl/extensions";
import { COLOR_RANGE } from "../constants/colorRange";
import {
  findColorRange,
  getFilterRangeFromDataset,
  getFilterValueFromDataset,
  getMinMax,
} from "../functions/layerProperties";
//@ts-ignore
import { isEmpty, isString } from "lodash";
import { HextoRGB } from "../functions/colorConversion";

export const CsvGeoJsonLayer = (
  datasetId: string,
  dataset: Record<string, any>,
  config: any
) => {
  if (isEmpty(config)) return;

  const {
    colorRange,
    colorScale,
    colorBasedOn,
    customColor,
    customStrokeColor,
    strokeColorBasedOn,
    strokeWidthBasedOn,
    strokeColorRange,
    strokeWidthScale,
    strokeColorScale,
    fillColor,
    lineColor,
    filterConfig,
    extruded,
    heightBasedOn,
    heightScale,
    visible,
    radiusBasedOn,
    fillConditionEnabled,
    fillCondition,
    strokeConditionEnabled,
    strokeCondition,
    nullConfig,
  } = config;

  const customRgb =
    customColor && customColor.map((item: any) => HextoRGB(item));

  const customStrokeRgb =
    customStrokeColor && customStrokeColor.map((item: any) => HextoRGB(item));

  const getPointRadius = (d: any) => {
    if (radiusBasedOn) {
      const radiusValue = isString(d[radiusBasedOn])
        ? d[radiusBasedOn].length
        : d[radiusBasedOn];
      return valueScale(radiusValue);
    } else return 10;
  };

  const valueScale = scaleLinear()
    .domain([...getMinMax(dataset, radiusBasedOn)])
    .range([0, 100]);

  const getFillColor = (d: any) => {
    const scaleObj = {
      quantile: colorScaleFunction.quantiles,
      quantize: colorScaleFunction.quantize,
      ordinal: colorScaleFunction.ordinal,
    };
    if (colorBasedOn) {
      const colorValue = d[colorBasedOn];
      //@ts-ignore
      const scale = (scaleObj[colorScale] = function (val: any) {
        if (nullConfig && val === nullConfig.value) return nullConfig.color;
        else return colorScaleFunction(val);
      });
      return scale(colorValue);
    } else return fillColor;
  };

  const getLineColor = (d: any) => {
    if (strokeColorBasedOn) {
      const colorValue = d[strokeColorBasedOn];
      return strokeColorScaleFunction(colorValue);
    } else return lineColor;
  };

  const getLineWidth = (d: any) => {
    if (strokeWidthBasedOn) {
      const lineWidth = isString(d[strokeWidthBasedOn])
        ? d[strokeWidthBasedOn].length
        : d[strokeWidthBasedOn];
      return strokeValueScale(lineWidth) / 10;
    } else return 5;
  };

  const getElevation = (d: any) => {
    if (!extruded) return 0;
    if (heightBasedOn) {
      const heightValue = isString(d[heightBasedOn])
        ? d[heightBasedOn].length
        : d[heightBasedOn];
      return heightValueScale(heightValue) * 100;
    } else return 100;
  };

  const strokeValueScale =
    strokeWidthScale === "linear"
      ? scaleLinear()
          .domain([...getMinMax(dataset, strokeWidthBasedOn)])
          .range([0, 100])
      : strokeWidthScale === "log"
      ? scaleLog()
          .domain([...getMinMax(dataset, strokeWidthBasedOn)])
          .range([0, 100])
      : scaleSqrt()
          .domain([...getMinMax(dataset, strokeWidthBasedOn)])
          .range([0, 100]);

  const heightValueScale =
    heightScale === "linear"
      ? scaleLinear()
          .domain([...getMinMax(dataset, heightBasedOn)])
          .range([0, 10])
      : heightScale === "log"
      ? scaleLog()
          .domain([...getMinMax(dataset, heightBasedOn)])
          .range([0, 10])
      : scaleSqrt()
          .domain([...getMinMax(dataset, heightBasedOn)])
          .range([0, 10]);

  const colorDomain =
    fillConditionEnabled && fillCondition?.fieldNames.includes(colorBasedOn)
      ? fillCondition?.values
      : findColorRange(
          dataset,
          colorScale,
          colorBasedOn,
          //@ts-ignore
          COLOR_RANGE[colorRange]
        );

  const colorScaleFunction: any =
    colorScale === "quantize"
      ? scaleQuantize()
          .domain(colorDomain)
          .range(
            colorRange
              ? // @ts-ignore
                COLOR_RANGE[colorRange]
              : customRgb
              ? customRgb
              : COLOR_RANGE["default"]
          )
      : colorScale === "quantile"
      ? scaleQuantile()
          .domain(colorDomain)
          .range(
            colorRange
              ? // @ts-ignore
                COLOR_RANGE[colorRange]
              : customRgb
              ? customRgb
              : COLOR_RANGE["default"]
          )
      : scaleOrdinal()
          .domain(colorDomain)
          .range(
            colorRange
              ? // @ts-ignore
                COLOR_RANGE[colorRange]
              : customRgb
              ? customRgb
              : COLOR_RANGE["default"]
          );

  const strokeColorDomain =
    strokeConditionEnabled &&
    strokeCondition?.fieldNames.includes(strokeColorBasedOn)
      ? strokeCondition?.values
      : findColorRange(
          dataset,
          strokeColorScale,
          strokeColorBasedOn,
          //@ts-ignore
          COLOR_RANGE[strokeColorRange]
        );

  const strokeColorScaleFunction =
    config.strokeColorScale === "quantize"
      ? scaleQuantize()
          .domain(strokeColorDomain)
          .range(
            strokeColorRange
              ? // @ts-ignore
                COLOR_RANGE[strokeColorRange]
              : customStrokeRgb
              ? customStrokeRgb
              : COLOR_RANGE["default"]
          )
      : config.strokeColorScale === "quantile"
      ? scaleQuantile()
          .domain(strokeColorDomain)
          .range(
            strokeColorRange
              ? // @ts-ignore
                COLOR_RANGE[strokeColorRange]
              : customStrokeRgb
              ? customStrokeRgb
              : COLOR_RANGE["default"]
          )
      : scaleOrdinal()
          .domain(strokeColorDomain)
          .range(
            strokeColorRange
              ? // @ts-ignore
                COLOR_RANGE[strokeColorRange]
              : customStrokeRgb
              ? customStrokeRgb
              : COLOR_RANGE["default"]
          );

  const getFilterValue = (d: any) => {
    const result = getFilterValueFromDataset(d, filterConfig);
    return result;
  };

  const getFilterRange = () => {
    const result = getFilterRangeFromDataset(filterConfig);
    return result;
  };

  return new Geo({
    id: datasetId,
    data: dataset.rows,
    visible: visible,
    autoHighlight: true,
    highlightColor: [250, 253, 15],
    pickable: true,
    filled: true,
    pointType: "circle",
    pointRadiusUnits: "meters",
    pointRadiusMinPixels: 1,
    pointRadiusMaxPixels: 100,
    lineWidthMaxPixels: 100,
    lineWidthMinPixels: 1,
    lineWidthUnits: "meters",
    getPointRadius,
    getFillColor,
    getLineColor,
    getLineWidth,
    getElevation,
    getFilterValue: (d: any) => getFilterValue(d),
    filterRange: getFilterRange(),
    extensions: [
      new DataFilterExtension({
        filterSize: 3,
      }),
    ],
    updateTriggers: {
      getFillColor: [
        colorBasedOn,
        colorRange,
        getFillColor,
        fillColor,
        colorScale,
        customRgb,
        nullConfig,
      ],
      getPointRadius: [radiusBasedOn],
      getLineWidth: [strokeWidthBasedOn, strokeWidthScale],
      getLineColor: [
        strokeColorBasedOn,
        strokeColorScale,
        strokeColorRange,
        lineColor,
        getLineColor,
        customStrokeRgb,
      ],
      getElevation: [heightBasedOn, heightScale],
      getFilterValue: [filterConfig],
    },
    transitions: {
      getLineColor: 100,
      getLineWidth: 100,
      lineWidthScale: 100,
      opacity: 100,
      getPointRadius: 100,
      pointRadiusScale: 100,
      getFillColor: 100,
      elevationScale: 100,
      getElevation: 100,
    },
    ...config,
    _subLayerProps: {
      "polygons-fill": {
        autoHighlight: false,
      },
      "points-circle": {
        autoHighlight: true,
      },
    },
  });
};
