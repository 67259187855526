import { GeoJsonLayer, PointLayer, CsvGeoJsonLayer } from "../layerConfigs";

export const selectLayer = (
  datasetId: string,
  dataset: Record<string, any>,
  config: any
) => {
  const datasetType = dataset.type;
  switch (datasetType) {
    case "FeatureCollections":
      return GeoJsonLayer(datasetId, dataset, config);
    case "CsvCollections":
      return CsvGeoJsonLayer(datasetId, dataset, config);
    case "Point":
      return PointLayer(datasetId, dataset, config);
    // case "Polygon":
    //   return PolygonLayer(datasetId, dataset, config);
  }
};
