import { useState } from "react";
import Control from "./components/Control";
import Deck from "./components/Deck";
import "./App.css";

function App() {
  const [dataset, setDataset] = useState({});
  return (
    <div className="App">
      <div>
        <Deck dataset={dataset} />
      </div>
      <div>
        <Control dataset={dataset} setDataset={setDataset} />
      </div>
    </div>
  );
}

export default App;
